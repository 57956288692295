import { produce } from "immer";
import { create } from "zustand";
import { ErrorsStore } from "./types";

const useErrorsRootStore = create<ErrorsStore>((set, get) => ({
    errors: [],
    // computed: {},

    setError: (error) =>
        set(
            produce((state) => {
                state.errors.push(error);
            })
        ),
}));

export { useErrorsRootStore };
