import {
    CreditCardIcon,
    StethoscopeIcon,
} from "../../../../../components/icons";
import { useQuizRootStore } from "../../../../../stores/quiz/quizRootStore";

import styles from "./quiz-checkout-summary.module.scss";

export const QuizCheckoutSummary = ({ formId }: { formId: string }) => {
    const QuizTitle = useQuizRootStore((s) => s.title);

    return (
        <div className={styles["receipt-block__wrap"]}>
            <span className={styles["receipt-block__title"]}>
                Online Consultation
            </span>
            <div className={styles["receipt-block__list"]}>
                <div className={styles["disease-name"]}>
                    <StethoscopeIcon className={styles["disease-name__icon"]} />
                    <span className={styles["list-name"]}>{QuizTitle}</span>
                </div>

                <div className={styles["receipt-summary"]}>
                    <CreditCardIcon className={styles["disease-name__icon"]} />
                    <ul className={styles["receipt-list"]}>
                        <li className={styles["receipt-list__item"]}>
                            <span className={styles["list-name"]}>Summary</span>
                            <span className={styles["list-name"]}>$ 25.00</span>
                        </li>
                        <li className={styles["receipt-list__item"]}>
                            <span className={styles["list-name--grey"]}>
                                Fees
                            </span>
                            <span className={styles["list-name--grey"]}>
                                $ 5.00
                            </span>
                        </li>
                        <li className={styles["receipt-list__item"]}>
                            <span className={styles["list-name--grey"]}>
                                Consultation
                            </span>
                            <span className={styles["list-name--grey"]}>
                                $ 20.00
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles["receipt-block__action"]}>
                <button
                    type="submit"
                    form={formId}
                    className={`button purple ${styles["submit-btn"]}`}
                >
                    Pay $25.00
                </button>
            </div>
        </div>
    );
};
