import { FooterNavigation } from "../navigation";
import styles from "./footer.module.scss";

export const Footer = () => {
    return (
        <div className={styles["main"]}>
            <div className="container">
                <div className="row">
                    <div className={styles["wrap"]}>
                        <div className={styles["main-nav-bar"]} />
                        <div className={styles["additional-nav-bar"]}>
                            <FooterNavigation
                                navClassName={styles["nav"]}
                                linkTitleClassName={styles["nav__link-title"]}
                            />
                            <p className={styles["copyright"]}>
                                Copyright © {new Date().getFullYear()} CharityRX
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
