import { IconProps } from "./types";

export const LoaderOrbitPart = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 223 218"
        fill="none"
    >
        <ellipse
            cx="81.434"
            cy="81.5611"
            rx="81.434"
            ry="81.5611"
            transform="matrix(-0.0162973 -0.999867 0.999868 -0.0162465 28.9453 191.187)"
            fill="#F9F7FF"
        />
        <path
            d="M36.1395 66.0958C34.237 64.0308 33.0927 61.3786 32.894 58.5732C32.6952 55.7679 33.4538 52.9756 35.0454 50.6535C36.6371 48.3314 38.9676 46.617 41.6554 45.7911C44.3433 44.9651 47.2292 45.0764 49.8408 46.1068C50.4005 46.3263 50.943 46.587 51.464 46.8868"
            stroke="#2B307A"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M55.2729 50.4279C56.8494 52.6148 57.674 55.2514 57.6233 57.9431C57.5727 60.6348 56.6495 63.2369 54.9918 65.3601C53.3342 67.4832 51.0313 69.0133 48.4277 69.7212C45.8241 70.4292 43.0598 70.277 40.5485 69.2875C40.3687 69.2165 40.189 69.1455 40.0131 69.0761"
            stroke="#2B307A"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            opacity="0.74"
            d="M195.701 57.9383C196.2 59.2017 196.279 60.5914 195.925 61.9024C195.84 62.2146 195.733 62.5203 195.604 62.817C195.27 63.5865 194.786 64.2825 194.181 64.8648C193.576 65.4471 192.861 65.9043 192.078 66.21L185.052 68.9518L179.236 71.2255C177.777 71.7897 176.163 71.804 174.694 71.2657C173.226 70.7275 172.005 69.6736 171.26 68.3019C172.166 68.3928 173.08 68.2669 173.928 67.9346L180.347 65.4263L186.77 62.9191C187.618 62.5885 188.375 62.062 188.978 61.383C189.582 60.7039 190.015 59.8917 190.242 59.0132C190.469 58.1346 190.484 57.2147 190.285 56.3291C190.086 55.4435 189.68 54.6175 189.098 53.9189C190.488 53.7805 191.884 54.0982 193.076 54.8237C194.268 55.5492 195.189 56.6429 195.701 57.9383Z"
            fill="#BB8DDC"
        />
        <path
            d="M190.028 55.4838C190.583 56.9001 190.553 58.4777 189.944 59.8716C189.336 61.2655 188.199 62.3622 186.781 62.9221L180.361 65.4345L176.155 54.7502L182.58 52.2389C183.999 51.6921 185.578 51.7263 186.973 52.334C188.368 52.9417 189.466 54.0737 190.028 55.4838V55.4838Z"
            stroke="#921FA8"
            strokeWidth="3"
            strokeMiterlimit="10"
        />
        <path
            d="M176.139 54.7433L180.344 65.4277L173.921 67.9349C172.499 68.4847 170.917 68.4513 169.519 67.8419C168.122 67.2325 167.022 66.0967 166.462 64.6824C165.908 63.2657 165.941 61.6883 166.551 60.2956C167.162 58.9029 168.301 57.8084 169.72 57.2516L176.139 54.7433Z"
            stroke="#921FA8"
            strokeWidth="3"
            strokeMiterlimit="10"
        />
        <path
            d="M141.283 200.756L130.568 178.701"
            stroke="#2B307A"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M151.119 184.489L142.502 177.002"
            stroke="#2B307A"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M200.343 74.562C200.756 73.414 201.608 72.4763 202.713 71.9537C203.818 71.431 205.086 71.366 206.239 71.7727L211.46 73.6313L208.361 82.3026L203.146 80.4474C201.996 80.033 201.055 79.1817 200.53 78.0793C200.005 76.9768 199.937 75.7125 200.343 74.562Z"
            fill="#921FA8"
            stroke="#921FA8"
            strokeWidth="3"
            strokeMiterlimit="10"
        />
        <path
            d="M208.361 82.3053L211.46 73.634L216.673 75.4922C217.826 75.9025 218.769 76.7529 219.295 77.8562C219.82 78.9595 219.885 80.2253 219.476 81.3753C219.066 82.5252 218.214 83.4651 217.109 83.988C216.004 84.511 214.735 84.5743 213.581 84.1639L208.361 82.3053Z"
            stroke="#921FA8"
            strokeWidth="3"
            strokeMiterlimit="10"
        />
        <path
            d="M183.531 73.7105C201.476 110.908 188.658 154.956 155.136 177.149M36.5717 72.8831C32.9043 80.2841 30.3692 88.2121 29.0672 96.38M53.4281 50.0059C59.453 44.2781 66.4627 39.3782 74.3613 35.5796C107.567 19.6105 146.222 28.0399 169.972 53.7583M38.8361 149.649C55.4138 178.692 87.6649 194.079 119.627 190.236"
            stroke="#2B307A"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29.4333 116.643C29.7287 115.714 31.0441 115.714 31.3395 116.643L32.5903 120.581C32.6781 120.857 32.8814 121.082 33.1476 121.197L36.9274 122.826C37.733 123.173 37.733 124.315 36.9274 124.662L33.1476 126.292C32.8814 126.406 32.6781 126.631 32.5903 126.907L31.3395 130.845C31.0441 131.775 29.7287 131.775 29.4333 130.845L28.1825 126.907C28.0947 126.631 27.8914 126.406 27.6252 126.292L23.8454 124.662C23.0398 124.315 23.0398 123.173 23.8454 122.826L27.6252 121.197C27.8914 121.082 28.0947 120.857 28.1825 120.581L29.4333 116.643Z"
            fill="#921FA8"
        />
        <path
            d="M23.1679 138.444C23.4548 137.498 24.7948 137.498 25.0818 138.444L25.1642 138.716C25.2479 138.992 25.4467 139.218 25.7096 139.337L26.4423 139.668C27.2269 140.022 27.2269 141.136 26.4423 141.49L25.7096 141.821C25.4467 141.94 25.2479 142.167 25.1642 142.443L25.0818 142.714C24.7948 143.661 23.4548 143.661 23.1679 142.714L23.0855 142.443C23.0018 142.167 22.803 141.94 22.5401 141.821L21.8074 141.49C21.0228 141.136 21.0228 140.022 21.8074 139.668L22.5401 139.337C22.803 139.218 23.0018 138.992 23.0855 138.716L23.1679 138.444Z"
            fill="#2B307A"
        />
    </svg>
);
