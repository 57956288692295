import { find, isEmpty, last, map } from "lodash";
import { produce } from "immer";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import {
    QuizRootStoreType,
    ErrorItem,
    QuizSavedObject,
    QuizErrorTypes,
} from "./types";
import {
    pageQuestionsNoRxAction,
    pageQuestionsValidateValue,
    getNextPageIndex,
} from "./helpers";

import { QuizNoRxError, QuizValidationError } from "./errors";

import { saveProgressOfQuizForUser } from "../../services/disease.service";
import { QuizPageObject, QuizStatuses } from "../../services/types";

const useQuizRootStore = create(
    subscribeWithSelector<QuizRootStoreType>((set, get) => ({
        // Quiz
        title: "",
        diseaseQuizzes: null,
        quizUserId: -1, // only current
        data: [],
        saved: [],
        history: [],

        //control
        loading: false,
        currentPage: 0,
        status: QuizStatuses.processing,

        validationErrors: [],
        globalError: null, // on of ..
        computed: {
            get currentDiseaseTypes() {
                return map(get().diseaseQuizzes, (item) => item.type);
            },

            get inited() {
                return !isEmpty(get().data);
            },

            get totalPages() {
                return get().data?.length || 0;
            },

            get pages(): QuizPageObject[] | undefined {
                return get().data;
            },

            get page(): QuizPageObject | undefined {
                return get().data[get().currentPage];
            },

            get isDisabledNavBtn(): boolean {
                return get().currentPage === get().computed.totalPages;
            },

            get hasValidationErrors(): boolean {
                return get().validationErrors.length > 0;
            },
        },

        resetStore: () =>
            set(
                produce((state) => {
                    state.title = "";
                    state.diseaseQuizzes = null;
                    state.quizUserId = -1;
                    state.data = [];
                    state.saved = [];
                    state.history = [];
                    state.loading = false;
                    state.currentPage = 0;
                    state.status = QuizStatuses.processing;
                    state.validationErrors = [];
                    state.globalError = null;
                })
            ),

        initDiseaseQuizzes: (data) =>
            set(
                produce((state) => {
                    state.diseaseQuizzes = data;
                })
            ),
        initCurrentQuiz: (title, quizQuestions, userSavedObj) => {
            set({
                title,
                data: quizQuestions,
                currentPage: 0,
                validationErrors: [],
                globalError: null,
                history: [0],
                quizUserId: userSavedObj.id,
                ...(userSavedObj.status && { status: userSavedObj.status }),
            });

            // Set saved answers
            set({
                saved:
                    userSavedObj.answers && userSavedObj.answers !== null
                        ? userSavedObj.answers
                        : [],
            });
        },
        setValidationError: (error) =>
            set(
                produce((state) => {
                    state.validationErrors = error;
                })
            ),
        setGlobalError: (type: QuizErrorTypes, message: string | null) =>
            set(
                produce((state) => {
                    state.globalError = {
                        type,
                        message,
                    };
                })
            ),
        setLoading: (s) =>
            set(
                produce((state) => {
                    state.loading = s;
                })
            ),
        getStartedQuizId: (quizType) => {
            const quiz = find(get().diseaseQuizzes, ["type", quizType]);
            return quiz?.userQuizId;
        },

        nextPage: () =>
            set(
                produce((state) => {
                    // Save current page to history
                    state.history.push(state.currentPage);

                    // Check and get all values
                    const pages = state.computed.pages;
                    const page = state.computed.page;
                    // const history = state.history;

                    if (pages === undefined || page === undefined) return;

                    // TO-DO: Check that this is last question and go to payment page.
                    if (state.currentPage === state.computed.totalPages - 1) {
                        state.status = QuizStatuses.payment;
                        // force save quiz new status
                        const { quizUserId, status } = state;
                        state.saveQuizInToUser(quizUserId, status, get().saved);
                    }

                    // if We have one more page.
                    if (state.currentPage < state.computed.totalPages) {
                        // We should check all marked (filled) answers
                        // that all required field is ready and that from them nobody doesn't have No-RX type
                        // In any case we should redirect to no-rx page or show errors

                        try {
                            // Check on Required Fields
                            pageQuestionsValidateValue(page, state.saved);

                            // Check on "no-rx" type
                            pageQuestionsNoRxAction(page, state.saved);

                            // Get new index of page based on another.
                            const newIndex = getNextPageIndex(
                                pages,
                                page,
                                state.saved,
                                state.currentPage
                            );

                            // use produce
                            state.currentPage = newIndex;

                            return;
                        } catch (customError) {
                            if (customError instanceof QuizNoRxError) {
                                state.globalError = QuizErrorTypes.norx;
                            } else if (
                                customError instanceof QuizValidationError
                            ) {
                                state.validationErrors =
                                    customError.getErrors();
                            } else {
                                return;
                            }
                        } finally {
                            // !Save quiz into backend
                            const { quizUserId, status } = state;
                            state.saveQuizInToUser(
                                quizUserId,
                                status,
                                get().saved
                            );
                        }
                    } else {
                        return;
                    }
                })
            ),
        prevPage: () =>
            set(
                produce((state) => {
                    state.globalError = null;

                    if (state.currentPage === 0) return;
                    const lastPage = last<number | undefined>(get().history);

                    if (undefined === undefined || lastPage === 0)
                        state.currentPage = 0;

                    state.history.pop();
                    state.currentPage = lastPage;

                    return;
                })
            ),
        deleteErrorFromStack: (id) =>
            set(
                produce((state) => {
                    const index = state.validationErrors.findIndex(
                        (i: ErrorItem) => i.id === id
                    );
                    if (index !== -1) state.validationErrors.splice(index, 1);

                    return;
                })
            ),
        saveAnswerQuestion: (id: string, value: string) =>
            set((state) => {
                // Clean error in current field
                get().deleteErrorFromStack(id);

                // Check that this ID has in current Quiz.
                const page = state.computed.page;
                const question = find(page?.questions, ["id", id]);

                if (question === undefined) return {};

                // Get Index in saved answers
                const questionIndex = state.saved.findIndex(
                    (item: QuizSavedObject) => item.id === id
                );

                if (questionIndex === -1) {
                    //add
                    return {
                        saved: [...state.saved, { id, value }],
                    };
                } else {
                    //update
                    const answers = [...state.saved]; // copy the array
                    answers[questionIndex] = { id, value };
                    return { saved: answers };
                }
            }),
        saveQuizInToUser: (quizUserId, status, savedData) => {
            saveProgressOfQuizForUser(quizUserId, status, savedData);
        },
    }))
);

export { useQuizRootStore };
