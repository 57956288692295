import * as rdd from "react-device-detect";
import { ApiValidationError } from "../errors";
import { FieldValues, UseFormSetError } from "react-hook-form";

export const generateRandomString = (length: number): string => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const isMobileWidth = () => {
    const InnerWidthForDesktop = 860;

    if (window === undefined) return undefined;

    return window.innerWidth <= InnerWidthForDesktop;
};

export const getCurrentDeviceNameForAuth = () => {
    return `Device: ${rdd.osName}(${rdd.osVersion}) - ${rdd.browserName} ${rdd.browserVersion}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasEmptyProps = (obj: { [key: string]: any }) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === "") return true;
    }
    return false;
};

export const parseValidationErrors = (
    error: ApiValidationError,
    callback: UseFormSetError<FieldValues>
) => {
    if (error.fields !== null) {
        for (const fieldName in error.fields) {
            const message = error.fields[fieldName];

            callback(fieldName, {
                message: message.toString(),
            });
        }
    }
    return;
};
