import { IconProps } from "./types";

export const SearchIcon = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        fill="none"
        className={className}
    >
        <path
            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
            fill="white"
        />
    </svg>
);

export const CloseIcon = ({
    className,
    containerClass,
    onClick,
}: IconProps) => (
    <div className={containerClass} onClick={onClick}>
        <svg
            className={className}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 1L13 13" stroke="currentColor" strokeLinecap="round" />
            <path
                d="M1 13L13 0.999999"
                stroke="currentColor"
                strokeLinecap="round"
            />
        </svg>
    </div>
);

export const DotsIcon = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 4"
        fill="none"
    >
        <circle cx="2" cy="2" r="2" fill="#2B307A" />
        <circle cx="10" cy="2" r="2" fill="#2B307A" />
        <circle cx="18" cy="2" r="2" fill="#2B307A" />
    </svg>
);

export const LockIcon = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 25"
        fill="none"
    >
        <path
            d="M5.75 11.9604C5.75 10.7178 6.75736 9.71045 8 9.71045H16C17.2426 9.71045 18.25 10.7178 18.25 11.9604V17.9604C18.25 19.2031 17.2426 20.2104 16 20.2104H8C6.75736 20.2104 5.75 19.2031 5.75 17.9604V11.9604Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <rect
            x="11.5"
            y="17.4604"
            width="2"
            height="1"
            rx="0.5"
            transform="rotate(-90 11.5 17.4604)"
            fill="currentColor"
            stroke="currentColor"
        />
        <rect
            x="13.25"
            y="15.2104"
            width="2.5"
            height="2.5"
            rx="1.25"
            transform="rotate(180 13.25 15.2104)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M15 8.96045V6.96045C15 5.3036 13.6569 3.96045 12 3.96045V3.96045C10.3431 3.96045 9 5.30359 9 6.96045V8.96045"
            stroke="currentColor"
            strokeWidth="1.5"
        />
    </svg>
);

export const StethoscopeIcon = ({ className, color }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 12"
        fill="none"
        style={{ stroke: color || "inherit" }}
    >
        <path
            d="M3.68561 1.00708H3C1.89543 1.00708 0.985134 1.90295 1.02848 3.00667C1.1524 6.16211 1.81554 10.071 5.36412 10.071C8.9127 10.071 9.57584 6.16211 9.69976 3.00667C9.74311 1.90295 8.83281 1.00708 7.72824 1.00708H7.04263"
            stroke="#currentColor"
            strokeWidth="1.2"
        />
        <path
            d="M5.36328 10.0711C7.71319 11.4139 13.0844 11.951 13.0844 3.35706"
            stroke="#currentColor"
            strokeWidth="1.2"
        />
        <path
            d="M14.4987 2.01421C14.4987 2.79526 13.8656 3.42842 13.0845 3.42842C12.3035 3.42842 11.6703 2.79526 11.6703 2.01421C11.6703 1.23316 12.3035 0.6 13.0845 0.6C13.8656 0.6 14.4987 1.23316 14.4987 2.01421Z"
            stroke="#currentColor"
            strokeWidth="1.2"
        />
        <path
            d="M7.78679 1.34281C7.78679 1.75305 7.45422 2.08561 7.04398 2.08561C6.63374 2.08561 6.30117 1.75305 6.30117 1.34281C6.30117 0.932566 6.63374 0.6 7.04398 0.6C7.45422 0.6 7.78679 0.932566 7.78679 1.34281Z"
            fill="#currentColor"
            stroke="#currentColor"
            strokeWidth="1.2"
        />
        <circle
            cx="3.6846"
            cy="1.34281"
            r="0.742807"
            fill="#currentColor"
            stroke="#currentColor"
            strokeWidth="1.2"
        />
    </svg>
);

export const CreditCardIcon = ({ className, color }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 12"
        fill="none"
        className={className}
        style={{ color: color || "inherit" }}
    >
        <path
            d="M12.334 11.3333H1.66732C0.930938 11.3333 0.333984 10.7363 0.333984 9.99996V1.99996C0.333984 1.26358 0.930938 0.666626 1.66732 0.666626H12.334C13.0704 0.666626 13.6673 1.26358 13.6673 1.99996V9.99996C13.6673 10.7363 13.0704 11.3333 12.334 11.3333ZM1.66732 5.99996V9.99996H12.334V5.99996H1.66732ZM1.66732 1.99996V3.33329H12.334V1.99996H1.66732ZM7.66732 8.66663H3.00065V7.33329H7.66732V8.66663Z"
            fill="currentColor"
        />
    </svg>
);

export const ExitIcon = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        fill="none"
    >
        <path
            d="M11.1427 12.5H1.80941C1.07303 12.5 0.476074 11.903 0.476074 11.1667V8.5H1.80941V11.1667H11.1427V1.83333H1.80941V4.5H0.476074V1.83333C0.476074 1.09695 1.07303 0.5 1.80941 0.5H11.1427C11.8791 0.5 12.4761 1.09695 12.4761 1.83333V11.1667C12.4761 11.903 11.8791 12.5 11.1427 12.5ZM5.80941 9.16667V7.16667H0.476074V5.83333H5.80941V3.83333L9.14274 6.5L5.80941 9.16667Z"
            fill="currentColor"
        />
    </svg>
);

export const ImageIcon = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 13"
        fill="none"
    >
        <path
            d="M10.6667 12.5344H1.33333C0.596954 12.5344 0 11.9375 0 11.2011V1.86776C0 1.13138 0.596954 0.534424 1.33333 0.534424H10.6667C11.403 0.534424 12 1.13138 12 1.86776V11.2011C12 11.9375 11.403 12.5344 10.6667 12.5344ZM1.33333 1.86776V11.2011H10.6667V1.86776H1.33333ZM10 9.86776H2L4 7.20109L4.66667 7.86776L6.66667 5.20109L10 9.86776ZM3.66667 5.86776C3.11438 5.86776 2.66667 5.42004 2.66667 4.86776C2.66667 4.31547 3.11438 3.86776 3.66667 3.86776C4.21895 3.86776 4.66667 4.31547 4.66667 4.86776C4.66667 5.42004 4.21895 5.86776 3.66667 5.86776Z"
            fill="currentColor"
        />
    </svg>
);

export const TrashIcon = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 14"
        fill="none"
    >
        <path
            d="M9.80941 13.6668H3.14274C2.40636 13.6668 1.80941 13.0699 1.80941 12.3335V3.66683H0.476074V2.3335H3.14274V1.66683C3.14274 0.93045 3.73969 0.333496 4.47607 0.333496H8.47607C9.21245 0.333496 9.80941 0.93045 9.80941 1.66683V2.3335H12.4761V3.66683H11.1427V12.3335C11.1427 13.0699 10.5458 13.6668 9.80941 13.6668ZM3.14274 3.66683V12.3335H9.80941V3.66683H3.14274ZM4.47607 1.66683V2.3335H8.47607V1.66683H4.47607Z"
            fill="currentColor"
        />
    </svg>
);
export const RxPrescriptionIcon = ({ className, color }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 20"
        fill="none"
        className={className}
        style={{ color: color || "inherit" }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.8 0.473511C0.358172 0.473511 0 0.831683 0 1.27351V18.8735C0 19.3153 0.358172 19.6735 0.8 19.6735H15.2C15.6418 19.6735 16 19.3153 16 18.8735V5.27351C16 5.06134 15.9157 4.85785 15.7657 4.70783L11.7657 0.707825C11.6157 0.557796 11.4122 0.473511 11.2 0.473511H0.8ZM1.6 18.0735V2.07351H10.4V5.27351C10.4 5.71534 10.7582 6.07351 11.2 6.07351H14.4V18.0735H1.6ZM13.2686 4.47351L12 3.20488V4.47351H13.2686ZM11.9249 10.0111L10.2124 12.4792L12 15.0735H9.99268L9.15607 13.7575L8.31213 15.0735H6.34142L8.12998 12.4993L6.4183 10.0111L6.19313 9.68736H5.76475V11.1342H4V6.07351H6.5794C7.51487 6.07351 8.10984 6.29812 8.47963 6.65082C8.79634 6.95615 8.96293 7.33165 8.96293 7.85193V7.86774C8.96293 8.60472 8.69657 9.11707 8.05492 9.41362L9.09657 11.0806L9.18627 11.221L9.95697 10.0111H11.9249ZM5.76475 7.49044V8.48099H6.52632C6.94828 8.48099 7.20549 8.29937 7.20549 7.98966V7.97565C7.20549 7.65015 6.94096 7.49044 6.53455 7.49044H5.76475Z"
            fill="currentColor"
        />
    </svg>
);

export const PlusIcon = ({ className, color }: IconProps) => (
    <svg
        className={className}
        style={{ color: color || "inherit" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path d="M8 2V14" stroke="currentColor" strokeWidth="2" />
        <path d="M2 8L14 8" stroke="currentColor" strokeWidth="2" />
    </svg>
);

export const ExclamationPointIcon = ({ className, color }: IconProps) => (
    <svg
        className={className}
        style={{ color, stroke: color }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        fill="none"
    >
        <circle
            cx="15.5"
            cy="15.5"
            r="11.5"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path
            d="M16 8C16.5523 8 17 8.44772 17 9V14.8288C17 15.447 16.9614 16.055 16.8841 16.6529C16.839 16.9979 16.7851 17.3557 16.7225 17.7262C16.6426 18.1985 16.229 18.538 15.75 18.538H15.25C14.771 18.538 14.3574 18.1985 14.2775 17.7262C14.2149 17.3557 14.161 16.9979 14.1159 16.6529C14.0386 16.055 14 15.447 14 14.8288V9C14 8.44771 14.4477 8 15 8H16Z"
            fill="currentColor"
        />
        <circle cx="15.5" cy="21.5" r="1.5" fill="currentColor" />
    </svg>
);

export const PencilEditIcon = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 17"
        className={className}
        fill="none"
    >
        <g>
            <path
                d="M2.94666 14.6734C2.75965 14.6731 2.58137 14.5942 2.45533 14.4561C2.32696 14.3191 2.26317 14.1338 2.28 13.9467L2.44333 12.1507L9.98866 4.60808L12.3467 6.96541L4.80333 14.5074L3.00733 14.6707C2.98666 14.6727 2.966 14.6734 2.94666 14.6734ZM12.8173 6.49408L10.46 4.13675L11.874 2.72275C11.999 2.59756 12.1687 2.52722 12.3457 2.52722C12.5226 2.52722 12.6923 2.59756 12.8173 2.72275L14.2313 4.13675C14.3565 4.26179 14.4269 4.43147 14.4269 4.60841C14.4269 4.78535 14.3565 4.95503 14.2313 5.08008L12.818 6.49341L12.8173 6.49408Z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const LocationIcon = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
    >
        <g>
            <path
                d="M12 21C10.7369 19.9226 9.56619 18.7415 8.5 17.469C6.9 15.558 5 12.712 5 9.99999C4.99858 7.16754 6.70425 4.61338 9.32107 3.52939C11.9379 2.44539 14.9501 3.04523 16.952 5.04899C18.2685 6.3596 19.0059 8.14238 19 9.99999C19 12.712 17.1 15.558 15.5 17.469C14.4338 18.7415 13.2631 19.9226 12 21ZM12 4.99999C9.23995 5.0033 7.00331 7.23994 7 9.99999C7 11.166 7.527 13.185 10.035 16.186C10.6531 16.924 11.309 17.6297 12 18.3C12.691 17.6304 13.3472 16.9259 13.966 16.189C16.473 13.184 17 11.165 17 9.99999C16.9967 7.23994 14.7601 5.0033 12 4.99999ZM12 13C10.3431 13 9 11.6568 9 9.99999C9 8.34313 10.3431 6.99999 12 6.99999C13.6569 6.99999 15 8.34313 15 9.99999C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7957 13 12 13Z"
                fill="currentColor"
            />
        </g>
    </svg>
);
