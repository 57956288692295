import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FunctionComponent, ReactElement, useEffect } from "react";

import { BackToMainBtn, QuizLoader, QuizProgressBar, QuizError } from "./ui";

import {
    checkDiseaseRestrictions,
    getDiseaseQuizzes,
    getOrCreateUserQuiz,
} from "../../services/disease.service";
import { useQuizRootStore } from "../../stores/quiz/quizRootStore";

import styles from "./quiz-pages.module.scss";
import { QuizErrorTypes } from "../../stores/quiz/types";
import { QuizTypes } from "../../services/types";

interface Props {
    children: ReactElement | null;
}

export const QuizWrapper: FunctionComponent<Props> = ({
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { diseaseSlug, quizType, quizId } = useParams<{
        diseaseSlug?: string;
        quizType?: QuizTypes;
        quizId?: string;
    }>();
    const [
        quizInited,
        loading,
        globalQuizError,
        setGlobalError,
        setLoading,
        initDiseaseQuizzes,
        initCurrentQuiz,
        resetStore,
    ] = useQuizRootStore((s) => [
        s.computed.inited,
        s.loading,
        s.globalError,
        s.setGlobalError,
        s.setLoading,
        s.initDiseaseQuizzes,
        s.initCurrentQuiz,
        s.resetStore,
    ]);

    useEffect(() => {
        let didCancel = false;
        async function makeRequest(slug: string) {
            setLoading(true);
            const { error: restrictionError } = await checkDiseaseRestrictions(
                slug
            );
            if (!didCancel) {
                if (restrictionError === undefined) {
                    const { data, error } = await getDiseaseQuizzes(slug);
                    if (!didCancel) {
                        if (data !== null && error === undefined) {
                            initDiseaseQuizzes(data);
                        }
                    }
                } else {
                    const _err = restrictionError as Error; // ?
                    setGlobalError(QuizErrorTypes.restriction, _err.message);
                }
                setLoading(false);
            }
        }

        if (diseaseSlug !== undefined) {
            makeRequest(diseaseSlug);
        }

        return () => {
            // console.log("unmounted and reset store");
            // resetStore();
            didCancel = true;
        };
    }, [diseaseSlug]);

    useEffect(() => {
        async function makeInit(
            slug: string,
            type: QuizTypes,
            savedQuizId: string | undefined
        ) {
            setLoading(true);

            const { data, error } = await getOrCreateUserQuiz(
                slug,
                type,
                savedQuizId
            );
            if (data && error === undefined) {
                initCurrentQuiz(data.title, data.quiz, data.saved);
            } else {
                const _err = error as Error; // ?
                setGlobalError(QuizErrorTypes.norx, _err.message);
            }
            setLoading(false);
        }

        if (!quizInited && diseaseSlug && quizType && quizId) {
            makeInit(diseaseSlug, quizType, quizId);
        }
    }, [quizType]);

    return (
        <div className="container">
            <div className="row">
                <div className={styles["head"]}>
                    <div className={styles["back-btn"]}>
                        <BackToMainBtn />
                    </div>
                    <div className={styles["progress-bar"]}>
                        <QuizProgressBar />
                    </div>
                </div>
                <div className={styles["main"]}>
                    {globalQuizError === null ? (
                        children
                    ) : (
                        <QuizError error={globalQuizError} />
                    )}
                </div>
                {loading && <QuizLoader />}
            </div>
        </div>
    );
};
