import * as yup from "yup";
import React, { FunctionComponent, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import {
    FieldGroup,
    GenderField,
    InputField,
} from "../../../../components/fields";
import { ArrowNextIcon } from "../../../../components/icons";
import { FormProps } from "./types";

import styles from "./welcome-forms.module.scss";
import { updateMainUserProfile } from "../../../../services/user.service";
import { ApiValidationError } from "../../../../services/errors";
import { parseValidationErrors } from "../../../../services/helpers";
import { LoadingButton } from "../../../../components/buttons";

type MainUserFormValues = FieldValues;

export const MainUserInfoStep: FunctionComponent<FormProps> = ({
    nextStep,
    user,
}) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const VALIDATOR = yup
        .object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            gender: yup.string().required(),
        })
        .required();
    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<MainUserFormValues>({
        values: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            gender: user?.gender,
        },
        resolver: yupResolver(VALIDATOR),
    });

    const onSubmit: SubmitHandler<MainUserFormValues> = async (data) => {
        setFormLoading(true);
        const { error } = await updateMainUserProfile(data);
        setFormLoading(false);

        if (error !== undefined) {
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        } else {
            nextStep();
        }
    };

    return (
        <div className={styles["users-form"]}>
            <h3 className={styles["users-form__title"]}>What is your name?</h3>
            <form
                className={styles["users-form__wrap"]}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FieldGroup
                    containerClassName={styles["users-form__group"]}
                    name="firstName"
                    label="First Name"
                    error={errors.firstName}
                >
                    <InputField register={register} />
                </FieldGroup>
                <FieldGroup
                    containerClassName={styles["users-form__group"]}
                    name="lastName"
                    label="Last Name"
                    error={errors.lastName}
                >
                    <InputField register={register} />
                </FieldGroup>
                <FieldGroup
                    containerClassName={styles["users-form__group"]}
                    name="gender"
                    label="Sex"
                    error={errors.gender}
                >
                    <GenderField control={control} />
                </FieldGroup>
                <LoadingButton
                    isLoading={formLoading}
                    isSubmitBtn={true}
                    className={styles["users-form__btn"]}
                    color="blue"
                    title={
                        <ArrowNextIcon
                            className={styles["users-form__btn-arrow"]}
                        />
                    }
                />
            </form>
        </div>
    );
};
