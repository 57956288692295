import {
    loginRequest,
    logoutRequest,
    signUpRequest,
    verifyUserEmailWithCodeRequest,
    forgotPasswordRequest,
    resetPasswordRequest,
    resendVerifyEmailCodeRequest,
} from "./api/auth.api";
import { getCurrentDeviceNameForAuth } from "./helpers";
import {
    ServiceLoginUserResponse,
    ServiceSignUpUserResponse,
    ServiceLogoutUserResponse,
    ServiceForgotPasswordResponse,
    resetPasswordRequestData,
    ServiceResetPasswordResponse,
} from "./types";

export const loginUser = async (
    email: string,
    pwd: string
): Promise<ServiceLoginUserResponse> => {
    try {
        const device = getCurrentDeviceNameForAuth();
        const response = await loginRequest(email, pwd, device);

        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const logoutUser = async (): Promise<ServiceLogoutUserResponse> => {
    try {
        const response = await logoutRequest();
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const signInUser = async (
    email: string,
    password: string,
    password_confirmation: string
): Promise<ServiceSignUpUserResponse> => {
    try {
        const response = await signUpRequest({
            email,
            password,
            password_confirmation,
        });

        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const verifyEmail = async (
    email: string,
    code: string
): Promise<ServiceSignUpUserResponse> => {
    try {
        const response = await verifyUserEmailWithCodeRequest({
            email,
            code,
        });

        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const resendVerifyEmailCode = async (
    email: string
): Promise<ServiceSignUpUserResponse> => {
    try {
        const response = await resendVerifyEmailCodeRequest({
            email,
        });

        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const forgotPassword = async (
    email: string
): Promise<ServiceForgotPasswordResponse> => {
    try {
        const response = await forgotPasswordRequest({
            email,
        });

        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const resetPassword = async (
    email: string,
    token: string,
    password: string,
    password_confirmation: string
): Promise<ServiceResetPasswordResponse> => {
    try {
        const response = await resetPasswordRequest({
            email,
            token,
            password,
            password_confirmation,
        });

        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};
