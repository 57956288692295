import { Loader } from "../loader";

import styles from "./dashboard-page-loader.module.scss";

export const DashboardPageLoader = () => {
    return (
        <div className={styles["wrap"]}>
            <Loader />
        </div>
    );
};
