import { ErrorItem } from "./types";

class QuizNoRxError extends Error {
    constructor() {
        super("We can not provide Rx Help to you, sorry");

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, QuizNoRxError.prototype);
    }
}

class QuizValidationError extends Error {
    errors: ErrorItem[] = [];
    constructor(errors: ErrorItem[]) {
        super("Validation Errors, check your answers");
        // Set the prototype explicitly.s
        Object.setPrototypeOf(this, QuizValidationError.prototype);

        this.errors = errors;
    }

    getErrors() {
        return this.errors;
    }
}

export { QuizNoRxError, QuizValidationError };
