import { QuizSavedObject } from "../../stores/quiz/types";

export interface UserProfileData {
    firstName: string;
    lastName: string;
    birthday: string;
    gender: string;
    mobilePhone: string;
    avatar: string | null;
    height: string | null;
    weight: string | null;
}

export enum UserAddressType {
    main = "main",
    billing = "billing",
    delivery = "delivery",
}

export interface UserAddressItem {
    key: number;
    type: UserAddressType;
    location: string | undefined;
    address: string | undefined;
    additional: string | undefined;
    zipcode: string | undefined;
}

// Received from SERVER.
export interface UserData {
    email: string;
    profile: UserProfileData;
    addresses: UserAddressItem[] | null;
}

// Diseases Items and Quizzes.

export enum QuizTypes {
    initial = "initial",
    refill = "refill",
}

export enum QuizStatuses {
    processing = "processing",
    noRx = "no-rx",
    finished = "finished",
    payment = "payment",
}
export interface DiseaseItemData {
    name: string;
    slug: string;
    icon: string;
    description: string;
    groupId: number;
    groupName: string;
    availableQuizTypes: [];
}
export interface DiseaseQuizItemData {
    id: number;
    title: string;
    description: string;
    type: QuizTypes;
    userQuizId: number | null;
}

export interface GetSavedQuizData {
    id: number;
    saved: QuizSavedObject[];
}

export interface UserQuizItem {
    id: number;
    name: string;
    slug: string;
    type: QuizTypes;
    status: QuizStatuses;
    saved: [];
    answers: { label: string; answer: string | null }[];
    date: string;
}
