import { FunctionComponent, ReactElement } from "react";

import { UserMainData, UserAddressData, UserCredentialsData } from "./forms";

import styles from "./profile-page.module.scss";

export const ProfilePage: FunctionComponent = (): ReactElement => {
    return (
        <div className={styles["container"]}>
            <div className="container">
                <div className="row">
                    <div className={styles["content"]}>
                        <div className={styles["profile-form"]}>
                            <UserMainData />

                            <UserAddressData />

                            {/* <UserBillingData /> */}

                            <UserCredentialsData />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
