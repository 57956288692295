import { useUserRootStore } from "../../stores/user/userRootStore";
import TopUserAvatar1x from "../../assets/images/illustration/top-user-avatar-1x.png";
import TopUserAvatar2x from "../../assets/images/illustration/top-user-avatar-2x.png";
import TopUserAvatar3x from "../../assets/images/illustration/top-user-avatar-3x.png";

interface Props {
    containerClassName?: string;
    imageClassName?: string;
}

export const UserAvatar = ({ containerClassName, imageClassName }: Props) => {
    const { avatar } = useUserRootStore();

    return (
        <div className={containerClassName}>
            {avatar === null ? (
                <img
                    src={TopUserAvatar1x}
                    srcSet={`${TopUserAvatar2x} 2x, ${TopUserAvatar3x} 3x`}
                    className={imageClassName}
                    alt=""
                />
            ) : (
                <img src={avatar} alt="" className={imageClassName} />
            )}
        </div>
    );
};
