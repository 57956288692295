import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { BlankPage } from "./ui";
import { useUserRootStore } from "../../../stores/user/userRootStore";
import { DashboardPageLoader } from "../../../components/dashboard-page-loader";

import { getUserQuizzes } from "../../../services/user.service";
import { QuizzesHistoryTable } from "./quizzes-history-table";

import styles from "./history-page.module.scss";

export const HistoryPage: FunctionComponent = (): ReactElement => {
    const [loading, setLoading] = useState<boolean>(false);
    const [initUserQuizzes, hasQuizzes] = useUserRootStore((s) => [
        s.initUserQuizzes,
        s.computed.hasUserQuizzes,
    ]);

    useEffect(() => {
        let didCancel = false;
        async function request() {
            setLoading(true);
            const { data, error } = await getUserQuizzes();

            if (!didCancel) {
                if (error === undefined) {
                    initUserQuizzes(data);
                }
                setLoading(false);
            }
        }

        request();

        return () => {
            didCancel = true;
        };
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className={styles["wrap"]}>
                    {hasQuizzes && <QuizzesHistoryTable />}
                    {!hasQuizzes && <BlankPage opacity={true} />}
                    {loading && <DashboardPageLoader />}
                </div>
            </div>
        </div>
    );
};
