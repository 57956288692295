import { find, findIndex, forEach, has } from "lodash";
import { ErrorItem, QuizSavedObject } from "./types";
import { QuizNoRxError, QuizValidationError } from "./errors";
import { QuizPageObject } from "../../services/types";

export const pageQuestionsValidateValue = (
    page: QuizPageObject,
    answers: QuizSavedObject[]
): boolean => {
    const errors: ErrorItem[] = [];
    forEach(page.questions, (question) => {
        const currentAnswer = find(answers, { id: question.id });
        if (currentAnswer === undefined) {
            errors.push({
                id: question.id,
                error: "Field is required",
            });
        }
    });
    if (errors.length > 0) throw new QuizValidationError(errors);
    return true;
};

export const pageQuestionsNoRxAction = (
    page: QuizPageObject,
    answers: QuizSavedObject[]
): boolean => {
    forEach(page.questions, (question) => {
        if (question.validation && has(question.validation, "no-rx")) {
            const currentAnswer = find(answers, { id: question.id });
            if (
                currentAnswer &&
                currentAnswer.value &&
                question.validation["no-rx"].includes(currentAnswer.value)
            ) {
                throw new QuizNoRxError();
            }
        }
    });

    return true;
};

export const pageQuestionsGoToAction = (
    page: QuizPageObject,
    answers: QuizSavedObject[]
): boolean => {
    forEach(page.questions, (question) => {
        if (question.validation && has(question.validation, "go-to")) {
            const currentAnswer = find(answers, { id: question.id });

            if (
                currentAnswer?.value &&
                question.validation["no-rx"].includes(currentAnswer?.value)
            ) {
                throw new QuizNoRxError();
            }
        }
    });

    return true;
};

export const getNextPageIndex = (
    pages: QuizPageObject[],
    currentPage: QuizPageObject,
    answers: QuizSavedObject[],
    currentPageIndex: number
): number => {
    const sliced = pages.slice(currentPageIndex + 1);

    for (const key in sliced) {
        const page = sliced[key];

        if (has(page, "visibleIf")) {
            const visibleIfCondition = page.visibleIf;
            const currentAnswerByCondition = find(answers, {
                id: visibleIfCondition?.id,
            });
            if (
                visibleIfCondition?.answer &&
                currentAnswerByCondition !== undefined &&
                currentAnswerByCondition.value
                    .split(";")
                    .includes(visibleIfCondition?.answer)
            ) {
                const newIndex = findIndex(pages, { id: page.id });
                if (newIndex !== -1) return newIndex;
            } else {
                // ?
                console.log("I AM HEERE");
                continue;
            }
        } else {
            const newIndex = findIndex(pages, { id: page.id });
            if (newIndex !== -1) return newIndex;
        }
    }

    return currentPageIndex + 1;
};
