import { IconProps } from "./types";

export const Left = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 45"
        fill="none"
        className={className}
    >
        <g clipPath="url(#clip0_1397_24446)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M190.865 45C184.519 45 178.86 41.0064 176.733 35.0274L166.637 6.64839C165.219 2.66238 161.447 0 157.216 0H24C18.4771 0 14 4.47715 14 10V30C14 38.2843 7.28427 45 -1 45H-180C-188.284 45 -195 51.7158 -195 60V1967C-195 1975.28 -188.284 1982 -180 1982H1190C1198.28 1982 1205 1975.28 1205 1967V60C1205 51.7157 1198.28 45 1190 45H190.865Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1397_24446">
                <rect width="23" height="45" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);

export const Right = ({ className }: IconProps) => (
    <svg
        viewBox="0 0 33 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <g clipPath="url(#clip0_807_23588)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.6439 50C28.2978 50 22.6385 46.0064 20.5115 40.0274L8.63742 6.64839C7.21945 2.66238 3.4465 0 -0.78421 0H-134C-139.523 0 -144 4.47715 -144 10V35C-144 43.2843 -150.716 50 -159 50H-338C-346.284 50 -353 56.7157 -353 65V769C-353 777.284 -346.284 784 -338 784H1032C1040.28 784 1047 777.284 1047 769V65C1047 56.7157 1040.28 50 1032 50H34.6439Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_807_23588">
                <rect width="33" height="50" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);
