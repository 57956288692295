import * as yup from "yup";
import { useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { parseValidationErrors } from "../../../services/helpers";
import { ApiValidationError } from "../../../services/errors";
import { signInUser } from "../../../services/auth.service";

import { FieldGroup, InputField } from "../../fields";
import { LoadingButton } from "../../buttons";

import { FormProps } from "../types";
import { Link } from "react-router-dom";

import routes from "../../../data/routes.json";

const schema = yup
    .object({
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
        password_confirmation: yup
            .string()
            .oneOf(
                [yup.ref("password"), ""],
                "Check that you passwords matched"
            ),
    })
    .required();

export const SignUpForm = ({
    formClassName,
    actionBtnWrapClassName,
    onSuccess,
}: FormProps) => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        if (Object.keys(errors).length === 0) {
            const { email, password, password_confirmation } = data;
            setLoading(true);
            const { error } = await signInUser(
                email,
                password,
                password_confirmation
            );
            setLoading(false);

            if (error) {
                if (error instanceof ApiValidationError) {
                    parseValidationErrors(error, setError);
                }
            } else {
                onSuccess(email);
            }
        }
    };

    return (
        <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup label="E-mail" name="email" error={errors.email}>
                <InputField register={register} />
            </FieldGroup>
            <FieldGroup
                label="Password"
                name="password"
                error={errors.password}
            >
                <InputField type="password" register={register} />
            </FieldGroup>
            <FieldGroup
                label="Password Confirm"
                name="password_confirmation"
                error={errors.password_confirmation}
            >
                <InputField type="password" register={register} />
            </FieldGroup>

            <div className={actionBtnWrapClassName}>
                <LoadingButton
                    isSubmitBtn={true}
                    color="purple"
                    title="Continue"
                    isLoading={loading}
                />
            </div>

            <p className="agreed-text">
                By clicking “Continue”, I agree to the{" "}
                <Link
                    className="link"
                    to={routes.docsInformedConsentPage}
                    target="__blank"
                >
                    CRX Informed Concert
                </Link>{" "}
                and acknowledge the{" "}
                <Link
                    className="link"
                    to={routes.docsPrivacyPolicyPage}
                    target="__blank"
                >
                    Notice of Privacy Practices
                </Link>
            </p>
        </form>
    );
};
