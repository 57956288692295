import * as yup from "yup";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
    BirthdayField,
    FieldGroup,
    GenderField,
    AvatarField,
} from "../../../../components/fields";
import { InputField } from "../../../../components/fields";
import { LoadingButton } from "../../../../components/buttons";

import { ApiValidationError } from "../../../../services/errors";
import { updateMainUserProfile } from "../../../../services/user.service";
import { parseValidationErrors } from "../../../../services/helpers";
import { useUserRootStore } from "../../../../stores/user/userRootStore";

import styles from "./forms.module.scss";

const USER_PROFILE_SCHEME = yup
    .object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        birthday: yup.string().required(),
        gender: yup.string().required(),
        mobilePhone: yup.string().required(),
        weight: yup.string().matches(/^[0-9]+$/, "Must be only digits"),
        height: yup.string().matches(/^[0-9]+$/, "Must be only digits"),
    })
    .required();

export const UserMainData = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [init, userProfile] = useUserRootStore((s) => [
        s.init,
        s.computed.profileData,
    ]);
    const {
        control,
        setError,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FieldValues>({
        values: userProfile,
        resolver: yupResolver(USER_PROFILE_SCHEME),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        const { data: userData, error } = await updateMainUserProfile(data);
        if (error) {
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        } else if (userData !== null) {
            init(userData);
        }
        setLoading(false);
    };

    return (
        <div className={styles["user-main-info"]}>
            <div className={styles["user-main-info__avatar"]}>
                <AvatarField />
            </div>
            <form
                className={styles["user-main-info__fields"]}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className={styles["fields-row"]}>
                    <FieldGroup
                        name="firstName"
                        label="First Name"
                        containerClassName={styles["form-text-input"]}
                        error={errors.firstName}
                    >
                        <InputField register={register} />
                    </FieldGroup>
                    <FieldGroup
                        name="lastName"
                        label="Last Name"
                        containerClassName={styles["form-text-input"]}
                        error={errors.lastName}
                    >
                        <InputField register={register} />
                    </FieldGroup>
                </div>
                <div className={styles["fields-row"]}>
                    <FieldGroup
                        name="birthday"
                        label="Birth Date"
                        containerClassName={styles["form-text-input"]}
                        error={errors.birthday}
                    >
                        <BirthdayField control={control} />
                    </FieldGroup>
                    <FieldGroup
                        name="gender"
                        label="Gender"
                        containerClassName={styles["form-text-input"]}
                        error={errors.gender}
                    >
                        <GenderField control={control} />
                    </FieldGroup>
                    <FieldGroup
                        name="mobilePhone"
                        label="Phone"
                        containerClassName={styles["form-text-input"]}
                        error={errors.mobilePhone}
                    >
                        <InputField register={register} />
                    </FieldGroup>
                </div>
                <div className={styles["fields-row"]}>
                    <FieldGroup
                        name="weight"
                        label="Weight (kg)"
                        containerClassName={`${styles["form-text-input"]} ${styles["form-text-input--small"]}`}
                        error={errors.weight}
                    >
                        <InputField register={register} />
                    </FieldGroup>
                    <FieldGroup
                        name="height"
                        label="Height (inches)"
                        containerClassName={`${styles["form-text-input"]} ${styles["form-text-input--small"]}`}
                        error={errors.height}
                    >
                        <InputField register={register} />
                    </FieldGroup>
                </div>
                <div className={styles["form-submit__wrap"]}>
                    <LoadingButton
                        color="blue"
                        className={styles["form-submit__wrap-submit"]}
                        isSubmitBtn={true}
                        title="Save changes"
                        isLoading={loading}
                    />
                </div>
            </form>
        </div>
    );
};
