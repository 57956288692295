import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { CreatePasswordForm } from "../../../components/forms/create-password";

import routes from "../../../data/routes.json";
import styles from "../auth-pages.module.scss";
import clsx from "clsx";

export const CreatePasswordPage: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const { resetToken } = useParams();
    const [searchParams] = useSearchParams();
    const [msg, setMsg] = useState<string | null>(null);

    const successHandler = (msg: string | null) => {
        setMsg(msg);
        setTimeout(() => {
            navigate(routes.authLogIn);
        }, 1500);
    };

    useEffect(() => {
        if (resetToken === undefined || searchParams.get("email") === null) {
            navigate(routes.authRestorePwd);
        }
    }, [resetToken, searchParams]);

    return (
        <div className={styles["wrapper"]}>
            <div className={styles["title-wrap"]}>
                <h2 className={styles["title"]}>Set new password</h2>
                <p className="section-text">Enter strong password</p>
            </div>
            <div
                className={clsx({
                    [styles["form-wrap"]]: true,
                    [styles["notify-wrap"]]: msg !== null,
                })}
            >
                <CreatePasswordForm
                    email={searchParams.get("email")}
                    token={resetToken}
                    formClassName={styles["form"]}
                    actionBtnWrapClassName={styles["form-action"]}
                    onSuccess={successHandler}
                />
                <span className={`notify__msg ${styles["notify__msg"]}`}>
                    {msg}
                </span>
            </div>
        </div>
    );
};
