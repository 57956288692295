import clsx from "clsx";
import styles from "./spinner.module.scss";

interface Props {
    className?: string;
}

export const MainSpinner = ({ className = "" }: Props) => {
    return (
        <span
            className={clsx({
                "element-spinner": true,
                [styles["loader"]]: true,
                [className]: className !== "",
            })}
        />
    );
};
