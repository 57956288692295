import { DiseasesImage } from "../../../../../components/diseases-image";

import styles from "./disease.module.scss";
import { Link, generatePath } from "react-router-dom";
import routes from "../../../../../data/routes.json";
import { DiseaseItemData } from "../../../../../services/types";

export interface Props extends DiseaseItemData {
    isLocked: boolean;
}

export const DiseasesItem = ({
    name,
    slug,
    description,
    icon,
    isLocked,
}: Props) => {
    return (
        <div className={styles["item"]}>
            <div className={styles["item__image"]}>
                <DiseasesImage name={icon} />
            </div>
            <div className={styles["item__content"]}>
                <h4 className={styles["item__content-title"]}>{name}</h4>
                <p className={styles["item__content-desc"]}>{description}</p>
                {!isLocked && (
                    <div
                        className={`${styles["item__actions"]} diseases-item__action-block`}
                    >
                        <Link
                            to={generatePath(routes.dashQuizWelcomePage, {
                                diseaseSlug: slug,
                            })}
                        >
                            <button
                                className={`button purple ${styles["item__actions-btn"]}`}
                            >
                                Start Now
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};
