import { Link } from "react-router-dom";

import { Modal } from "../Modal";

import { Logo } from "../../logo";
import { CloseIcon } from "../../icons";
import { FooterNavigation, MainNavigation } from "../../navigation";

import styles from "./mobile-nav-modal.module.scss";

const ModalBody = ({ close }: { close: () => void }) => {
    return (
        <div className={styles["body__wrap"]}>
            <div className={styles["body__head"]}>
                <div className={styles["body__head-logo"]}>
                    <Logo />
                </div>
                <CloseIcon
                    containerClass={styles["body__head-close"]}
                    className={styles["body__head-close--icon"]}
                    onClick={close}
                />
            </div>
            <div className={styles["body__content"]}>
                <div className={styles["nav-wrap"]}>
                    <MainNavigation
                        withCorners={false}
                        navClassName={styles["nav"]}
                        navLinkClassName={styles["nav__link"]}
                        navLinkActiveClassName={styles["nav__link--active"]}
                    />
                </div>
                <div className={styles["nav-btns"]}>
                    <button className="button blue">
                        <Link target="blank" to="/about">
                            About Us
                        </Link>
                    </button>
                    <button className="button light-blue">
                        <Link target="blank" to="https://charityrx.com">
                            I have Prescriptions
                        </Link>
                    </button>
                </div>
            </div>
            <div className={styles["body__footer"]}>
                <FooterNavigation
                    containerClassName={styles["body__footer-wrap"]}
                    navClassName={styles["body__footer-nav"]}
                    navLinkClassName={styles["body__footer_nav-item"]}
                    navLinkActiveClassName={
                        styles["body__footer_nav-item--active"]
                    }
                    linkTitleClassName={styles["body__footer_nav-title"]}
                    withCopyright={true}
                />
            </div>
        </div>
    );
};

export interface Props {
    trigger?: JSX.Element;
}

export const MobileNavigationModal = ({ trigger }: Props) => {
    return (
        <Modal trigger={trigger} isFull>
            {(props) => <ModalBody {...props} />}
        </Modal>
    );
};
