import * as yup from "yup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldGroup, InputField } from "../../fields";
import { LoadingButton } from "../../buttons";

import { useUserAuthStore } from "../../../stores/user/userAuthStore";
import { loginUser } from "../../../services/auth.service";
import { ApiValidationError } from "../../../services/errors";
import { parseValidationErrors } from "../../../services/helpers";

import routes from "../../../data/routes.json";

import { FormProps } from "../types";
import { AxiosError } from "axios";
import clsx from "clsx";

const schema = yup
    .object({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })
    .required();

export const LoginInForm = ({
    formClassName,
    globalErrorClassName,
    globalErrorClassNameShow,
    actionBtnWrapClassName,
    onSuccess,
}: FormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FieldValues>({
        resolver: yupResolver(schema),
    });
    const { login } = useUserAuthStore();
    const [loading, setLoading] = useState(false);
    const [globalError, setGlobalError] = useState<string | null>(null);

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        if (Object.keys(errors).length === 0) {
            const { email, password } = data;
            setLoading(true);
            const { data: loginUserResult, error } = await loginUser(
                email,
                password
            );
            if (error) {
                if (error instanceof ApiValidationError) {
                    parseValidationErrors(error, setError);
                } else if (error instanceof AxiosError) {
                    setGlobalError(error?.message);
                } else {
                    setGlobalError("Something went wrong please try again.");
                }
            }
            if (loginUserResult !== null && loginUserResult.token) {
                login(loginUserResult.token);
                onSuccess("");
            }
            setLoading(false);
        }
    };

    return (
        <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup label="E-mail" name="email" error={errors.email}>
                <InputField register={register} />
            </FieldGroup>
            <FieldGroup
                label="Password"
                name="password"
                error={errors.password}
            >
                <InputField type="password" register={register} />
            </FieldGroup>
            {globalErrorClassName && globalErrorClassNameShow && (
                <div
                    className={clsx({
                        [globalErrorClassName]: true,
                        [globalErrorClassNameShow]: globalError,
                    })}
                >
                    <p className="error">{globalError}</p>
                </div>
            )}

            <div className={actionBtnWrapClassName}>
                <LoadingButton
                    isSubmitBtn={true}
                    color="purple"
                    title="Continue"
                    isLoading={loading}
                />
                <Link to={routes.authRestorePwd}>
                    <button className="button purple inverse">
                        Forgot Password?
                    </button>
                </Link>
            </div>
        </form>
    );
};
