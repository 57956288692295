import { Link, useNavigate } from "react-router-dom";
import { FunctionComponent, ReactElement, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Logo } from "../../components/logo";
import { AppBubbleErrors } from "../../components/errors";
import { AuthMainIllustration, AuthNavTabs } from "./ui";

import { useUserAuthStore } from "../../stores/user/userAuthStore";

import routes from "../../data/routes.json";
import styles from "./auth.module.scss";

export const AuthLayout: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const isAuth = useUserAuthStore((s) => s.computed.isAuth);
    useEffect(() => {
        if (isAuth) {
            navigate(routes.dashboard);
        }
    }, [isAuth]);
    return (
        <div className={styles["wrapper"]}>
            <main className={styles["main"]}>
                <Logo />
                <div className={styles["content"]}>
                    <div className={styles["content__mobile-img"]}>
                        <AuthMainIllustration />
                    </div>
                    <AuthNavTabs />
                    <Outlet />
                </div>
                <div className={styles["footer"]}>
                    <p className={styles["footer__notice"]}>
                        Need Help? Contact our{" "}
                        <Link to="/faq">support team</Link>
                    </p>
                </div>
            </main>
            <div className={styles["illustration"]}>
                <AuthMainIllustration />
            </div>
            <AppBubbleErrors />
        </div>
    );
};
