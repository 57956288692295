import client from "./client";

import { SaveUserAddressesType, UpdateUserProfileRequestType } from "../types";

export const getUserRequest = () => {
    return client.get("/api/user");
};

export const updateUserProfileRequest = (
    data: UpdateUserProfileRequestType
) => {
    return client.post("/api/user/profile", data);
};

export const saveUserAddressRequest = (data: SaveUserAddressesType) => {
    return client.post("/api/user/profile/address", data);
};

export const deleteUserAddressRequest = (id: number) => {
    return client.get(`/api/user/profile/address/${id}`);
};

export const updateUserAvatarRequest = (
    formData: FormData,
    fileName: string
) => {
    return client.post("/api/user/profile/avatar", formData, {
        headers: {
            "Content-Type": `multipart/form-data; boundary=${fileName}`,
        },
    });
};
export const deleteUserAvatarRequest = () => {
    return client.post("/api/user/profile/avatar/delete");
};

export const getUserQuizzesRequest = () => {
    return client.get("/api/user/quizzes");
};

export const getUserQuizByIdRequest = (id: string) => {
    return client.get(`/api/user/quizzes/${id}`);
};

export const postCheckoutQuiz = <T>(id: string, data: T) => {
    return client.post(`/api/user/quiz/${id}/checkout`, data);
};
