import { get as _get, filter, find, has, isEmpty } from "lodash";
import { produce } from "immer";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { UserRootStore } from "./types";
import {
    UserAddressType,
    UserData,
    UserInfoConfirmData,
} from "../../services/types";
import { hasEmptyProps } from "../../services/helpers";

const useUserRootStore = create(
    subscribeWithSelector<UserRootStore>((set, get) => ({
        inited: false,
        email: "",
        firstName: "",
        lastName: "",
        birthday: "",
        gender: "",
        mobilePhone: "",
        avatar: null,
        weight: null,
        height: null,
        userQuizzes: null,
        addresses: null,
        computed: {
            get profileData() {
                const data = get();
                return {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    fullName: `${data.firstName} ${data.lastName}`,
                    birthday: data.birthday,
                    gender: data.gender,
                    mobilePhone: data.mobilePhone,
                    weight: data.weight,
                    height: data.height,
                };
            },

            get filledProfile() {
                const data = get();
                // Get all fields and make profile object with only neccesssary fields.
                const profile = {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthday: data.birthday,
                    gender: data.gender,
                    mobilePhone: data.mobilePhone,
                };
                return !hasEmptyProps(profile);
            },

            get hasUserQuizzes() {
                return !isEmpty(get().userQuizzes);
            },

            get userMainAddress() {
                const addrs = get().addresses;
                if (addrs !== null && addrs?.length > 0) {
                    const addr = find(addrs, ["type", UserAddressType.main]);
                    if (addr) {
                        return addr;
                    }
                }
                return null;
            },

            get userDeliveryAddresses() {
                const addrs = get().addresses;
                if (addrs !== null && addrs?.length > 0) {
                    const addresses = filter(addrs, function (o) {
                        return (
                            o.type === UserAddressType.main ||
                            o.type === UserAddressType.delivery
                        );
                    });
                    return addresses;
                } else {
                    return [];
                }
            },
        },
        init: (user: UserData) =>
            set(
                produce((state) => {
                    state.inited = true;
                    state.email = user.email;

                    if (user.profile !== null) {
                        state.firstName = user.profile.firstName;
                        state.lastName = user.profile.lastName;
                        state.birthday = user.profile.birthday;
                        state.gender = user.profile.gender;
                        state.mobilePhone = user.profile.mobilePhone;
                        state.avatar = user.profile?.avatar;
                        state.weight = user.profile?.weight;
                        state.height = user.profile?.height;
                    }

                    if (user.addresses !== null) {
                        state.addresses = user.addresses;
                    }
                })
            ),
        initUserQuizzes: (quizzes) =>
            set(
                produce((state) => {
                    state.userQuizzes = quizzes;
                })
            ),

        getConfirmedUserDataByFields: (mapData) => {
            const data = get();
            const result = filter(
                mapData.map((item) => {
                    if (has(data, item.key)) {
                        return {
                            ...item,
                            value: _get(data, item.key),
                        };
                    }
                }),
                (p) => p !== undefined
            );
            return (result as UserInfoConfirmData[]) || [];
        },
    }))
);

// useQuizRootStore.subscribe(
//     (state) => state.currentPage,
//     (saved) => {
//         console.log(saved);
//     }
// );

// useQuizRootStore.subscribe(
//     (state) => state.saved,
//     (saved) => console.log(saved),
//     {
//         fireImmediately: true,
//     }
// );

export { useUserRootStore };
