import { Loader } from "../../../../components/loader";
import styles from "./quiz_loader.module.scss";

export const QuizLoader = () => {
    return (
        <div className={styles["wrap"]}>
            <Loader />
        </div>
    );
};
