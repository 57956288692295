import clsx from "clsx";
import { cloneElement } from "react";

import { FieldGroupProps } from "../types";

import styles from "./field-group.module.scss";
import { generateRandomString } from "../../../services/helpers";

export const FieldGroup = ({
    name,
    label,
    error,
    success,
    children,
    containerClassName = "",
    withoutLabelConnect = false,
}: FieldGroupProps) => {
    const id = withoutLabelConnect
        ? `${name}-${generateRandomString(8)}`
        : undefined;

    return (
        <div
            className={clsx({
                [styles["container"]]: true,
                [containerClassName]: containerClassName !== "",
            })}
        >
            {label && (
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
            )}
            <div className={styles.inputGroup}>
                <div
                    className={clsx(styles.inputWrapper, {
                        [styles.inputWrapper_invalid]: error,
                        [styles.inputWrapper_valid]: success,
                    })}
                >
                    {cloneElement(children, { name, id })}
                </div>
                <span
                    className={clsx({
                        [styles["error"]]: true,
                        [styles["error--show"]]: error !== undefined,
                    })}
                >
                    {error && error?.message
                        ? error.message.toString()
                        : "You must have made a mistake."}
                </span>
            </div>
        </div>
    );
};
