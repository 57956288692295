import moment from "moment";
import { Link, generatePath } from "react-router-dom";
import { QuizStatuses, UserQuizItem } from "../../../../../services/types";

import routes from "../../../../../data/routes.json";

interface Props {
    containerClassName: string;
    dataClassName: string;
    quiz: UserQuizItem;
}

export const ActionBtn = ({
    containerClassName,
    dataClassName,
    quiz,
}: Props) => {
    return (
        <div className={containerClassName}>
            <span className={dataClassName}>
                {quiz && moment(quiz.date).format("lll")}
            </span>
            {quiz.status === QuizStatuses.processing && (
                <Link
                    to={generatePath(routes.dashQuizMainPage, {
                        diseaseSlug: quiz.slug,
                        quizType: quiz.type,
                        quizId: quiz.id,
                    })}
                >
                    <button className="button purple">Continue</button>
                </Link>
            )}
            {quiz.status === QuizStatuses.payment && (
                <Link
                    to={generatePath(routes.dashQuizPaymentPage, {
                        diseaseSlug: quiz.slug,
                        quizType: quiz.type,
                        quizId: quiz.id,
                    })}
                >
                    <button className="button blue">Payment</button>
                </Link>
            )}
        </div>
    );
};
