interface iApiNetworkError {
    type: string;
}
export class ApiNetworkError extends Error implements iApiNetworkError {
    type = "";
    constructor(msg: string) {
        super(msg);
        this.type = "apiNetworkError";
        Object.setPrototypeOf(this, ApiNetworkError.prototype);
    }
}
