import { Link, generatePath } from "react-router-dom";
import { forwardRef } from "react";
import { DiseasesImage } from "../../../../../components/diseases-image";
import routes from "../../../../../data/routes.json";

import styles from "./disease-mobile.module.scss";
import { DiseaseItemData } from "../../../../../services/types";

export const DiseasesMobileItem = forwardRef<HTMLDivElement, DiseaseItemData>(
    function DiseasesMobileItem(props, ref) {
        const { icon, name, description, slug } = props;
        return (
            <div ref={ref} className={styles["item"]}>
                <div className={styles["item__image"]}>
                    <DiseasesImage name={icon} />
                </div>
                <div className={styles["item__content"]}>
                    <h4 className={styles["item__content-title"]}>{name}</h4>
                    <p className={styles["item__content-desc"]}>
                        {description}
                    </p>
                    <Link
                        to={generatePath(routes.dashQuizWelcomePage, {
                            diseaseSlug: slug,
                        })}
                    >
                        <button
                            className={`button purple ${styles["item__actions-btn"]}`}
                        >
                            Start Now
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
);
