import clsx from "clsx";
import styles from "./small-circle-spinner.module.scss";

interface Props {
    className?: string;
}

export const SmallCircleSpinner = ({ className = "" }: Props) => {
    return (
        <div
            className={clsx({
                [styles["circle"]]: true,
                [className]: className !== "",
            })}
        >
            <div
                className={`${styles["circle1"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle2"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle3"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle4"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle5"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle6"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle7"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle8"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle9"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle10"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle11"]} ${styles["circle__child"]}`}
            />
            <div
                className={`${styles["circle12"]} ${styles["circle__child"]}`}
            />
        </div>
    );
};
