import { FunctionComponent, ReactElement } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";
import { produce } from "immer";

type OptionItem = {
    label: string;
    value: string;
    fullWidth?: boolean;
};

interface OptionProps {
    item: OptionItem;
    isCurrent: boolean | undefined;
    onClick: (value: string) => void;
}

interface Props {
    id: string;
    content: [];
    currentValue: string | null | undefined;
    saveHandler: (value: string) => void;
}

const Option = ({ item, isCurrent, onClick }: OptionProps) => (
    <li
        className={clsx({
            [styles["select__list-item"]]: true,
            [styles["select__list-item--full"]]: item?.fullWidth,
        })}
        onClick={() => onClick(item.value)}
    >
        <span
            className={clsx({
                [styles["select__item-multi"]]: true,
                [styles["select__item-multi--checked"]]: isCurrent,
            })}
        />
        <span className={styles["item-label"]}>{item.label}</span>
    </li>
);

export const QuestionFieldMultiSelect: FunctionComponent<Props> = ({
    currentValue,
    content,
    saveHandler,
}): ReactElement => {
    const clickHandler = (value: string) => {
        if (
            currentValue === null ||
            currentValue === undefined ||
            currentValue === ""
        ) {
            saveHandler(value);
        } else {
            const added = produce(currentValue.split(";"), (draft) => {
                const index = draft.indexOf(value);
                if (index === -1) {
                    draft.push(value);
                } else {
                    draft.splice(index, 1);
                }
            });
            saveHandler(added.join(";"));
        }
    };

    if (content.length === 0) {
        return <></>;
    }

    return (
        <ul
            className={`${styles["select__list"]} ${styles["select__list--multi"]}`}
        >
            {content.map((option: OptionItem, i) => (
                <Option
                    key={i}
                    item={option}
                    isCurrent={currentValue?.includes(option.value)}
                    onClick={clickHandler}
                />
            ))}
        </ul>
    );
};
