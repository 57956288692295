import { FunctionComponent, ReactElement, useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useQuizRootStore } from "../../../stores/quiz/quizRootStore";
import { QuizStatuses } from "../../../services/types";

import { QuizWrapper } from "../wrapper";
import { QuizNavigation } from "../ui";

import { QuizQuestionItem } from "./quiz-question-item";

import routes from "../../../data/routes.json";
import styles from "./quiz-main-page.module.scss";

export const MainQuizPage: FunctionComponent = (): ReactElement => {
    const { diseaseSlug, quizType, quizId } = useParams();

    const navigate = useNavigate();

    const [quizStatus, currentQuizPage] = useQuizRootStore((s) => [
        s.status,
        s.computed.page,
    ]);

    console.log(diseaseSlug, quizType, quizId);

    useEffect(() => {
        if (quizStatus === QuizStatuses.payment) {
            navigate(
                generatePath(routes.dashQuizPaymentPage, {
                    diseaseSlug,
                    quizType,
                    quizId,
                })
            );
        }
    }, [quizStatus]);

    return (
        <QuizWrapper>
            <div className={styles["container"]}>
                {currentQuizPage?.title && (
                    <div className={styles["title"]}>
                        <h2>{currentQuizPage.title}</h2>
                    </div>
                )}
                <div className={styles["questions"]}>
                    {currentQuizPage?.questions.map((question, i) => (
                        <QuizQuestionItem
                            key={i}
                            id={question.id}
                            title={question.title}
                            type={question.type}
                            content={question.content}
                        />
                    ))}
                </div>
                <div className={styles["bottom"]}>
                    <QuizNavigation />
                </div>
            </div>
        </QuizWrapper>
    );
};
