import { CreditCardIcon } from "../../../../components/icons";
import { UserQuizItem } from "../../../../services/types";
import { DetailsBlock } from "../ui";
import styles from "./details-treatment.module.scss";

interface Props {
    quiz: UserQuizItem;
}

export const DetailsPayment = ({ quiz }: Props) => {
    return (
        <DetailsBlock title="Payment" icon={<CreditCardIcon color="#2b307a" />}>
            <div className={styles["payment"]}>
                <div className={styles["payment-block"]}>
                    <span className={styles["payment-block__label"]}>
                        Card details
                    </span>
                    <p className={styles["payment-block__value"]}>
                        5495***********34
                    </p>
                </div>
                <div className={styles["payment-block"]}>
                    <span className={styles["payment-block__label"]}>
                        Billing Address
                    </span>
                    <p className={styles["payment-block__value"]}>
                        CA 92103 New York, 5th Avenu, 10
                    </p>
                </div>
            </div>
        </DetailsBlock>
    );
};
