import * as yup from "yup";
import { useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProps } from "../types";
import { FieldGroup, InputField } from "../../fields";
import { LoadingButton } from "../../buttons";

import { forgotPassword } from "../../../services/auth.service";
import { ApiValidationError } from "../../../services/errors";
import { parseValidationErrors } from "../../../services/helpers";

const schema = yup
    .object({
        email: yup.string().required(),
    })
    .required();

export const ForgotPasswordForm = ({
    formClassName,
    actionBtnWrapClassName,
    onSuccess,
}: FormProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FieldValues>({
        resolver: yupResolver(schema),
    });
    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        const { data: resetPwdMsg, error } = await forgotPassword(data.email);
        if (error) {
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        } else {
            onSuccess(resetPwdMsg?.toString() || "");
        }
        setLoading(false);
    };

    return (
        <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup label="E-mail" name="email" error={errors.email}>
                <InputField register={register} />
            </FieldGroup>
            <div className={actionBtnWrapClassName}>
                <LoadingButton
                    isLoading={loading}
                    title="Continue"
                    color="purple"
                    isSubmitBtn={true}
                />
            </div>
        </form>
    );
};
