import * as yup from "yup";
import { get } from "lodash";
import React, { FunctionComponent, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import {
    AddressField,
    FieldGroup,
    InputField,
} from "../../../../components/fields";
import { ArrowNextIcon } from "../../../../components/icons";
import { FormProps } from "./types";

import { saveUserAddress } from "../../../../services/user.service";
import { ApiValidationError } from "../../../../services/errors";
import { parseValidationErrors } from "../../../../services/helpers";
import { LoadingButton } from "../../../../components/buttons";

import styles from "./welcome-forms.module.scss";

export const AddressUserStep: FunctionComponent<FormProps> = ({
    nextStep,
    user,
}) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const VALIDATOR = yup
        .object({
            address: yup
                .string()
                .required("The full Address is required field"),
            additional: yup
                .string()
                .required("The Apt, Suite is required field"),
            zipcode: yup.string().required("The ZipCode is required field"),
        })
        .required();

    const {
        control,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(VALIDATOR),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setFormLoading(true);
        const { error } = await saveUserAddress(data);
        setFormLoading(false);

        if (error !== undefined) {
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        } else {
            nextStep();
        }
    };

    return (
        <div className={styles["users-form"]}>
            <h3 className={styles["users-form__title"]}>Where you are live?</h3>
            <form
                className={styles["users-form__wrap"]}
                onSubmit={handleSubmit(onSubmit)}
            >
                <input type="hidden" value="main" {...register("type")} />
                <FieldGroup
                    name="location"
                    label="City, State"
                    containerClassName={styles["form__group"]}
                    error={get(errors, "location")}
                >
                    <AddressField
                        control={control}
                        placeholder="Miami, FL"
                        filteredResultsBy={{
                            type: "string",
                            value: ", USA",
                        }}
                        typesOfAddress={["(cities)"]}
                    />
                </FieldGroup>
                <FieldGroup
                    name="address"
                    label="Address"
                    containerClassName={styles["users-form__group"]}
                    error={get(errors, "address")}
                >
                    <AddressField
                        control={control}
                        getValues={getValues}
                        filteredResultsBy={{
                            type: "field",
                            value: "location",
                            additional: ", USA",
                        }}
                        typesOfAddress={["address"]}
                        placeholder="1101 Brickell ave, South Tower - 8Th Floor"
                    />
                </FieldGroup>
                <FieldGroup
                    name="additional"
                    label="Apt, Suite"
                    containerClassName={styles["users-form__group"]}
                    error={get(errors, "additional")}
                >
                    <InputField
                        register={register}
                        placeholder="8 floor, 132"
                    />
                </FieldGroup>
                <FieldGroup
                    name="zipcode"
                    label="Zip Code"
                    containerClassName={styles["users-form__group"]}
                    error={get(errors, "zipcode")}
                >
                    <InputField register={register} placeholder="33131" />
                </FieldGroup>
                <LoadingButton
                    isLoading={formLoading}
                    isSubmitBtn={true}
                    className={styles["users-form__btn"]}
                    color="blue"
                    title={
                        <ArrowNextIcon
                            className={styles["users-form__btn-arrow"]}
                        />
                    }
                />
            </form>
        </div>
    );
};
