import {
    MouseEvent,
    FunctionComponent,
    ReactElement,
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { EmailVerifyCodeForm } from "../../../components/forms";

import routes from "../../../data/routes.json";
import styles from "../auth-pages.module.scss";
import { resendVerifyEmailCode } from "../../../services/auth.service";

const DEFAULT_TIME = 60;

export const VerificationPage: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(DEFAULT_TIME);
    const [searchParams] = useSearchParams();

    const emailSpanRef = useRef<HTMLSpanElement | null>(null);

    const timeOutCallback = useCallback(
        () => setTimer((currTimer) => currTimer - 1),
        []
    );

    useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);

    useEffect(() => {
        const email = searchParams.get("email");
        if (emailSpanRef?.current && email) {
            emailSpanRef.current.innerText = email;
        } else {
            // Don't show this page if we don't have email
            navigate(routes.authRestorePwd);
        }
    }, [searchParams]);

    const successHandler = () => {
        navigate(routes.authLogIn);
    };

    const resendCodeHandler = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const email = searchParams.get("email");

        if (timer === 0 && email) {
            await resendVerifyEmailCode(email);
            setTimer(DEFAULT_TIME);
        } else {
            return false;
        }
    };

    return (
        <div className={styles["wrapper"]}>
            <div className={styles["title-wrap"]}>
                <h2 className={styles["title"]}>Verification</h2>
                <p className="section-text">
                    Enter the verification 6-digits code that was sent to{" "}
                    <span ref={emailSpanRef} className={styles["email-text"]} />
                    .<br /> Please check your inbox or spam.
                </p>
            </div>
            <div className={styles["form-wrap"]}>
                <EmailVerifyCodeForm
                    email={searchParams.get("email")}
                    formClassName={styles["form"]}
                    actionBtnWrapClassName={styles["form-action"]}
                    onSuccess={successHandler}
                />
                <div className="">
                    <button
                        className={`button purple inverse ${styles["resend-btn"]}`}
                        onClick={resendCodeHandler}
                    >
                        {timer <= 0
                            ? "Send Code"
                            : `Request A New Code ${timer}`}
                    </button>
                </div>
            </div>
        </div>
    );
};
