import clsx from "clsx";
import { QuizTypes } from "../../../../../services/types";

import QuizInitialImage1x from "../../../../../assets/images/illustration/quiz-type-initial-1x.png";
import QuizInitialImage2x from "../../../../../assets/images/illustration/quiz-type-initial-2x.png";
import QuizInitialImage3x from "../../../../../assets/images/illustration/quiz-type-initial-3x.png";

import QuizRefillImage1x from "../../../../../assets/images/illustration/quiz-type-refill-1x.png";
import QuizRefillImage3x from "../../../../../assets/images/illustration/quiz-type-refill-3x.png";
import QuizRefillImage2x from "../../../../../assets/images/illustration/quiz-type-refill-2x.png";

import styles from "../welcome-content-selection-step.module.scss";

interface ActionBlockProps {
    isActive: boolean;
    clickHandler: (type: QuizTypes) => void;
}

const ContentInitialActionBlock = ({
    isActive,
    clickHandler,
}: ActionBlockProps) => {
    return (
        <div
            className={clsx({
                [styles["action-block"]]: true,
                [styles["action-block--active"]]: isActive,
            })}
            onClick={() => clickHandler(QuizTypes.initial)}
        >
            <div className={styles["action-block__illustration"]}>
                <img
                    src={QuizInitialImage1x}
                    srcSet={`${QuizInitialImage2x} 2x, ${QuizInitialImage3x} 3x`}
                    alt=""
                    className={styles["action-block__illustration--img"]}
                />
            </div>
            <div className={styles["action-block__content"]}>
                <h3 className={styles["action-block__content--title"]}>
                    This is my first experience
                </h3>
                <p className={styles["action-block__content--desc"]}>
                    I want to consult a doctor and get a prescription for
                    medicine
                </p>
            </div>
        </div>
    );
};

const ContentRefillActionBlock = ({ clickHandler }: ActionBlockProps) => {
    return (
        <div
            className={styles["action-block"]}
            onClick={() => clickHandler(QuizTypes.refill)}
        >
            <div className={styles["action-block__illustration"]}>
                <img
                    src={QuizRefillImage1x}
                    srcSet={`${QuizRefillImage2x} 2x, ${QuizRefillImage3x} 3x`}
                    alt=""
                    className={styles["action-block__illustration--img"]}
                />
            </div>
            <div className={styles["action-block__content"]}>
                <h3 className={styles["action-block__content--title"]}>
                    I already took the survey
                </h3>
                <p className={styles["action-block__content--desc"]}>
                    I want to renew my prescription
                </p>
            </div>
        </div>
    );
};

export { ContentInitialActionBlock, ContentRefillActionBlock };
