import clsx from "clsx";
import { FunctionComponent, ReactElement, useEffect } from "react";

import { DiseasesGroup, ServicesSearchBar, DiseasesMobileGroup } from "./ui";
import { useDiseaseRootStore } from "../../../stores/disease/diseaseRootStore";

import styles from "./services-page.module.scss";
import { isMobileWidth } from "../../../services/helpers";
import { useUserRootStore } from "../../../stores/user/userRootStore";

export const ServicesPage: FunctionComponent = (): ReactElement => {
    const isFilledProfile = useUserRootStore((s) => s.computed.filledProfile);
    const [diseases, init] = useDiseaseRootStore((state) => [
        state.computed.diseasesByGroup,
        state.init,
    ]);

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div
                    className={clsx({
                        [styles["wrap"]]: true,
                        [styles["wrap--hidden"]]: !isFilledProfile,
                    })}
                >
                    <div className={styles["head"]}>
                        <h2>How can we help?</h2>
                    </div>
                    <div className={styles["search-bar"]}>
                        <ServicesSearchBar />
                    </div>
                    <div className={styles["main"]}>
                        {diseases?.map((group, i) =>
                            !isMobileWidth() ? (
                                <DiseasesGroup
                                    key={i}
                                    name={group.name}
                                    items={group.items}
                                    isLocked={!isFilledProfile}
                                />
                            ) : (
                                <DiseasesMobileGroup
                                    key={i}
                                    name={group.name}
                                    items={group.items}
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
