import { has } from "lodash";
import {
    getUserRequest,
    updateUserProfileRequest,
    getUserQuizzesRequest,
    updateUserAvatarRequest,
    deleteUserAvatarRequest,
    getUserQuizByIdRequest,
    saveUserAddressRequest,
    deleteUserAddressRequest,
    postCheckoutQuiz,
} from "./api/user.api";
import {
    ServiceGetUserResponse,
    ServiceUpdateUserProfileResponse,
    ServiceGetUserQuizzesResponse,
    UpdateUserProfileRequestType,
    ServiceUpdateAvatarResponse,
    ServiceDeleteAvatarResponse,
    ServiceGetUserQuizResponse,
    SaveUserAddressesType,
} from "./types";
import moment from "moment";
import { FieldValues } from "react-hook-form";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const getUser = async (): Promise<ServiceGetUserResponse> => {
    try {
        const response = await getUserRequest();
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const updateMainUserProfile = async (
    data: UpdateUserProfileRequestType
): Promise<ServiceUpdateUserProfileResponse> => {
    try {
        if (has(data, "password")) delete data.password;
        if (has(data, "email")) delete data.email;

        const response = await updateUserProfileRequest({
            ...data,
            ...(data.birthday &&
                data.birthday !== "" && {
                    birthday: moment(
                        new Date(data.birthday).toISOString()
                    ).format("YYYY-MM-DD"),
                }),
        });
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const saveUserAddress = async (
    data: FieldValues
): Promise<ServiceUpdateUserProfileResponse> => {
    try {
        const props = {
            addresses: !Array.isArray(data) ? new Array(data) : data,
        };

        const response = await saveUserAddressRequest(
            props as SaveUserAddressesType
        );
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const deleteUserAddress = async (
    id: number
): Promise<ServiceUpdateUserProfileResponse> => {
    try {
        const response = await deleteUserAddressRequest(id);
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const getUserQuizzes =
    async (): Promise<ServiceGetUserQuizzesResponse> => {
        try {
            const response = await getUserQuizzesRequest();
            return { data: response.data, error: undefined };
        } catch (error) {
            return { data: null, error };
        }
    };

export const updateUserAvatar = async (
    file: File
): Promise<ServiceUpdateAvatarResponse> => {
    try {
        const formData = new FormData();
        formData.append("file", file, file.name);

        const response = await updateUserAvatarRequest(formData, file.name);
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};
export const deleteUserAvatar =
    async (): Promise<ServiceDeleteAvatarResponse> => {
        try {
            const response = await deleteUserAvatarRequest();
            return { data: response.data, error: undefined };
        } catch (error) {
            return { data: null, error };
        }
    };

export const getUserQuizById = async (
    id: string | number
): Promise<ServiceGetUserQuizResponse> => {
    try {
        const response = await getUserQuizByIdRequest(
            new String(id).toString()
        );
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

interface MakeCheckoutOfQuiz<T> {
    quizId: number;
    data: T;
}

export const makeCheckoutOfQuiz = async <T>({
    quizId,
    data,
}: MakeCheckoutOfQuiz<T>): Promise<ServiceGetUserQuizResponse> => {
    try {
        const response = await postCheckoutQuiz<T>(
            new String(quizId).toString(),
            data
        );
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};
