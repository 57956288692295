import Popup from "reactjs-popup";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PopupActions } from "reactjs-popup/dist/types";
import { useLocation } from "react-router-dom";

import styles from "./modal.module.scss";
import clsx from "clsx";

interface Props {
    open?: boolean;
    trigger?: JSX.Element | null;
    children: ({ close }: { close: () => void }) => ReactNode;
    isFull?: boolean;
    isMobileDialog?: boolean;
}

export const Modal = ({
    open = false,
    trigger = null,
    children,
    isFull = false,
    isMobileDialog = false,
}: Props) => {
    const { pathname } = useLocation();
    const refModal = useRef<PopupActions>(null);
    const [isOpen, setOpen] = useState<boolean>(open);
    const close = () => {
        if (trigger === null) {
            setOpen(false);
        } else {
            refModal.current?.close();
        }
    };

    // Close when we go to another path.
    useEffect(() => {
        close();
    }, [pathname]);

    return (
        <Popup
            ref={refModal}
            closeOnDocumentClick
            closeOnEscape
            className={clsx({
                "crx-full-modal": isFull,
                "crx-mobile-dialog": isMobileDialog,
                "crx-modal": !isMobileDialog && !isFull,
            })}
            modal
            lockScroll
            open={isOpen}
            onClose={close}
            {...(trigger !== null && { trigger })}
        >
            <div className={isFull ? styles["wrap-full"] : styles["wrap"]}>
                {children({ close })}
            </div>
        </Popup>
    );
};
