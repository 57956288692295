import { matchSorter } from "match-sorter";
import { chain, find, map } from "lodash";
import { produce } from "immer";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { DiseaseStore, DiseaseGroup } from "./types";

import { getDiseases } from "../../services/disease.service";
import { DiseaseItemData } from "../../services/types";

const useDiseaseRootStore = create(
    subscribeWithSelector<DiseaseStore>((set, get) => ({
        data: null, // Data Quiz from server.
        sortKey: "",
        loading: true,

        computed: {
            get diseases(): DiseaseItemData[] | null {
                return get().data;
            },

            get diseasesByGroup(): DiseaseGroup[] | [] {
                let diseases = get().computed.diseases;
                const sortBy = get().sortKey;

                if (diseases === null) return [];

                if (sortBy !== "") {
                    diseases = matchSorter(diseases, sortBy, {
                        keys: ["name"],
                        threshold: matchSorter.rankings.WORD_STARTS_WITH,
                    });
                }
                const result = chain(diseases)
                    .groupBy("groupId")
                    .map((value) => ({
                        name: value[0].groupName,
                        items: value,
                    }))
                    .value();
                return result;
            },

            get diseasesNames(): string[] | [] {
                const diseases = get().computed.diseases;
                if (diseases) {
                    return map(diseases, "name");
                }
                return [];
            },
        },

        getItemBySlug: (slug: string) => {
            return find(get().computed.diseases, ["slug", slug]);
        },

        setItems: (items) =>
            set(
                produce((state) => {
                    state.data = items;
                })
            ),

        init: async () => {
            if (get().computed.diseases === null) {
                set({ loading: true }); // ?
                const { data, error } = await getDiseases();
                if (data !== null && error === undefined) {
                    set({ data, loading: false });
                } else {
                    // eslint-disable-next-line no-console
                    console.log("ERROR when inited diseases: ", error);
                }
            }
            return;
        },

        setSortKey: (val: string) =>
            set(
                produce((state) => {
                    state.sortKey = val;
                })
            ),
    }))
);

export { useDiseaseRootStore };
