import { clsx } from "clsx";

import styles from "./input.module.scss";
import { InputProps } from "../types";

export const Input = ({
    type = "text",
    value,
    placeholder = "",
    readOnly = false,
    className = "",
    required,
    disabled,
    name,
    id,
    registerOptions = {},
    register,
    onChange,
    onFocus,
    onBlur,
}: InputProps) => {
    return (
        <input
            className={clsx({
                [styles["input"]]: true,
                [className]: className !== "",
            })}
            type={type}
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            id={id}
            onFocus={() => onFocus && onFocus()}
            onBlur={() => onBlur && onBlur()}
            onChange={(e) => onChange && onChange(e)}
            {...(register && name && register(name, registerOptions))}
        />
    );
};
