import clsx from "clsx";
import BlankPageImage1x from "../../../../../assets/images/illustration/crx-blank-history-page-1x.png";
import BlankPageImage2x from "../../../../../assets/images/illustration/crx-blank-history-page-2x.png";
import BlankPageImage3x from "../../../../../assets/images/illustration/crx-blank-history-page-3x.png";

import styles from "./history-blank-page.module.scss";

interface Props {
    opacity: boolean;
}

export const BlankPage = ({ opacity }: Props) => {
    return (
        <div
            className={clsx({
                [styles["wrap"]]: true,
                [styles["wrap--opacity"]]: opacity,
            })}
        >
            <h2 className={styles["blank-title"]}>
                Here will be a history of visits to the doctor
            </h2>
            <div className={styles["blank-image"]}>
                <img
                    src={BlankPageImage1x}
                    srcSet={`${BlankPageImage2x} 2x, ${BlankPageImage3x} 3x`}
                    alt=""
                />
            </div>
        </div>
    );
};
