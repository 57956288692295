import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { Controller, ControllerRenderProps } from "react-hook-form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { AddressField } from "../types";
import { Input } from "../input";
import { SmallCircleSpinner } from "../../spinner";

import styles from "./address-field.module.scss";

interface AutoCompleteItemProps {
    item: google.maps.places.AutocompletePrediction;
    onSelect: (val: string) => void;
    filter: () => string;
}

const AutoCompleteItem = ({
    item,
    filter,
    onSelect,
}: AutoCompleteItemProps) => {
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        const filterStr = filter();
        if (filterStr !== null) {
            setValue(item.description.replace(filterStr, ""));
        } else {
            setValue(item.description);
        }
    }, [item]);

    return (
        <li className={styles["list__item"]} onClick={() => onSelect(value)}>
            <span className={styles["list__item-value"]}>{value}</span>
        </li>
    );
};

export const Address = ({
    name,
    control,
    placeholder,
    typesOfAddress = [],
    filteredResultsBy,
    getValues,
}: AddressField) => {
    const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
    if (REACT_APP_GOOGLE_MAPS_API_KEY === undefined || name === undefined)
        return null;

    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
        usePlacesService({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            debounce: 600,
            options: {
                input: "FL",
                types: typesOfAddress,
                componentRestrictions: { country: "us" },
            },
        });

    const clickOnItemHandler = (
        value: string,
        field: ControllerRenderProps
    ) => {
        field.onChange(value);
        setShowDropDown(false);
    };

    // Filter handler in case if we should change value string.
    const filteredHandler = (): string => {
        if (filteredResultsBy?.type === "string") {
            return filteredResultsBy.value;
        } else if (filteredResultsBy?.type === "field" && getValues) {
            return `, ${getValues(filteredResultsBy.value)}${
                filteredResultsBy?.additional
            }`;
        } else {
            return "";
        }
    };

    return (
        <div className={styles["container"]}>
            <Controller
                name={name}
                control={control}
                defaultValue={""}
                render={({ field }) => (
                    <>
                        <Input
                            placeholder={placeholder}
                            value={field.value}
                            onChange={(evt) => {
                                getPlacePredictions({
                                    input: evt.currentTarget.value,
                                });
                                setShowDropDown(true);
                                field.onChange(evt.currentTarget.value);
                            }}
                        />
                        <ul
                            className={clsx({
                                [styles["dropdown-result-list"]]: true,
                                [styles["dropdown-result-list--show"]]:
                                    placePredictions.length > 0 && showDropDown,
                            })}
                        >
                            {placePredictions.map((item, i) => (
                                <AutoCompleteItem
                                    key={i}
                                    item={item}
                                    onSelect={(value) =>
                                        clickOnItemHandler(value, field)
                                    }
                                    filter={filteredHandler}
                                />
                            ))}
                        </ul>
                    </>
                )}
            />
            <div
                className={clsx({
                    [styles["dropdown-loading"]]: true,
                    [styles["dropdown-loading--show"]]:
                        isPlacePredictionsLoading,
                })}
            >
                <SmallCircleSpinner
                    className={styles["dropdown-loading__spinner"]}
                />
            </div>
        </div>
    );
};
