import { Component, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface States {
    hasError: boolean;
}

class AppErrorBoundary extends Component<Props, States> {
    // eslint-disable-next-line react/sort-comp
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: {}) {
        // console.log(error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any) {
        // console.log("componentDidCatch: ", error, info);
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        // logErrorToMyService(error, info.componentStack);
    }

    render() {
        // console.log(this.state, 123);
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // return this.props.fallback;
        }

        return this.props.children;
    }
}

export { AppErrorBoundary };
