import { SyntheticEvent, useState } from "react";
import { InputProps } from "../types";

import { InputWithMask } from "./InputWithMask";

export const CreditCardInput = ({ name, register }: InputProps) => {
    const [fieldMask, setFieldMask] = useState<string>("9999-9999-9999-9999");
    const [fieldPlaceholder, setFieldPlaceholder] = useState<string>(
        "xxxx xxxx xxxx xxxx"
    );

    const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (/^3[47]/.test(value)) {
            setFieldMask("9999-999999-99999");
            setFieldPlaceholder("xxxx-xxxxxx-xxxxx");
        } else {
            setFieldMask("9999-9999-9999-9999");
            setFieldPlaceholder("xxxx xxxx xxxx xxxx");
        }
    };

    return (
        <InputWithMask
            mask={fieldMask}
            placeholder={fieldPlaceholder}
            name={name}
            register={register}
            onChange={onChange}
        />
    );
};
