import { useEffect, useState } from "react";
import { useErrorsRootStore } from "../../../stores/errors/errorsRootStore";
import { ApiNetworkError } from "../../../services/errors";

import { CloseIcon } from "../../icons";
import { ExclamationPointIcon } from "../../icons/elements";

import styles from "./error-bubble.module.scss";

interface ErrorBubbleProps {
    error: ApiNetworkError;
}
const ErrorBubble = ({ error }: ErrorBubbleProps) => {
    let timer: ReturnType<typeof setTimeout>;
    const [show, setShow] = useState(true);

    useEffect(() => {
        timer = setTimeout(() => {
            setShow(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    if (!show) return null;

    return (
        <div className={styles["bubble"]}>
            <ExclamationPointIcon
                className={styles["bubble__point-icon"]}
                color="#EB5757"
            />
            <span className={styles["bubble__msg"]}>{error.message}</span>
            <div
                className={styles["bubble__close"]}
                onClick={() => setShow(false)}
            >
                <CloseIcon className={styles["bubble__close-icon"]} />
            </div>
        </div>
    );
};

export const AppBubbleErrors = () => {
    const { errors } = useErrorsRootStore();
    return (
        <div className={styles["wrap"]}>
            {errors.map((errorItem, i) => (
                <ErrorBubble key={i} error={errorItem} />
            ))}
        </div>
    );
};
