import { useNavigate } from "react-router-dom";

import QuestionDoctorImage1x from "../../../assets/images/illustration/crx-doctor-question-1x.png";
import QuestionDoctorImage2x from "../../../assets/images/illustration/crx-doctor-question-2x.png";
import QuestionDoctorImage3x from "../../../assets/images/illustration/crx-doctor-question-3x.png";

import styles from "./error-not-found.module.scss";

interface Props {
    title?: string;
    message?: string;
}

export const ErrorNotFound = ({
    title = "Oops, something went wrong",
    message = "Please try again or some later.",
}: Props) => {
    const navigate = useNavigate();

    return (
        <div className={styles["wrap"]}>
            <div className={styles["illustration"]}>
                <img
                    src={QuestionDoctorImage1x}
                    srcSet={`${QuestionDoctorImage2x} 2x, ${QuestionDoctorImage3x} 3x`}
                    alt=""
                    draggable={false}
                />
            </div>
            <div className={styles["content"]}>
                <div className={styles["content__head"]}>
                    <h3 className={styles["content__head-title"]}>{title}</h3>
                    <p className={styles["content__head-text"]}>{message}</p>
                </div>
                <div className={styles["content__actions"]}>
                    <button
                        onClick={() => navigate(-1)}
                        className={`${styles["content__actions-btn"]} button light-blue`}
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    );
};
