import clsx from "clsx";
import React, { FunctionComponent, ReactElement, useState } from "react";
import { FormProps } from "./forms/types";

import DoctorsTogetherImg1x from "../../../assets/images/illustration/doctors-together-1x.png";
import DoctorsTogetherImg2x from "../../../assets/images/illustration/doctors-together-2x.png";
import DoctorsTogetherImg3x from "../../../assets/images/illustration/doctors-together-3x.png";

import {
    BirthdayUserStep,
    MainUserInfoStep,
    AddressUserStep,
    PhoneUserStep,
} from "./forms";

import { DashboardPageLoader } from "../../../components/dashboard-page-loader";

import styles from "./history-page.module.scss";
import { updateMainUserProfile } from "../../../services/user.service";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/routes.json";
import { useUserRootStore } from "../../../stores/user/userRootStore";
import { UserData } from "../../../services/types";
import { SmallBubbleArrow } from "../../../components/icons/arrows";
type StepItem = {
    bubblePosition: string;
    bubbleText: string;
    form: FunctionComponent<FormProps>;
};

const STEPS: StepItem[] = [
    {
        bubblePosition: "top",
        bubbleText: "We want to meet with you!",
        form: MainUserInfoStep,
    },
    {
        bubblePosition: "bottom",
        bubbleText: "We need know your age.",
        form: BirthdayUserStep,
    },
    {
        bubblePosition: "top",
        bubbleText:
            "We need your zip code to determine if we are operating in this area.",
        form: AddressUserStep,
    },
    {
        bubblePosition: "bottom",
        bubbleText: "We need your Phone number.",
        form: PhoneUserStep,
    },
];

export const WelcomePage: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [init, userProfile] = useUserRootStore((s) => [
        s.init,
        s.computed.profileData,
    ]);

    const nextStepHandler = () => {
        if (step < STEPS.length - 1) {
            setStep((prev) => ++prev);
        }
    };

    const finalStepHandler = (user: UserData) => {
        init(user);
        setTimeout(() => {
            navigate(routes.dashServicesPage);
        }, 500);
    };

    const renderElement = (currentIndex: number) => {
        const FormElement = STEPS[currentIndex]?.form;
        if (FormElement !== undefined) {
            return (
                <FormElement
                    loadingHandler={setLoading}
                    nextStep={nextStepHandler}
                    finalStep={finalStepHandler}
                    user={userProfile}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className={styles["wrap"]}>
                    <div className={styles["welcome-container"]}>
                        <div className={styles["welcome__illustration"]}>
                            <img
                                src={DoctorsTogetherImg1x}
                                srcSet={`${DoctorsTogetherImg2x} 2x, ${DoctorsTogetherImg3x} 3x`}
                                alt=""
                                className={styles["illustration__doctors-img"]}
                            />
                            <span
                                className={clsx({
                                    [styles["illustration__doctors-bubble"]]:
                                        true,
                                    [styles[
                                        `illustration__doctors-bubble--${STEPS[step].bubblePosition}`
                                    ]]: true,
                                })}
                            >
                                {STEPS[step]?.bubbleText}
                                <SmallBubbleArrow
                                    className={styles["doctors-bubble__arrow"]}
                                />
                            </span>
                        </div>
                        <div className={styles["welcome__content"]}>
                            {renderElement(step)}
                        </div>
                    </div>
                    {loading && <DashboardPageLoader />}
                </div>
            </div>
        </div>
    );
};
