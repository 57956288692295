import acidRefluxImage from "../../assets/images/diseases/acid-reflux.png";
import acneImage from "../../assets/images/diseases/acne.png";
import allergiesImage from "../../assets/images/diseases/allergies.png";
import birthControlImage from "../../assets/images/diseases/birth-control.png";
import covidImage from "../../assets/images/diseases/covid-19-oral-therapeutics.png";
import erectileDysfunctionImage from "../../assets/images/diseases/erectile-dysfunction.png";
import eyelashesGrowingImage from "../../assets/images/diseases/eyelashes-growing.png";
import maleHairlossImage from "../../assets/images/diseases/male-hair-loss.png";
import prematureEjaculationImage from "../../assets/images/diseases/premature-ejaculation.png";
import shortTermRefillImage from "../../assets/images/diseases/short-term-medication-refill.png";
import skinRashesImage from "../../assets/images/diseases/skin-rashes.png";
import smokingCessationsImage from "../../assets/images/diseases/smoking-cessations.png";
import stiImage from "../../assets/images/diseases/sti.png";
import utiImage from "../../assets/images/diseases/uti.png";
import weightlossImage from "../../assets/images/diseases/weight-loss.png";

interface Props {
    name: string;
}
export const DiseasesImage = ({ name }: Props) => {
    let srcImage = "";

    switch (name) {
        case "acne":
            srcImage = acneImage;
            break;
        case "acid-reflux":
            srcImage = acidRefluxImage;
            break;
        case "allergies":
            srcImage = allergiesImage;
            break;
        case "birth-control":
            srcImage = birthControlImage;
            break;
        case "covid-19-oral-therapeutics":
            srcImage = covidImage;
            break;
        case "erectile-dysfunction":
            srcImage = erectileDysfunctionImage;
            break;
        case "eyelashes-growing":
            srcImage = eyelashesGrowingImage;
            break;
        case "male-hair-loss":
            srcImage = maleHairlossImage;
            break;
        case "premature-ejaculation":
            srcImage = prematureEjaculationImage;
            break;
        case "short-term-medication-refill":
            srcImage = shortTermRefillImage;
            break;
        case "skin-rashes":
            srcImage = skinRashesImage;
            break;
        case "smoking-cessations":
            srcImage = smokingCessationsImage;
            break;
        case "sti":
            srcImage = stiImage;
            break;
        case "uti":
            srcImage = utiImage;
            break;
        case "weight-loss":
            srcImage = weightlossImage;
            break;
        default:
            break;
    }

    return <img src={srcImage} alt="" />;
};
