import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { get, isEmpty } from "lodash";
import * as yup from "yup";

import {
    FieldValues,
    SubmitHandler,
    useFieldArray,
    useForm,
} from "react-hook-form";

import {
    AddressField,
    FieldGroup,
    InputField,
} from "../../../../components/fields";
import { useUserRootStore } from "../../../../stores/user/userRootStore";
import { PlusIcon, TrashIcon } from "../../../../components/icons";
import { LoadingButton } from "../../../../components/buttons";

import {
    deleteUserAddress,
    saveUserAddress,
} from "../../../../services/user.service";
import { UserAddressType } from "../../../../services/types";

import styles from "./forms.module.scss";

const USER_PROFILE_SCHEME = yup
    .object({
        delivery: yup
            .array()
            .of(
                yup.object({
                    address: yup.string().required("required"),
                    additional: yup.string().required("required"),
                    zipcode: yup.string().required("required"),
                })
            )
            .required(),
    })
    .required();

export const UserAddressData = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const deliveryAddresses = useUserRootStore(
        (s) => s.computed.userDeliveryAddresses
    );
    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<FieldValues>({
        values: { delivery: deliveryAddresses },
        resolver: yupResolver(USER_PROFILE_SCHEME),
    });

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "delivery", // unique name for your Field Array
    });

    const appendFieldHandler = () => {
        append({
            type: "delivery",
            address: "",
            additional: "",
            zipcode: "",
        });
    };

    const removeFieldHandler = async (field: FieldValues, index: number) => {
        if (field.key === undefined) remove(index); // in case if user don't save this address

        if (field && field.type !== UserAddressType.main) {
            const { error } = await deleteUserAddress(field.key);
            if (error === undefined) {
                remove(index);
            }
        } else {
            // eslint-disable-next-line no-console
            console.log("we can not remove main address field");
        }
    };

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        if (!isEmpty(errors)) return false;
        setLoading(true);
        const { error } = await saveUserAddress(data.delivery);
        console.log(error);

        setLoading(false);
    };

    return (
        <div className={styles["wrap"]}>
            <span
                className={`${styles["head__title"]} ${styles["head__title--address"]}`}
            >
                Delivery Address
            </span>
            <form
                className={styles["user-address__wrap"]}
                onSubmit={handleSubmit(onSubmit)}
            >
                {fields.map((field, index) => (
                    <div className={styles["address-row"]} key={field.id}>
                        <div className={styles["address-row__head"]}>
                            <span className={styles["address-row__head-title"]}>
                                Address {index + 1}
                            </span>
                            <span
                                className={styles["address-row__head-delete"]}
                                onClick={() => removeFieldHandler(field, index)}
                            >
                                <TrashIcon
                                    className={styles["address-row__head-icon"]}
                                />
                                Delete
                            </span>
                        </div>
                        <div className={styles["address-row__body"]}>
                            <FieldGroup
                                name={`delivery.${index}.location`}
                                label="City, State"
                                containerClassName={
                                    styles["address-input__address"]
                                }
                                error={get(
                                    errors,
                                    `delivery.${index}.location`
                                )}
                            >
                                <AddressField
                                    control={control}
                                    placeholder="Miami, FL"
                                    filteredResultsBy={{
                                        type: "string",
                                        value: ", USA",
                                    }}
                                    typesOfAddress={["(cities)"]}
                                />
                            </FieldGroup>
                            <FieldGroup
                                name={`delivery.${index}.address`}
                                label="Address"
                                containerClassName={
                                    styles["address-input__address"]
                                }
                                error={get(errors, `delivery.${index}.address`)}
                            >
                                <AddressField
                                    control={control}
                                    getValues={getValues}
                                    filteredResultsBy={{
                                        type: "field",
                                        value: `delivery.${index}.location`,
                                        additional: ", USA",
                                    }}
                                    typesOfAddress={["address"]}
                                    placeholder="1101 Brickell ave, South Tower - 8Th Floor"
                                />
                            </FieldGroup>
                            <FieldGroup
                                name={`delivery.${index}.additional`}
                                label="Apt, Suite"
                                containerClassName={
                                    styles["address-input__additional"]
                                }
                                error={get(
                                    errors,
                                    `delivery.${index}.additional`
                                )}
                            >
                                <InputField
                                    register={register}
                                    placeholder="8 floor, 132"
                                />
                            </FieldGroup>
                            <FieldGroup
                                name={`delivery.${index}.zipcode`}
                                label="Zip Code"
                                containerClassName={
                                    styles["address-input__zipcode"]
                                }
                                error={get(errors, `delivery.${index}.zipcode`)}
                            >
                                <InputField
                                    register={register}
                                    placeholder="33131"
                                />
                            </FieldGroup>
                        </div>
                    </div>
                ))}
                <div className={styles["user-address__new"]}>
                    <span
                        className={styles["user-address__new-wrap"]}
                        onClick={appendFieldHandler}
                    >
                        <PlusIcon
                            className={styles["user-address__new-icon"]}
                        />
                        Add new address
                    </span>
                </div>
                <div className={styles["user-address__actions"]}>
                    <LoadingButton
                        isLoading={loading}
                        isSubmitBtn={true}
                        title="Update"
                        color="blue"
                    />
                </div>
            </form>
        </div>
    );
};
