import { FunctionComponent, ReactElement, useState } from "react";

import { QuizTypes } from "../../../services/types";

import { QuizWrapper } from "../wrapper";
import { WelcomeContentSelectionStep } from "./content-selection-step";
import { WelcomeContentConfirmStep } from "./content-confirm-step";

import styles from "./quiz-welcome-page.module.scss";

export const WelcomeQuizPage: FunctionComponent = (): ReactElement => {
    const [step, setStep] = useState<"selection" | "confirm">("selection");
    const [typeOfQuiz, setTypeOfQuiz] = useState<QuizTypes>();

    return (
        <QuizWrapper>
            <div className={styles["container"]}>
                <div className={styles["title"]}>
                    <h2>
                        {step === "selection"
                            ? "Let's start!"
                            : "Confirm information"}
                    </h2>
                </div>
                <div className={styles["wrap"]}>
                    {step == "selection" ? (
                        <WelcomeContentSelectionStep
                            nextStep={() => setStep("confirm")}
                            currentTypeOfQuiz={typeOfQuiz || QuizTypes.initial}
                            setTypeOfQuiz={setTypeOfQuiz}
                        />
                    ) : (
                        <WelcomeContentConfirmStep
                            currentTypeOfQuiz={typeOfQuiz || QuizTypes.initial}
                        />
                    )}
                </div>
            </div>
        </QuizWrapper>
    );
};
