import { QuizSavedObject } from "../stores/quiz/types";
import {
    getDiseasesRequest,
    getDiseaseRestrictionsRequest,
    getDiseaseQuizzesRequest,
    getOrCreateUserQuizRequest,
    saveProgressOfQuizForUserRequest,
    getSaveQuizFromUserRequest,
    confirmUserDataQuizRequest,
} from "./api/disease.api";
import {
    ServiceGetDiseasesResponse,
    ServiceGetQuizResponse,
    ServiceGetSavedQuizFromUserResponse,
    ServiceGetDiseasesQuizzesResponse,
    ServiceGetOrCreateUserQuizResponse,
    ServicesResponse,
} from "./types";

export const getDiseases = async (): Promise<ServiceGetDiseasesResponse> => {
    try {
        const response = await getDiseasesRequest();
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const checkDiseaseRestrictions = async (
    slug: string
): Promise<ServiceGetDiseasesResponse> => {
    try {
        const response = await getDiseaseRestrictionsRequest(slug);
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const getDiseaseQuizzes = async (
    slug: string
): Promise<ServiceGetDiseasesQuizzesResponse> => {
    try {
        const response = await getDiseaseQuizzesRequest(slug);
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const getOrCreateUserQuiz = async (
    disease: string,
    quizType: string,
    savedQuizId: string | undefined
): Promise<ServiceGetOrCreateUserQuizResponse> => {
    try {
        const response = await getOrCreateUserQuizRequest(
            disease,
            quizType,
            savedQuizId
        );
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const confirmationUserDataForNewQuiz = async (
    quizId: number,
    locationId: number
): Promise<ServicesResponse> => {
    try {
        const response = await confirmUserDataQuizRequest(quizId, {
            locationId,
        });
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const saveProgressOfQuizForUser = async (
    answersId: number,
    status: string,
    savedData: QuizSavedObject[]
): Promise<ServiceGetQuizResponse> => {
    try {
        const response = await saveProgressOfQuizForUserRequest(answersId, {
            status,
            data: savedData,
        });
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};

export const getSavedUserQuiz = async (
    quizId: number
): Promise<ServiceGetSavedQuizFromUserResponse> => {
    try {
        const response = await getSaveQuizFromUserRequest(quizId);
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: null, error };
    }
};
