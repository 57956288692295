import { useParams } from "react-router-dom";
import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { getUserQuizById } from "../../../services/user.service";
import { UserQuizItem } from "../../../services/types";

import { DetailsTreatment } from "./details-treatment";
import { DetailsPrescription } from "./details-prescription";
import { ActionBtn, DetailsStatus, GoBackBtn } from "./ui";
import { ErrorNotFound } from "../../../components/errors/error-not-found/ErrorNotFound";
import { DashboardPageLoader } from "../../../components/dashboard-page-loader";

import styles from "./history-details-page.module.scss";
import { DetailsPayment } from "./details-payment";

export const HistoryDetailsPage: FunctionComponent = (): ReactElement => {
    const { quizId } = useParams();
    const [currentQuiz, setCurrentQuiz] = useState<UserQuizItem | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        let didCancel = false;
        async function request(id: string) {
            setLoading(true);
            const { data, error } = await getUserQuizById(id);

            if (!didCancel) {
                if (error === undefined || data !== null) {
                    setCurrentQuiz(data);
                } else {
                    setError(true);
                }
                setLoading(false);
            }
        }

        if (quizId !== undefined) {
            request(quizId);
        }

        return () => {
            didCancel = true;
        };
    }, [quizId]);

    return (
        <div className="container">
            <div className="row">
                <div className={styles["wrap"]}>
                    {error && <ErrorNotFound />}
                    {currentQuiz && (
                        <>
                            <GoBackBtn />
                            <div className={styles["quiz-details__container"]}>
                                <h2>Review</h2>
                                <div className={styles["quiz-details__head"]}>
                                    <DetailsStatus quiz={currentQuiz} />
                                    <ActionBtn
                                        containerClassName={
                                            styles["details-head__actions"]
                                        }
                                        dataClassName={styles["detail__data"]}
                                        quiz={currentQuiz}
                                    />
                                </div>
                                <div className={styles["quiz-details__body"]}>
                                    <DetailsTreatment quiz={currentQuiz} />
                                    <DetailsPrescription quiz={currentQuiz} />
                                    <DetailsPayment quiz={currentQuiz} />
                                </div>
                            </div>
                        </>
                    )}

                    {loading && <DashboardPageLoader />}
                </div>
            </div>
        </div>
    );
};
