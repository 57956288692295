import { QuizStatuses, UserQuizItem } from "../../../../../services/types";

import styles from "./history-details__app-doctor.module.scss";

interface Props {
    quiz: UserQuizItem;
}
export const DetailsStatus = ({ quiz }: Props) => {
    return (
        <div className={styles["details-status"]}>
            <span className={styles["details-status__name"]}>{quiz.name}</span>
            <span className={styles["details-status__status-wrap"]}>
                {quiz.status === QuizStatuses.processing && (
                    <p>
                        Your current status is &quot;Processing&quot; you should
                        finished quiz and we can help to you.
                    </p>
                )}
                {quiz.status === QuizStatuses.payment && (
                    <p>
                        For received help you should make a payment, please
                        finished it.
                    </p>
                )}
                {quiz.status === QuizStatuses.finished && (
                    <p>
                        Doctor will check your quiz and send prescription to
                        you.
                    </p>
                )}
            </span>
        </div>
    );
};
