import { clsx } from "clsx";
import InputMask from "react-input-mask";

import { InputWithMaskProps } from "../types";

import styles from "./input.module.scss";

export const InputWithMask = ({
    type = "text",
    mask,
    value,
    placeholder = "",
    readOnly = false,
    className = "",
    required,
    disabled,
    name,
    id,
    register,
    onChange,
    onFocus,
    onBlur,
}: InputWithMaskProps) => {
    return (
        <InputMask
            mask={mask}
            maskPlaceholder={placeholder}
            alwaysShowMask={true}
            className={clsx({
                [styles["input"]]: true,
                [className]: className !== "",
            })}
            type={type}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            id={id}
            onFocus={() => onFocus && onFocus()}
            onBlur={() => onBlur && onBlur()}
            onChange={(e) => onChange && onChange(e)}
            {...(register && name && register(name))}
        />
    );
};
