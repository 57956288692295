import { isMobileWidth } from "../../services/helpers";
import { Logo } from "../logo";
import { NavBar } from "../nav-bar";

import styles from "./header.module.scss";

export const Header = () => {
    return (
        <div className={styles["main"]}>
            <div className="container">
                <div className="row">
                    <div className={styles["wrap"]}>
                        <div className={styles["logo"]}>
                            <Logo />
                        </div>
                        <div className={styles["nav-bar"]}>
                            {!isMobileWidth() ? <NavBar /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
