import { useNavigate } from "react-router-dom";
import styles from "./quiz_back-btn.module.scss";

export const GoBackBtn = () => {
    const navigate = useNavigate();

    return (
        <div className={styles["wrap"]}>
            <button
                className={`button ghost-white ${styles["with-back-icon"]}`}
                onClick={() => navigate(-1)}
            >
                Back to history
            </button>
        </div>
    );
};
