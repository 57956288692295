import { Link } from "react-router-dom";
import CONTENT from "../../../../../data/base-quiz-stated-content.json";

import routes from "../../../../../data/routes.json";
import styles from "../welcome-content-selection-step.module.scss";

type ContentHowItWorkQuizType = {
    title: string;
    content: string;
};

export const ContentBenefits = () => {
    const items = CONTENT as ContentHowItWorkQuizType[];

    return (
        <div className={styles["content__list"]}>
            <ul className={styles["list"]}>
                {items.map((item, i) => (
                    <li key={i} className={styles["list__item"]}>
                        <div className={styles["list__item--count"]}>
                            <span>{++i}</span>
                        </div>
                        <div className={styles["list__item--content"]}>
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                        </div>
                    </li>
                ))}
            </ul>
            <div className={styles["terms"]}>
                <p className="agreed-text">
                    By clicking “Start” below, I agree to the{" "}
                    <Link
                        target="__blank"
                        className="link"
                        to={routes.docsInformedConsentPage}
                    >
                        CRX Informed Consent
                    </Link>{" "}
                    and acknowledge the{" "}
                    <Link
                        target="__blank"
                        className="link"
                        to={routes.docsPrivacyPolicyPage}
                    >
                        Notice of Privacy Practices
                    </Link>
                </p>
            </div>
        </div>
    );
};
