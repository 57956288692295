import { DiseaseGroup } from "../../../../../stores/disease/types";
import { DiseasesItem } from "./DiseasesItem";

import styles from "./disease.module.scss";
export interface Props extends DiseaseGroup {
    isLocked: boolean;
}

export const DiseasesGroup = ({ name, items, isLocked }: Props) => {
    return (
        <div className={styles["group_wrap"]}>
            <div className={styles["group_head"]}>
                <span className={styles["group_title"]}>{name}</span>
            </div>
            <div className={styles["group_list"]}>
                {items.map((item, i) => (
                    <DiseasesItem key={i} {...item} isLocked={isLocked} />
                ))}
            </div>
        </div>
    );
};
