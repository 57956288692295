import clsx from "clsx";
import { NavLink } from "react-router-dom";

import { RightCorner, LeftCorner, LockIcon } from "../../icons";
import {
    MainNavigationProps,
    MainNavItemProps,
    NavItemsWithCorners,
} from "../types";
import MainNavData from "../../../data/main.navigation";
import { useUserRootStore } from "../../../stores/user/userRootStore";
import routes from "../../../data/routes.json";
const NavItem = ({
    item,
    withCorners,
    className = "",
    withLockIcon = false,
    lockIconClassName = "",
    activeClassName = "",
    titleClassName = "",
    cornerClassName = "",
    disabledClassName = "",
}: MainNavItemProps) => {
    return (
        <NavLink
            to={item.slug}
            className={({ isActive }) =>
                clsx({
                    [className]: className !== "",
                    [activeClassName]: isActive,
                    "nav-link--active": isActive,
                    [disabledClassName]: withLockIcon,
                })
            }
        >
            {withCorners && item.leftCorner && (
                <div className={`corner left-corner ${cornerClassName}`}>
                    <LeftCorner />
                </div>
            )}
            {withLockIcon && <LockIcon className={lockIconClassName} />}
            <span
                className={clsx({
                    [titleClassName]: titleClassName !== "",
                })}
            >
                {item.title}
            </span>
            {withCorners && item.rightCorner && (
                <div className={`corner right-corner ${cornerClassName}`}>
                    <RightCorner />
                </div>
            )}
        </NavLink>
    );
};

export const MainNavigation = ({
    navClassName = "",
    navLinkClassName = "",
    navLinkActiveClassName = "",
    navLinkCornerClassName = "",
    linkTitleClassName = "",
    lockIconClassName = "",
    disabledClassName = "",
    withCorners,
}: MainNavigationProps) => {
    const isFilledProfile = useUserRootStore((s) => s.computed.filledProfile);
    const items = MainNavData as NavItemsWithCorners[];

    const isLocked = (slug: string, shouldLocked: boolean): boolean => {
        return slug !== routes.dashProfilePage && shouldLocked;
    };

    return (
        <nav
            className={clsx({
                [navClassName]: navClassName !== "",
            })}
        >
            {items.map((navItem, i) => (
                <NavItem
                    key={i}
                    item={navItem}
                    withLockIcon={isLocked(navItem.slug, !isFilledProfile)}
                    lockIconClassName={lockIconClassName}
                    withCorners={withCorners}
                    className={navLinkClassName}
                    activeClassName={navLinkActiveClassName}
                    titleClassName={linkTitleClassName}
                    cornerClassName={navLinkCornerClassName}
                    disabledClassName={disabledClassName}
                />
            ))}
        </nav>
    );
};
