import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { useQuizRootStore } from "../../../../stores/quiz/quizRootStore";

import routes from "../../../../data/routes.json";
import styles from "./quiz_progress-bar.module.scss";

export const QuizProgressBar = () => {
    const { pathname } = useLocation();
    const [statusOfQuiz, setStatusOfQuiz] = useState("Start");
    const [progress, setProgress] = useState<number>(0);

    const [title, totalStep, currentStep] = useQuizRootStore((s) => [
        s.title,
        s.computed.totalPages,
        s.currentPage,
    ]);

    useEffect(() => {
        const current = ((currentStep + 1) * 100) / totalStep;
        setProgress(current);
    }, [currentStep, totalStep]);

    useEffect(() => {
        if (matchPath(routes.dashQuizCongratsPage, pathname)) {
            setStatusOfQuiz("Congrats");
            setProgress(100);
        } else if (matchPath(routes.dashQuizPaymentPage, pathname)) {
            setStatusOfQuiz("Payment");
            setProgress(100);
        } else if (matchPath(routes.quizWelcomePage, pathname)) {
            setStatusOfQuiz("Start");
        } else {
            if (totalStep !== 0) {
                setStatusOfQuiz(`${currentStep + 1} / ${totalStep}`);
            }
        }
    }, [pathname, currentStep, totalStep]);

    return (
        <div className={styles["container"]}>
            <div className={styles["bar"]}>
                <div
                    className={styles["bar--progress"]}
                    style={{ width: `${progress}%` }}
                />
            </div>
            <div className={styles["notice"]}>
                <span className={styles["notice__title"]}>{title}</span>
                <span className={styles["notice__steps"]}>{statusOfQuiz}</span>
            </div>
        </div>
    );
};
