import { FunctionComponent, ReactElement } from "react";
import { SignUpForm } from "../../../components/forms";
import routes from "../../../data/routes.json";

import styles from "../auth-pages.module.scss";
import { useNavigate } from "react-router-dom";

export const SignUpPage: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const successHandler = (email: string | null) => {
        if (email === null) navigate(routes.authLogIn);
        navigate(`${routes.authVerification}?email=${email}`);
    };
    return (
        <div className={styles["wrapper"]}>
            <div className={styles["title-wrap"]}>
                <h2 className={styles["title"]}>Enter your email</h2>
            </div>
            <div className={styles["form-wrap"]}>
                <SignUpForm
                    formClassName={styles["form"]}
                    actionBtnWrapClassName={styles["form-action"]}
                    onSuccess={successHandler}
                />
            </div>
        </div>
    );
};
