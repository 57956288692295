import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormCreatePasswordProps, FormProps } from "../types";
import { FieldGroup, InputField } from "../../fields";
import { LoadingButton } from "../../buttons";
import { useState } from "react";
import { parseValidationErrors } from "../../../services/helpers";
import { ApiValidationError } from "../../../services/errors";
import { resetPassword } from "../../../services/auth.service";

const schema = yup
    .object({
        password: yup.string().min(8).required(),
        password_confirmation: yup
            .string()
            .oneOf(
                [yup.ref("password"), ""],
                "Check that you passwords matched"
            ),
    })
    .required();

export const CreatePasswordForm = ({
    email,
    token,
    formClassName,
    actionBtnWrapClassName,
    onSuccess,
}: FormCreatePasswordProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(schema),
    });
    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        if (email === null || token === undefined) return;

        const { data: resetPwdResult, error } = await resetPassword(
            email,
            token,
            data.password,
            data.password_confirmation
        );

        if (error) {
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        }
        if (resetPwdResult) {
            onSuccess(resetPwdResult);
        }
        setLoading(false);
    };

    return (
        <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup
                label="Password"
                name="password"
                error={errors.password}
            >
                <InputField type="password" register={register} />
            </FieldGroup>
            <FieldGroup
                label="Password Confirm"
                name="password_confirmation"
                error={errors.password_confirmation}
            >
                <InputField type="password" register={register} />
            </FieldGroup>
            <div className={actionBtnWrapClassName}>
                <LoadingButton
                    isLoading={loading}
                    title="Continue"
                    color="purple"
                    isSubmitBtn={true}
                />
            </div>
        </form>
    );
};
