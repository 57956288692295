import clsx from "clsx";

import logoCvs from "../../assets/images/pharmacies/cvs.png";
import logoRiteAid from "../../assets/images/pharmacies/rite-aid.png";
import logoTarget from "../../assets/images/pharmacies/target.png";
import logoWalgreens from "../../assets/images/pharmacies/walgreens.png";
import logoWalmart from "../../assets/images/pharmacies/walmart.png";
import logoPublix from "../../assets/images/pharmacies/publix.png";
import logoKroger from "../../assets/images/pharmacies/kroger.png";
import logoAlbertsonsMarket from "../../assets/images/pharmacies/albertsons_market.png";
import logoSafeway from "../../assets/images/pharmacies/safeway.png";
import logoVons from "../../assets/images/pharmacies/vons.png";
import logoSmith from "../../assets/images/pharmacies/smiths.svg";

import styles from "./pharmacy-logo.module.scss";
import { has } from "lodash";

function PharmacyLogo({
    name,
    className = "",
}: {
    name: string;
    className?: string;
}) {
    const getLogoUrl = (pharmacyName: string) => {
        let url;

        switch (pharmacyName) {
            case "cvs":
                url = logoCvs;
                break;
            case "rite_aid":
                url = logoRiteAid;
                break;
            case "target":
                url = logoTarget;
                break;
            case "walgreens":
                url = logoWalgreens;
                break;
            case "walmart":
                url = logoWalmart;
                break;
            case "publix":
                url = logoPublix;
                break;
            case "kroger":
                url = logoKroger;
                break;
            case "albertsons_market":
                url = logoAlbertsonsMarket;
                break;
            case "safeway":
                url = logoSafeway;
                break;
            case "vons":
                url = logoVons;
                break;
            case "smiths":
                url = logoSmith;
                break;
        }
        return url;
    };

    return (
        <div
            className={clsx({
                [styles["wrap"]]: true,
                [styles["not-found"]]: name === undefined,
                [`${name}-logo`]: true,
                [className]: className !== "",
            })}
        >
            <img
                className={has(styles, name) ? styles[name] : ""}
                alt={`CharityRx, LLC with ${name}`}
                src={getLogoUrl(name)}
                draggable="false"
            />
        </div>
    );
}

export default PharmacyLogo;
