import { useEffect, useRef, useState } from "react";
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
} from "pure-react-carousel";

import { SmallArrowIcon } from "../../../../../components/icons";
import { DiseaseGroup } from "../../../../../stores/disease/types";
import { DiseasesMobileItem } from "./DiseasesMobileItem";

import styles from "./disease-mobile.module.scss";

export const DiseasesMobileGroup = ({ name, items }: DiseaseGroup) => {
    const refCarousel = useRef<HTMLDivElement>(null);
    const refSlideItem = useRef<HTMLDivElement>(null);
    const [countSlides, setCountSlides] = useState(2);
    const [sliderWidth, setSliderWidth] = useState<number>(250);
    const [sliderHeight, setSliderHeight] = useState<number>(600);

    const handlerResize = () => {
        if (refSlideItem?.current) {
            const slideBoundary =
                refSlideItem?.current?.getBoundingClientRect();
            setSliderWidth(slideBoundary.width / 2);
            setSliderHeight(slideBoundary.height / 2);

            if (window.innerWidth <= 375) {
                setCountSlides(1);
            } else if (window.innerWidth > 375 && window.innerWidth <= 575) {
                setCountSlides(2);
            } else {
                setCountSlides(3);
            }
        }
    };

    useEffect(() => {
        setTimeout(() => handlerResize(), 100);
        window.addEventListener("resize", handlerResize);
        return () => window.removeEventListener("reset", handlerResize);
    }, []);

    return (
        <div ref={refCarousel} className={styles["group_wrap"]}>
            <div className={styles["group_head"]}>
                <span className={styles["group_title"]}>{name}</span>
            </div>
            <CarouselProvider
                naturalSlideWidth={sliderWidth}
                naturalSlideHeight={sliderHeight}
                visibleSlides={countSlides}
                step={1}
                totalSlides={items.length}
            >
                <Slider
                    className={styles["group_list-slider"]}
                    classNameTray={styles["slide"]}
                >
                    {items.map((item, i) => (
                        <Slide key={i} index={i} className={styles["slide"]}>
                            <DiseasesMobileItem ref={refSlideItem} {...item} />
                        </Slide>
                    ))}
                </Slider>
                <div className={styles["group__slide-nav"]}>
                    <ButtonBack
                        className={`button light-blue ${styles["slide__nav-btn"]}`}
                    >
                        <SmallArrowIcon
                            className={`${styles["slide__nav-btn-icon"]} ${styles["slide__nav-btn-icon--prev"]}`}
                        />
                    </ButtonBack>
                    <ButtonNext
                        className={`button light-blue ${styles["slide__nav-btn"]}`}
                    >
                        <SmallArrowIcon
                            className={styles["slide__nav-btn-icon"]}
                        />
                    </ButtonNext>
                </div>
            </CarouselProvider>
        </div>
    );
};
