import clsx from "clsx";

import { ArrowPrevIcon, ArrowNextIcon } from "../../../../components/icons";
import { useQuizRootStore } from "../../../../stores/quiz/quizRootStore";

import styles from "./quiz-nav.module.scss";

export const QuizNavigation = () => {
    const [isDisabledNavBtn, hasErrors] = useQuizRootStore((state) => [
        state.computed.isDisabledNavBtn,
        state.computed.hasValidationErrors,
    ]);
    const { nextPage, prevPage } = useQuizRootStore();

    return (
        <div className={styles["container"]}>
            <button
                onClick={prevPage}
                className={clsx({
                    "button transparent": true,
                    [styles["btn-prev"]]: true,
                })}
            >
                <ArrowPrevIcon className={styles["btn-prev__arrow"]} />
            </button>
            <button
                onClick={nextPage}
                disabled={isDisabledNavBtn}
                className={clsx({
                    button: true,
                    purple: !hasErrors,
                    error: hasErrors,
                    [styles["btn-next"]]: true,
                })}
            >
                Next
                <ArrowNextIcon className={styles["btn-next__arrow"]} />
            </button>
        </div>
    );
};
