import { generatePath, useNavigate, useParams } from "react-router-dom";

import { QuizCheckoutSummary } from "./ui";
import { QuizWrapper } from "../wrapper";
import { CreditCardPaymentForm } from "../../../components/forms/credit-card-payment";

import routes from "../../../data/routes.json";
import styles from "./quiz-checkout-page.module.scss";
import { useQuizRootStore } from "../../../stores/quiz/quizRootStore";

export const CheckoutQuizPage = () => {
    const navigate = useNavigate();
    const { diseaseSlug, quizType, quizId } = useParams();
    const { setLoading } = useQuizRootStore();

    const successHandler = async (msg: string | null) => {
        setTimeout(() => {
            navigate(
                generatePath(routes.dashQuizCongratsPage, {
                    diseaseSlug,
                    quizType,
                    quizId,
                })
            );
        }, 1500);
    };

    return (
        <QuizWrapper>
            <div className={styles["container"]}>
                <div className={styles["title"]}>
                    <h2>
                        Great News!
                        <small>
                            Our Doctor will review your case within 24 Hours
                        </small>
                    </h2>
                </div>

                <div className={styles["wrap"]}>
                    <div className={styles["payment"]}>
                        <div className={styles["payment_wrap"]}>
                            <CreditCardPaymentForm
                                formId="quiz-checkout-payment-form"
                                onLoading={(s) => setLoading(s)}
                                onSuccess={successHandler}
                            />
                        </div>
                    </div>
                    <div className={styles["receipt"]}>
                        <div className={styles["receipt-block"]}>
                            <QuizCheckoutSummary formId="quiz-checkout-payment-form" />
                        </div>
                    </div>
                </div>
            </div>
        </QuizWrapper>
    );
};
