import clsx from "clsx";
import { Spinner } from "../spinner";

import styles from "./loading-btn.module.scss";
import { ReactNode } from "react";

interface Props {
    isSubmitBtn: boolean;
    color: "purple" | "blue" | "light-blue";
    title: string | ReactNode;
    isLoading: boolean;
    className?: string;
    onClick?: () => void;
}

export const LoadingButton = ({
    isSubmitBtn,
    color,
    title,
    isLoading,
    className = "",
    onClick,
}: Props) => {
    return (
        <button
            onClick={onClick}
            {...(isSubmitBtn && { type: "submit" })}
            className={clsx({
                button: true,
                [color]: color,
                [styles["is-loading"]]: isLoading,
                [className]: className !== "",
            })}
        >
            {title}
            {isLoading && <Spinner className={styles["btn-spinner-wrap"]} />}
        </button>
    );
};
