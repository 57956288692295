import { IconProps } from "./types";

export const Prev = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 8"
        fill="none"
        className={className}
    >
        <path
            d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM21 3.5H1V4.5H21V3.5Z"
            fill="currentColor"
        />
    </svg>
);

export const Next = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 8"
        fill="none"
        className={className}
    >
        <path
            d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.97631 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM-4.37114e-08 4.5L20 4.5L20 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
            fill="currentColor"
        />
    </svg>
);

export const SmallArrow = ({ className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 14"
        fill="none"
    >
        <path
            d="M1.05768 13.581C0.522479 13.0578 0.480479 12.3294 1.05768 11.6898L5.55408 7.00023L1.05768 2.31063C0.480479 1.67103 0.522479 0.941429 1.05768 0.421828C1.59168 -0.101372 2.49408 -0.0677719 2.99568 0.421828C3.49728 0.909029 8.39808 6.05583 8.39808 6.05583C8.66568 6.31623 8.80008 6.65823 8.80008 7.00023C8.80008 7.34223 8.66568 7.68423 8.39808 7.94703C8.39808 7.94703 3.49728 13.0914 2.99568 13.581C2.49408 14.0706 1.59168 14.1042 1.05768 13.581Z"
            fill="currentColor"
        />
    </svg>
);

export const SmallBubbleArrow = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 36"
        className={className}
        fill="none"
    >
        <path
            d="M0.649879 35.2908C0.649879 35.2908 20.4507 23.8923 22.1692 8.58551C23.8876 -6.72129 51.0559 1.65327 41.4791 14.3629C31.9022 27.0725 0.649879 35.2908 0.649879 35.2908Z"
            fill="currentColor"
        />
    </svg>
);
