import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { WelcomeModalProps, WelcomeModalSlideProps } from "./types";

import WelcomeImage1x from "../../../assets/images/illustration/welcome-crx-modal-1x.png";
import WelcomeImage2x from "../../../assets/images/illustration/welcome-crx-modal-2x.png";
import WelcomeImage3x from "../../../assets/images/illustration/welcome-crx-modal-3x.png";

import { Modal } from "../Modal";
import { ArrowNextIcon, ArrowPrevIcon } from "../../icons";
import { isMobileWidth } from "../../../services/helpers";

import "pure-react-carousel/dist/react-carousel.es.css";
import styles from "./welcome-modal.module.scss";

import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";

const SLIDES = [
    {
        title: "Welcome to CRX Health!",
        description:
            "We are excited to provide you with convenient access to affordable and high-quality medical care through our platform.",
        image: {
            src: WelcomeImage1x,
            srcSet: `${WelcomeImage2x} 2x, ${WelcomeImage3x} 3x`,
        },
    },
    {
        title: "Welcome to the Charity RX Telemedicine!",
        description:
            "To begin your journey, select a service and fill out the secure health questionnaire, which a doctor in your area will review. If deemed medically appropriate, they will develop a comprehensive treatment plan and, if necessary, provide a prescription.",
        image: {
            src: WelcomeImage1x,
            srcSet: `${WelcomeImage2x} 2x, ${WelcomeImage3x} 3x`,
        },
    },
];

export const WelcomeModalSlide = forwardRef<
    HTMLDivElement,
    WelcomeModalSlideProps
>(function WelcomeModalSlide(props, ref) {
    const { item } = props;
    return (
        <div className={styles["slide"]} ref={ref}>
            <h3 className={styles["slide__title"]}>{item.title}</h3>
            <p className={styles["slide__desc"]}>{item.description}</p>
            <div className={styles["slide__image"]}>
                <img
                    src={WelcomeImage1x}
                    srcSet={`${WelcomeImage2x} 2x, ${WelcomeImage3x} 3x`}
                    alt=""
                />
            </div>
        </div>
    );
});

//
const ModalBody = ({ close }: { close?: () => void }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel();
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        // setSelectedIndex(emblaApi.selectedScrollSnap());
        setPrevBtnDisabled(!emblaApi.canScrollPrev());
        setNextBtnDisabled(!emblaApi.canScrollNext());
    }, []);

    const scrollPrev = useCallback(() => {
        if (!prevBtnDisabled) {
            emblaApi && emblaApi.scrollPrev();
        } else {
            close && close();
        }
    }, [prevBtnDisabled, emblaApi]);
    const scrollNext = useCallback(() => {
        if (!nextBtnDisabled) {
            emblaApi && emblaApi.scrollNext();
        } else {
            close && close();
        }
    }, [nextBtnDisabled, emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        onSelect(emblaApi);
        emblaApi.on("reInit", onSelect);
        emblaApi.on("select", onSelect);
    }, [emblaApi, onSelect]);

    return (
        <div className={styles["welcome-slider__wrap"]}>
            <div ref={emblaRef} className={`embla ${styles["welcome-slider"]}`}>
                <div className={styles["welcome-slider__container"]}>
                    {SLIDES.map((slide, i) => (
                        <WelcomeModalSlide key={i} item={slide} />
                    ))}
                </div>
                <div className={styles["slider-nav"]}>
                    <button
                        className={`button light-blue ${styles["slider-nav__btn"]}`}
                        onClick={scrollPrev}
                    >
                        {!prevBtnDisabled ? (
                            <>
                                <ArrowPrevIcon
                                    className={styles["btn-prev__arrow"]}
                                />
                                Prev
                            </>
                        ) : (
                            "Skip"
                        )}
                    </button>
                    <button
                        onClick={scrollNext}
                        className={`button purple ${styles["slider-nav__btn"]}`}
                    >
                        <>
                            {!nextBtnDisabled ? (
                                <>
                                    Next
                                    <ArrowNextIcon
                                        className={styles["btn-next__arrow"]}
                                    />
                                </>
                            ) : (
                                "Close"
                            )}
                        </>
                    </button>
                </div>
            </div>
        </div>
    );
};

export const WelcomeModal = ({ open }: WelcomeModalProps) => {
    return (
        <Modal open={open} isMobileDialog={isMobileWidth()}>
            {(props) => <ModalBody {...props} />}
        </Modal>
    );
};
