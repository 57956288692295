import { useNavigate } from "react-router-dom";
import { FunctionComponent, ReactElement } from "react";
import { LoginInForm } from "../../../components/forms";

import routes from "../../../data/routes.json";
import styles from "../auth-pages.module.scss";

export const LogInPage: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const successHandler = () => {
        navigate(routes.dashServicesPage);
    };
    return (
        <div className={styles["wrapper"]}>
            <div className={styles["title-wrap"]}>
                <h2 className={styles["title"]}>Welcome back!</h2>
            </div>
            <div className={styles["form-wrap"]}>
                <LoginInForm
                    formClassName={styles["form"]}
                    actionBtnWrapClassName={styles["form-action"]}
                    globalErrorClassName={styles["form__global-error"]}
                    globalErrorClassNameShow={
                        styles["form__global-error--show"]
                    }
                    onSuccess={successHandler}
                />
            </div>
        </div>
    );
};
