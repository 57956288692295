import { clsx } from "clsx";
import { ChangeEvent, useEffect, useRef, useState } from "react";

import { useUserRootStore } from "../../../stores/user/userRootStore";
import {
    deleteUserAvatar,
    updateUserAvatar,
} from "../../../services/user.service";

import { ImageIcon, TrashIcon } from "../../icons";
import { Spinner } from "../../spinner";

import styles from "./avatar-field.module.scss";
import { ApiValidationError } from "../../../services/errors";

export const Avatar = () => {
    const refInputFile = useRef<HTMLInputElement | null>(null);
    const { avatar: savedAvatarUrl } = useUserRootStore();
    const [avatar, setAvatar] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (savedAvatarUrl !== null) {
            setAvatar(savedAvatarUrl);
        }
    }, [savedAvatarUrl]);

    const changeFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e?.currentTarget.files;
        if (files && files.length > 0) {
            setLoading(true);
            // always get only first file.
            const { data, error } = await updateUserAvatar(files[0]);
            if (error === undefined && data?.url) {
                setAvatar(data.url);
            } else {
                if (error instanceof ApiValidationError) {
                    if (error.fields) {
                        setError(error.fields.toString());
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        }

        setLoading(false);
    };

    const deleteAvatarHandler = async (
        e: React.MouseEvent<HTMLSpanElement>
    ) => {
        e.stopPropagation();
        if (avatar) {
            setLoading(true);
            const { error } = await deleteUserAvatar();
            if (error === undefined) {
                setAvatar(null);
            } else {
                // eslint-disable-next-line no-console
                console.error("Something went wrong");
            }
            setLoading(false);
        }
    };

    const changeAvatarHandler = async (
        e: React.MouseEvent<HTMLSpanElement>
    ) => {
        e.stopPropagation();
        if (avatar && refInputFile !== null) {
            refInputFile.current?.click();
        }
    };

    return (
        <div className={styles["wrap"]}>
            <label
                htmlFor="user-avatar"
                className={clsx({
                    [styles["file-label"]]: true,
                    [styles["file-label--hide"]]: avatar !== null,
                })}
            >
                <span className={styles["file-label__text"]}>
                    {!loading && avatar === null && (
                        <>
                            <ImageIcon className={styles["label-text__icon"]} />{" "}
                            Add Photo
                        </>
                    )}
                </span>
                <input
                    ref={refInputFile}
                    id="user-avatar"
                    type="file"
                    accept="image/*"
                    className={styles["file-input"]}
                    onChange={changeFileHandler}
                />
            </label>
            <div
                className={clsx({
                    [styles["avatar"]]: true,
                    [styles["avatar--hide"]]: avatar === null,
                })}
            >
                {avatar !== null && (
                    <img
                        src={avatar}
                        alt=""
                        className={styles["avatar__image"]}
                    />
                )}
                {avatar !== null && (
                    <div className={styles["avatar__actions"]}>
                        <span
                            onClick={deleteAvatarHandler}
                            className={styles["file-label__text"]}
                        >
                            <TrashIcon className={styles["label-text__icon"]} />
                            Delete
                        </span>
                        <span
                            onClick={changeAvatarHandler}
                            className={styles["file-label__text"]}
                        >
                            <ImageIcon className={styles["label-text__icon"]} />
                            Change
                        </span>
                    </div>
                )}
            </div>
            {loading && (
                <div className={styles["loading"]}>
                    <Spinner className={styles["label-text__spinner"]} />
                </div>
            )}
            {error && <span className={styles["error"]}>{error}</span>}
        </div>
    );
};
