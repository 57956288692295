import {
    DiseaseQuizItemData,
    QuizPageObject,
    QuizSavedData,
    QuizStatuses,
    QuizTypes,
} from "../../services/types";

export type ContentAction = {
    type: "no-rx" | "go-to";
    value?: string;
};

export type ErrorItem = { id: string; error: string };

export enum QuizErrorTypes {
    norx = "no-rx",
    restriction = "restriction",
    global = "global",
}

export type QuizSavedObject = { id: string; value: string };
export interface SelectContentOptionItem {
    label: string;
    value: string;
    action?: ContentAction;
}

export interface QuizRootStoreType {
    diseaseQuizzes: DiseaseQuizItemData[] | null;
    quizUserId: number;
    title: string;
    data: QuizPageObject[] | [];
    saved: QuizSavedObject[] | [];
    history: number[];

    //control
    loading: boolean;
    currentPage: number;
    status: QuizStatuses;

    validationErrors: ErrorItem[];
    globalError: {
        type: QuizErrorTypes;
        message: string | null;
    } | null;
    // title: string;
    // data: QuizPageObject[] | [];
    // quizUserId: number;
    // saved: QuizSavedObject[];
    // errors: ErrorItem[] | [];
    // shouldFinishImmediately: boolean;
    // currentPage: number;
    // history: number[];
    // isFinished: boolean;
    // status: string;
    // loading: boolean;
    computed: {
        inited: boolean;
        currentDiseaseTypes: QuizTypes[] | null;
        totalPages: number;
        pages: QuizPageObject[] | undefined;
        page: QuizPageObject | undefined;
        isDisabledNavBtn: boolean;
        hasValidationErrors: boolean;
    };
    resetStore: () => void;
    initDiseaseQuizzes: (data: DiseaseQuizItemData[]) => void;
    initCurrentQuiz: (
        title: string,
        quizQuestions: QuizPageObject[],
        userSavedObj: QuizSavedData
    ) => void;
    setValidationError: (error: ErrorItem[] | ErrorItem) => void;
    setGlobalError: (errorType: QuizErrorTypes, message: string) => void;
    setLoading: (s: boolean) => void;
    getStartedQuizId: (type: QuizTypes) => number | null | undefined;
    nextPage: () => void;
    prevPage: () => void;
    saveAnswerQuestion: (id: string, value: string) => void;
    deleteErrorFromStack: (id: string) => void;
    saveQuizInToUser: (
        quizId: number,
        status: QuizStatuses,
        savingData: QuizSavedObject[] | []
    ) => void;
}
