import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useUserRootStore } from "../../stores/user/userRootStore";
import { useUserAuthStore } from "../../stores/user/userAuthStore";
import { getUser } from "../../services/user.service";
import { isMobileWidth } from "../../services/helpers";

import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { WelcomeModal } from "../../components/modal";
import { FillOutProfileFrame } from "../../components/fill-out-profile-frame";

import {
    MainLayoutMobileNavTabs,
    MainLayoutNavTabs,
    MainLayoutUserDropdown,
} from "./ui";

import routes from "../../data/routes.json";
import styles from "./main.module.scss";
import { DashboardPageLoader } from "../../components/dashboard-page-loader";

export const MainLayout: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const isAuth = useUserAuthStore((s) => s.computed.isAuth);
    const [init, inited, isFilledProfile] = useUserRootStore((s) => [
        s.init,
        s.inited,
        s.computed.filledProfile,
    ]);

    useEffect(() => {
        if (!isAuth) {
            navigate("/login");
        }
    }, [isAuth]);

    useEffect(() => {
        let didCancel = false;
        async function request() {
            setLoading(true);
            const { data, error } = await getUser();
            if (!didCancel && data && error === undefined) {
                init(data);
                setLoading(false);
            }
        }

        if (isAuth) {
            request();
        }

        return () => {
            didCancel = true;
        };
    }, []);

    useEffect(() => {
        if (inited && !isFilledProfile) {
            navigate(routes.dashProfileWelcomePage);
        }
    }, [inited, isFilledProfile]);

    return (
        <div className={`${styles["wrap"]} container-fluid`}>
            <div className="row">
                <Header />
            </div>
            <div className="row">
                <main className={styles["container"]}>
                    {!isMobileWidth() ? (
                        <div className={styles["top-header-layout"]}>
                            <MainLayoutNavTabs />
                            <MainLayoutUserDropdown />
                        </div>
                    ) : (
                        <MainLayoutMobileNavTabs />
                    )}

                    <div className={styles["content"]}>
                        {/* {!isFilledProfile && !loading && (
                            <div className="container">
                                <div className="row">
                                    <FillOutProfileFrame />
                                </div>
                            </div>
                        )} */}
                        <Outlet />
                        {/* {!loading && <WelcomeModal open={!isFilledProfile} />} */}
                        {loading && <DashboardPageLoader />}
                    </div>
                </main>
            </div>
            <div className="row">
                <Footer />
            </div>
        </div>
    );
};
