import { Link, useLocation } from "react-router-dom";

import styles from "./auth-nav-tabs.module.scss";
import clsx from "clsx";

import routes from "../../../../data/routes.json";

const ITEMS = [
    {
        name: "Login",
        url: routes.authLogIn,
    },
    {
        name: "Sign Up",
        url: routes.authSignUp,
    },
];

export const NavTabs = () => {
    const { pathname } = useLocation();

    return (
        <div className={styles["wrap"]}>
            <ul className={styles["tabs-list"]}>
                {ITEMS.map((item, i) => (
                    <li key={i} className={styles["list-item"]}>
                        <Link
                            to={item.url}
                            className={clsx({
                                [styles["list-item__link"]]: true,
                                [styles["list-item__link--active"]]:
                                    pathname === item.url,
                            })}
                        >
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
