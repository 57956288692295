import { RxPrescriptionIcon } from "../../../../components/icons";
import { LogoCrxDirect } from "../../../../components/logo";
import PharmacyLogo from "../../../../components/pharmacy-logo";
import { UserQuizItem } from "../../../../services/types";
import { DetailsBlock } from "../ui";
import styles from "./details-treatment.module.scss";

import BestOffersItems from "./temp-best-offers-items.json";

interface BestOffersItem {
    pharmacyName: string;
    title: string;
    price: string;
}

const BestOffersItem = ({ item }: { item: BestOffersItem }) => {
    return (
        <div className={styles["best-offer__item"]}>
            <div className={styles["best-offer__item-top"]}>
                <PharmacyLogo
                    name={item.pharmacyName}
                    className={styles["best-offer__item-pharm_icon"]}
                />
                <span className={styles["best-offer__item-title"]}>
                    {item.title}
                </span>
            </div>
            <div className={styles["best-offer__item-bottom"]}>
                <span className={styles["best-offer__item-price"]}>
                    $ {item.price}
                </span>
                <button className="button purple">Get Coupon</button>
            </div>
        </div>
    );
};

const PrescriptionListBlock = () => {
    return (
        <ul className={styles["prescription-list"]}>
            <li className={styles["prescription-list__item"]}>
                <span>Sildenafil 100mg</span>
            </li>
            <li className={styles["prescription-list__item"]}>
                <span>Tadalafil 20mg</span>
            </li>
        </ul>
    );
};

const BestOffers = () => {
    return (
        <div className={styles["best-offers__wrap"]}>
            <div className={styles["crx-direct"]}>
                <div className={styles["crx-direct__coupon-name"]}>
                    <div className={styles["crx-direct__coupon-logo"]}>
                        <LogoCrxDirect />
                    </div>
                    <div className={styles["crx-direct__coupon-popper"]}>
                        <span>Your Prescriptions direct in 24 Hours</span>
                    </div>
                </div>
                <div className={styles["crx-direct__coupon-actions"]}>
                    <span className={styles["direct__coupon-price"]}>
                        $ 15.90
                    </span>
                    <button className="button purple">Get Delivery</button>
                </div>
            </div>
        </div>
    );
};

interface Props {
    quiz: UserQuizItem;
}

export const DetailsPrescription = ({ quiz }: Props) => {
    return (
        <DetailsBlock
            title="Prescription"
            icon={<RxPrescriptionIcon color="#2b307a" />}
        >
            <div className={styles["details-block"]}>
                <div className={styles["prescription"]}>
                    <PrescriptionListBlock />
                    <div className={styles["prescription__comments"]}>
                        <span
                            className={styles["prescription__comments-label"]}
                        >
                            Doctor Comments
                        </span>
                        <p className={styles["prescription__comments-value"]}>
                            ...
                        </p>
                    </div>
                </div>
                <div className={styles["best-offers"]}>
                    <span className={styles["best-offers__title"]}>
                        Best offers near you:
                    </span>
                    <BestOffers />
                    <div className={styles["best-offers-prices"]}>
                        {BestOffersItems.map((item, i) => (
                            <BestOffersItem key={i} item={item} />
                        ))}
                    </div>
                </div>
            </div>
        </DetailsBlock>
    );
};
