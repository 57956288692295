import { Link, generatePath } from "react-router-dom";
import { QuizStatuses, UserQuizItem } from "../../../../../services/types";
import routes from "../../../../../data/routes.json";

import styles from "./quiz-history_table-item.module.scss";
import moment from "moment";

interface UserQuizTableItemProps {
    item: UserQuizItem;
}

export const UserQuizTableItem = ({ item }: UserQuizTableItemProps) => {
    return (
        <div className={styles["item__body"]}>
            <div className={styles["item__body--treatment"]}>
                <span className={styles["item__body-title-mobile"]}>
                    Treatment
                </span>
                <span className={styles["item__body-title"]}>{item.name}</span>
            </div>
            <div className={styles["item__body--status"]}>
                <span className={styles["item__body-title-mobile"]}>
                    Status
                </span>

                <span className={styles["item__body-name"]}>{item.status}</span>
            </div>
            <div className={styles["item__body--doctor"]}>
                <span className={styles["item__body-title-mobile"]}>
                    Doctor
                </span>

                <span className={styles["item__body-name"]}>-</span>
            </div>
            <div className={styles["item__body--date"]}>
                <span className={styles["item__body-title-mobile"]}>Date</span>

                <span className={styles["item__body-name"]}>
                    {moment(item.date).format("lll")}
                </span>
            </div>
            <div className={styles["item__body--actions"]}>
                <Link
                    to={generatePath(routes.dashHistoryDetailsQuizPage, {
                        quizId: item.id,
                    })}
                >
                    <button className="button light-blue">Details</button>
                </Link>
                {item.status === QuizStatuses.processing && (
                    <Link
                        to={generatePath(routes.dashQuizMainPage, {
                            diseaseSlug: item.slug,
                            quizType: item.type,
                            quizId: item.id,
                        })}
                    >
                        <button className="button purple">Continue</button>
                    </Link>
                )}
                {item.status === QuizStatuses.payment && (
                    <Link
                        to={generatePath(routes.dashQuizPaymentPage, {
                            diseaseSlug: item.slug,
                            quizType: item.type,
                            quizId: item.id,
                        })}
                    >
                        <button className="button blue">Payment</button>
                    </Link>
                )}
            </div>
        </div>
    );
};
