import clsx from "clsx";
import { NavLink } from "react-router-dom";

import { FooterNavigationProps, NavItemType } from "../types";
import FooterNavItem from "../../../data/footer.navigation";

export const FooterNavigation = ({
    containerClassName = "",
    navClassName = "",
    navLinkClassName = "",
    navLinkActiveClassName = "",
    linkTitleClassName = "",
    withCopyright = false,
}: FooterNavigationProps) => {
    const items = FooterNavItem as NavItemType[];
    return (
        <div className={containerClassName}>
            <nav
                className={clsx({
                    [navClassName]: navClassName !== "",
                })}
            >
                {items.map((navItem, i) => (
                    <NavLink
                        key={i}
                        to={navItem.slug}
                        className={({ isActive }) =>
                            clsx({
                                [navLinkClassName]: navLinkClassName !== "",
                                [navLinkActiveClassName]: isActive,
                            })
                        }
                    >
                        <span
                            className={clsx({
                                [linkTitleClassName]: linkTitleClassName !== "",
                            })}
                        >
                            {navItem.title}
                        </span>
                    </NavLink>
                ))}
            </nav>
            {withCopyright && (
                <span className="copyright">
                    Copyright © {new Date().getFullYear()} CharityRx
                </span>
            )}
        </div>
    );
};
