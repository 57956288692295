import { FunctionComponent, ReactElement } from "react";

import NotFoundImage1x from "../../../assets/images/illustration/main-layout-not-found-1x.png";
import NotFoundImage2x from "../../../assets/images/illustration/main-layout-not-found-2x.png";
import NotFoundImage3x from "../../../assets/images/illustration/main-layout-not-found-3x.png";

import styles from "./main-layout-errors.module.scss";
import { useNavigate } from "react-router-dom";

export const NotFoundError: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="row">
                <div className={styles["error-wrap"]}>
                    <div className={styles["not-found__image-block"]}>
                        <img
                            className={styles["not-found__illustration"]}
                            src={NotFoundImage1x}
                            srcSet={`${NotFoundImage2x} 2x, ${NotFoundImage3x} 3x`}
                            alt="CharityRx, LLC - 404 error"
                        />
                    </div>
                    <div className={styles["not-found__content"]}>
                        <div className={styles["content__wrap"]}>
                            <h2>Page not found</h2>
                            <p>
                                Sorry, the page you are looking for doesn&apos;t
                                exist or has been moved
                            </p>
                            <button
                                className={`button purple ${styles["btn__go-back"]}`}
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
