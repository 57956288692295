import routes from "./routes.json";

export default [
    {
        title: "Services",
        slug: routes.dashServicesPage,
        leftCorner: false,
        rightCorner: true,
    },
    {
        title: "History",
        slug: routes.dashHistoryPage,
        leftCorner: true,
        rightCorner: true,
    },
    {
        title: "Profile",
        slug: routes.dashProfilePage,
        leftCorner: false,
        rightCorner: true,
    },
];
