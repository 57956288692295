import {
    SignUpRequestType,
    resendVerifyEmailCodeRequestType,
    resetPasswordRequestData,
    verifyUserEmailWithCodeRequestType,
} from "../types";
import client from "./client";

export const loginRequest = (
    email: string,
    password: string,
    device_name: string
) => {
    return client.post("/api/auth/login", {
        email,
        password,
        device_name,
    });
};

export const logoutRequest = () => {
    return client.post("/api/auth/logout");
};

export const signUpRequest = (data: SignUpRequestType) => {
    return client.post("/api/auth/signup", data);
};

export const verifyUserEmailWithCodeRequest = (
    data: verifyUserEmailWithCodeRequestType
) => {
    return client.post("/api/auth/verify-email/code", data);
};

export const resendVerifyEmailCodeRequest = (
    data: resendVerifyEmailCodeRequestType
) => {
    return client.post("/api/auth/verify-email/repeat", data);
};

export const forgotPasswordRequest = (data: { email: string }) => {
    return client.post("/api/auth/forgot-password", data);
};

export const resetPasswordRequest = (data: resetPasswordRequestData) => {
    return client.post("/api/auth/reset-password", data);
};
