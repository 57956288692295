import { Link, generatePath, useParams } from "react-router-dom";
import { QuizStatuses, QuizTypes } from "../../../../services/types";
import {
    ContentBenefits,
    ContentInitialActionBlock,
    ContentRefillActionBlock,
} from "./ui";

import styles from "./welcome-content-selection-step.module.scss";
import routes from "../../../../data/routes.json";
import { useEffect, useState } from "react";
import { useQuizRootStore } from "../../../../stores/quiz/quizRootStore";

interface Props {
    nextStep: () => void;
    currentTypeOfQuiz: QuizTypes;
    setTypeOfQuiz: (val: QuizTypes) => void;
}

export const WelcomeContentSelectionStep = ({
    nextStep,
    setTypeOfQuiz,
    currentTypeOfQuiz,
}: Props) => {
    const { diseaseSlug } = useParams();

    const [quizId, setQuizId] = useState<number | null>(null);
    const [quizzes, availableQuizTypes, getStartedQuizId] = useQuizRootStore(
        (s) => [
            s.diseaseQuizzes,
            s.computed.currentDiseaseTypes,
            s.getStartedQuizId,
        ]
    );

    // Initial by default
    useEffect(() => {
        if (availableQuizTypes) {
            const id = getStartedQuizId(QuizTypes.initial);
            setTypeOfQuiz(QuizTypes.initial);
            if (id && id !== null) {
                setQuizId(id);
            }
        }
    }, [quizzes]);

    const onContentActionTypeHandler = (s: QuizTypes) => {
        setTypeOfQuiz(s);
        const quizId = getStartedQuizId(s);
        if (quizId && quizId !== null) {
            setQuizId(quizId);
        }
    };
    return (
        <>
            <div className={styles["content"]}>
                <div className={styles["content__actions"]}>
                    {availableQuizTypes?.includes(QuizTypes.initial) && (
                        <ContentInitialActionBlock
                            isActive={currentTypeOfQuiz === QuizTypes.initial}
                            clickHandler={(s) => onContentActionTypeHandler(s)}
                        />
                    )}
                    {availableQuizTypes?.includes(QuizTypes.refill) && (
                        <ContentRefillActionBlock
                            isActive={currentTypeOfQuiz === QuizTypes.refill}
                            clickHandler={(s) => onContentActionTypeHandler(s)}
                        />
                    )}
                </div>
                <div className={styles["content__main"]}>
                    <ContentBenefits />
                </div>
            </div>
            <div className={styles["bottom"]}>
                {currentTypeOfQuiz && quizId && (
                    <Link
                        to={generatePath(routes.dashQuizMainPage, {
                            diseaseSlug,
                            quizType: currentTypeOfQuiz,
                            quizId: quizId,
                        })}
                    >
                        <button
                            className={`button light-blue ${styles["bottom__btn"]}`}
                        >
                            Continue
                        </button>
                    </Link>
                )}
                <button
                    onClick={nextStep}
                    className={`button purple ${styles["bottom__btn"]}`}
                >
                    Start New
                </button>
            </div>
        </>
    );
};
