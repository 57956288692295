import clsx from "clsx";
import { SyntheticEvent, useState } from "react";

import { CloseIcon, SearchIcon } from "../../../../../components/icons";

import { useDiseaseRootStore } from "../../../../../stores/disease/diseaseRootStore";

import styles from "./styles.module.scss";
import { InputField } from "../../../../../components/fields";

export const SearchBar = () => {
    const [hintValue, setHintValue] = useState<string>("");
    const [isActive, setActive] = useState<boolean>(false);

    const [diseasesNames, setSortKey, value] = useDiseaseRootStore((state) => [
        state.computed.diseasesNames,
        state.setSortKey,
        state.sortKey,
    ]);

    const closeHandler = () => {
        setSortKey("");
        setHintValue("");
    };

    const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        setSortKey(value);

        if (value === "") {
            setHintValue("");
            return;
        }

        if (diseasesNames) {
            const match = diseasesNames
                .filter(
                    (x) =>
                        x.toLowerCase() !== value.toLowerCase() &&
                        x.toLowerCase().startsWith(value.toLowerCase())
                )
                .sort()[0];

            if (match) {
                setHintValue(match.slice(value.length));
            } else {
                setHintValue("");
            }
        }
        return null;
    };

    return (
        <div className={styles["wrap"]}>
            <SearchIcon
                className={clsx({
                    [styles["input-icon"]]: true,
                    [styles["input-icon--active"]]: isActive,
                })}
            />
            <InputField
                className={clsx({
                    [styles["input"]]: true,
                    [styles["input--active"]]: isActive,
                })}
                type="text"
                name="service-search-bar"
                placeholder="Search by disease or symptom name"
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                value={value}
                onChange={onChangeHandler}
            />
            <span className={styles["hint"]}>
                <span className={styles["hint__filler"]}>{value}</span>
                <input
                    className={styles["hint__input"]}
                    style={{
                        pointerEvents:
                            !hintValue || hintValue === "" ? "none" : "visible",
                        opacity: !hintValue || hintValue === "" ? 0 : 1,
                    }}
                    defaultValue={hintValue}
                    tabIndex={-1}
                />
            </span>

            <CloseIcon
                onClick={() => closeHandler()}
                containerClass={clsx({
                    [styles["input-icon__close-wrap"]]: true,
                    [styles["input-icon__close-wrap--active"]]: value !== "",
                })}
                className={styles["input-icon__close"]}
            />
        </div>
    );
};
