import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import creditCardValidator from "card-validator";
import { useUserRootStore } from "../../../stores/user/userRootStore";
import {
    CreditCardInputField,
    FieldGroup,
    InputField,
    InputWithMaskField,
} from "../../fields";

import { FormCreditCardPaymentProps } from "../types";

import styles from "./styles.module.scss";
import { toUpper } from "lodash";
import { makeCheckoutOfQuiz } from "../../../services/user.service";
import { useQuizRootStore } from "../../../stores/quiz/quizRootStore";
import { ApiValidationError } from "../../../services/errors";
import { parseValidationErrors } from "../../../services/helpers";

interface CheckoutFormFields {
    creditCard: string;
    expireDate: string;
    cvv: string;
    creditCardName: string;
    email: string;
}

const SCHEMA = yup
    .object({
        creditCard: yup
            .string()
            .test(
                "credit-card-number",
                "Credit Card number is invalid",
                (value) => creditCardValidator.number(value).isValid
            )
            .required(),
        expireDate: yup
            .string()
            .test(
                "credit-card-date",
                "Expiration Date is invalid",
                (value) => creditCardValidator.expirationDate(value).isValid
            )
            .required(),
        cvv: yup
            .string()
            .test(
                "credit-card-cvv",
                "CVV is invalid",
                (value) => creditCardValidator.cvv(value).isValid
            )
            .required(),
        creditCardName: yup
            .string()
            .test(
                "credit-card-name",
                "Name is invalid",
                (value) => creditCardValidator.cardholderName(value).isValid
            )
            .required(),
        email: yup.string().email().required(),
    })
    .required();

export const CreditCardPaymentForm = ({
    formId,
    onSuccess,
    onLoading,
    formClassName,
}: FormCreditCardPaymentProps) => {
    const { quizUserId } = useQuizRootStore();
    const { email } = useUserRootStore();

    const demoData = {
        creditCard: "4111111111111111",
        expireDate: "12/2023",
        cvv: "123",
        creditCardName: "TEST TEST",
    };

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setValue,
    } = useForm<FieldValues>({
        values: {
            ...(email && { email }),
            ...demoData,
        },
        resolver: yupResolver(SCHEMA),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        if (Object.keys(errors).length === 0 && email !== null) {
            onLoading(true);

            const { data: result, error } =
                await makeCheckoutOfQuiz<CheckoutFormFields>({
                    quizId: quizUserId,
                    data: data as CheckoutFormFields,
                });
            if (error) {
                if (error instanceof ApiValidationError) {
                    parseValidationErrors(error, setError);
                }
            } else {
                onSuccess("");
            }
            onLoading(false);
        }
    };

    return (
        <form
            id={formId || undefined}
            className={formClassName}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={styles["wrap"]}>
                <FieldGroup
                    name="creditCard"
                    label="Credit Card Number"
                    error={errors?.creditCard}
                >
                    <CreditCardInputField register={register} />
                </FieldGroup>
                <div className={styles["wrap-half"]}>
                    <FieldGroup
                        name="expireDate"
                        label="Expiry Date"
                        error={errors?.expireDate}
                    >
                        <InputWithMaskField
                            mask="99/9999"
                            placeholder="mm/yyyy"
                            register={register}
                        />
                    </FieldGroup>
                    <FieldGroup name="cvv" label="CVV" error={errors?.cvv}>
                        <InputField register={register} />
                    </FieldGroup>
                </div>
                <FieldGroup
                    name="creditCardName"
                    label="Name on card"
                    error={errors?.creditCardName}
                >
                    <InputField
                        register={register}
                        registerOptions={{
                            onChange: (e) => {
                                setValue(
                                    "creditCardName",
                                    toUpper(e.currentTarget.value)
                                );
                            },
                        }}
                    />
                </FieldGroup>
                <FieldGroup name="email" label="Email" error={errors?.email}>
                    <InputField register={register} />
                </FieldGroup>
            </div>
        </form>
    );
};
