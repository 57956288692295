import * as yup from "yup";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { FieldGroup, InputField } from "../../../../components/fields";
import { LoadingButton } from "../../../../components/buttons";

import { ApiValidationError } from "../../../../services/errors";
import { updateMainUserProfile } from "../../../../services/user.service";
import { parseValidationErrors } from "../../../../services/helpers";
import { useUserRootStore } from "../../../../stores/user/userRootStore";

import styles from "./forms.module.scss";

const USER_CREDENTIALS = yup
    .object({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })
    .required();

export const UserCredentialsData = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [init, userProfile] = useUserRootStore((s) => [
        s.init,
        s.computed.profileData,
    ]);
    const {
        setError,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FieldValues>({
        values: userProfile,
        resolver: yupResolver(USER_CREDENTIALS),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        const { data: userData, error } = await updateMainUserProfile(data);
        if (error) {
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        } else if (userData !== null) {
            init(userData);
        }
        setLoading(false);
    };

    return (
        <div className={styles["wrap"]}>
            <span
                className={`${styles["head__title"]} ${styles["head__title--credentials"]}`}
            >
                Credentials
            </span>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles["fields-row"]}
            >
                <FieldGroup
                    name="email"
                    label="Email"
                    containerClassName={styles["form-text-input"]}
                    error={errors.email}
                >
                    <InputField register={register} />
                </FieldGroup>
                <FieldGroup
                    name="password"
                    label="Password"
                    containerClassName={styles["form-text-input"]}
                    error={errors.password}
                >
                    <InputField type="password" register={register} />
                </FieldGroup>
            </form>
        </div>
    );
};
