import clsx from "clsx";
import React, { ComponentType } from "react";
import { shallow } from "zustand/shallow";
import { find } from "lodash";

import { generateRandomString } from "../../../../services/helpers";
import { useQuizRootStore } from "../../../../stores/quiz/quizRootStore";

import {
    QuestionFieldSelect,
    QuestionFieldMultiSelect,
} from "../../../../components/question-fields";

import styles from "./quiz-question-item.module.scss";

type QuestionType = {
    id: string;
    content: [];
    currentValue: string | null | undefined;
    saveHandler: (value: string) => void;
};

interface Props {
    id: string;
    type: "select" | "multiselect";
    title: string;
    subtitle?: string | null;
    content: [];
}

const QuestionTypeComponents: {
    [key: string]: ComponentType<QuestionType>;
} = {
    select: QuestionFieldSelect,
    multiselect: QuestionFieldMultiSelect,
};

export const QuizQuestionItem = ({
    id,
    type,
    title,
    subtitle = null,
    content,
}: Props) => {
    const [currentQuestionValue, saveAnswerQuestion, error] = useQuizRootStore(
        (state) => [
            find(state.saved, { id }),
            state.saveAnswerQuestion,
            find(state.validationErrors, { id }),
        ],
        shallow
    );

    const handlerMakeAnswer = (value: string) => {
        saveAnswerQuestion(id, value);
    };

    return (
        <div className={styles["container"]}>
            <div className={styles["head"]}>
                <h3
                    className={clsx({
                        question: true,
                        [styles["head__error"]]: error !== undefined,
                    })}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                {subtitle && <p className={`subtitle`}>{subtitle}</p>}
                {type === "multiselect" && (
                    <p className={`subtitle`}>
                        You can choose multiple answers
                    </p>
                )}
            </div>
            <div className={styles["content"]}>
                {typeof QuestionTypeComponents[type] !== "undefined"
                    ? React.createElement<QuestionType>(
                          QuestionTypeComponents[type],
                          {
                              key: generateRandomString(15),
                              id,
                              currentValue: currentQuestionValue?.value,
                              content,
                              saveHandler: handlerMakeAnswer,
                          }
                      )
                    : React.createElement(() => (
                          <div>
                              The component {type} has not been created yet.
                          </div>
                      ))}
            </div>
        </div>
    );
};
