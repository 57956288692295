import { NavItemType } from "./types";

import DATA from "../../data/nav-bar.navigation.json";
import styles from "./nav-bar.module.scss";
import { Link } from "react-router-dom";

const NavItem = ({ title, link }: NavItemType) => {
    return (
        <li>
            <Link className="link" to={link}>
                {title}
            </Link>
        </li>
    );
};

export const NavBar = () => {
    const NavItems = DATA as NavItemType[];
    return (
        <nav className={styles["container"]}>
            <ul className={styles["list"]}>
                {NavItems.map((item: NavItemType, i) => (
                    <NavItem key={i} {...item} />
                ))}
            </ul>
        </nav>
    );
};
