import { FunctionComponent, ReactElement } from "react";

import styles from "../auth-pages.module.scss";

export const SignUpCongratsPage: FunctionComponent = (): ReactElement => {
    return (
        <div className={styles["wrapper"]}>
            <div className={styles["title-wrap"]}>
                <h2 className={styles["title"]}>Congratulations!</h2>
                <p className="section-text">
                    Within a few minutes, a letter will be sent to the specified
                    mail, you must follow the link and authorize your email
                </p>
            </div>
        </div>
    );
};
