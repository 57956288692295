import { Link } from "react-router-dom";

import CrxDirectLogo1x from "./images/crx-direct-logo-1x.png";
import CrxDirectLogo2x from "./images/crx-direct-logo-2x.png";
import CrxDirectLogo3x from "./images/crx-direct-logo-3x.png";

import styles from "./logo.module.scss";

export const LogoCrxDirect = () => {
    return (
        <Link to="/" className={styles.component}>
            <img
                src={CrxDirectLogo1x}
                srcSet={`${CrxDirectLogo2x} 2x, ${CrxDirectLogo3x} 3x`}
                alt="CRX Direct"
            />
        </Link>
    );
};
