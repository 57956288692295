import { LoaderOrbitPart } from "../icons";

import LogoInsideImage1x from "../../assets/images/loader/logo-inside-loader-1x.png";
import LogoInsideImage2x from "../../assets/images/loader/logo-inside-loader-2x.png";
import LogoInsideImage3x from "../../assets/images/loader/logo-inside-loader-3x.png";

import styles from "./loader.module.scss";

export const Loader = () => {
    return (
        <div className={styles["container"]}>
            <div className={styles["orbit"]}>
                <LoaderOrbitPart className={styles["orbit-icon"]} />
            </div>
            <div className={styles["inside"]}>
                <div className={styles["block-foreground"]}>
                    <img
                        className={styles["block-foreground__logo"]}
                        src={LogoInsideImage1x}
                        srcSet={`${LogoInsideImage2x} 2x, ${LogoInsideImage3x} 3x`}
                    />
                </div>
                <div className={styles["block-background"]} />
            </div>
        </div>
    );
};
