import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useUserRootStore } from "../../stores/user/userRootStore";
import { useUserAuthStore } from "../../stores/user/userAuthStore";
import { getUser } from "../../services/user.service";
import { isMobileWidth } from "../../services/helpers";

import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { WelcomeModal } from "../../components/modal";
import { FillOutProfileFrame } from "../../components/fill-out-profile-frame";

import {
    MainLayoutMobileNavTabs,
    MainLayoutNavTabs,
    MainLayoutUserDropdown,
} from "./ui";

import styles from "./pages.module.scss";

export const PagesLayout: FunctionComponent = (): ReactElement => {
    return (
        <div className={`${styles["wrap"]} container-fluid`}>
            <div className="row">
                <Header />
            </div>
            <div className="row">
                <main className={styles["container"]}>
                    <div className={styles["content"]}>
                        <Outlet />
                    </div>
                </main>
            </div>
            <div className="row">
                <Footer />
            </div>
        </div>
    );
};
