import clsx from "clsx";
import { LeaveQuizModal } from "../../../../components/modal";

import styles from "./quiz_back-btn.module.scss";

export const BackToMainBtn = () => (
    <LeaveQuizModal
        trigger={
            <button
                className={`button ghost-white ${styles["with-back-icon"]}`}
            >
                Back to treatments
            </button>
        }
    />
);
