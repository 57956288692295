import * as React from "react";
import { Root, createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import { Router } from "@remix-run/router";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import { AuthLayout, MainLayout, MainLayoutNotFound } from "./layouts";
import { RouterError } from "./pages/RouterError";

import { NotFound } from "./pages/NotFound";

import {
    VerificationPage,
    CreatePasswordPage,
    ForgotPasswordPage,
    LogInPage,
    SignUpPage,
    SignUpCongratsPage,
} from "./pages/auth-pages";
import {
    HistoryDetailsPage,
    HistoryPage,
    ProfilePage,
    ServicesPage,
    WelcomePage,
} from "./pages/dashboard-pages";

import {
    CongratsQuizPage,
    MainQuizPage,
    CheckoutQuizPage,
    WelcomeQuizPage,
} from "./pages/quiz-pages";
import routes from "./data/routes.json";
import "./index.scss";
import { AppErrorBoundary } from "./components/errors";
import {
    InformedConsentPage,
    PrivacyPolicyPage,
    TermsConditionsPage,
} from "./pages/doc-pages";
import { PagesLayout } from "./layouts/pages-layout";

const router: Router = createBrowserRouter([
    {
        path: "/",
        element: <AuthLayout />,
        errorElement: <RouterError />,
        children: [
            {
                index: true,
                element: <Navigate to={routes.authLogIn} />,
            },
            {
                path: routes.authLogIn,
                element: <LogInPage />,
            },
            {
                path: routes.authSignUp,
                element: <SignUpPage />,
            },
            {
                path: routes.authSignUpCongrats,
                element: <SignUpCongratsPage />,
            },
            {
                path: routes.authVerification,
                element: <VerificationPage />,
            },
            {
                path: routes.authRestorePwd,
                element: <ForgotPasswordPage />,
            },
            {
                path: routes.authCreatePwd,
                element: <CreatePasswordPage />,
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
    {
        path: routes.dashboard,
        element: <MainLayout />,
        errorElement: <RouterError />, // ?
        children: [
            {
                index: true,
                element: <Navigate to={routes.dashServicesPage} />,
            },
            {
                path: routes.dashProfileWelcomePage,
                element: <WelcomePage />,
            },
            {
                path: routes.profilePage,
                element: <ProfilePage />,
            },
            {
                path: routes.historyPage,
                element: <HistoryPage />,
            },
            {
                path: routes.historyDetailsQuizPage,
                element: <HistoryDetailsPage />,
            },
            {
                path: routes.servicesPage,
                element: <ServicesPage />,
            },
            {
                path: routes.quizWelcomePage,
                element: <WelcomeQuizPage />,
            },
            {
                path: routes.quizMainPage,
                element: <MainQuizPage />,
            },
            {
                path: routes.quizPaymentPage,
                element: <CheckoutQuizPage />,
            },
            {
                path: routes.quizCongratsPage,
                element: <CongratsQuizPage />,
            },
            {
                path: "*",
                element: <MainLayoutNotFound />,
            },
        ],
    },
    {
        path: routes.docsPages,
        element: <PagesLayout />,
        errorElement: <RouterError />, // ?
        children: [
            {
                index: true,
                element: <Navigate to={routes.docsTermsConditionsPage} />,
            },
            {
                path: routes.docsTermsConditionsPage,
                element: <TermsConditionsPage />,
            },
            {
                path: routes.docsPrivacyPolicyPage,
                element: <PrivacyPolicyPage />,
            },
            {
                path: routes.docsInformedConsentPage,
                element: <InformedConsentPage />,
            },
            {
                path: "*",
                element: <MainLayoutNotFound />,
            },
        ],
    },
]);

const container = document.getElementById("root") as HTMLElement;
if (!container) throw new Error("Failed to find the root element");
const root: Root = createRoot(container);

root.render(
    <React.StrictMode>
        <AppErrorBoundary>
            <RouterProvider router={router} />
        </AppErrorBoundary>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
