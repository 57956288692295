import { get } from "lodash";
import { useLocation } from "react-router-dom";
import { DotsIcon, RightCorner } from "../../../../components/icons";

import styles from "./mobile-navigation-tabs.module.scss";
import { MobileNavigationModal } from "../../../../components/modal";

const PageNames = {
    "/dashboard/profile": "Profile",
    "/dashboard/services": "Services",
    "/dashboard/history": "History",
};

export const MobileNavigationTabs = () => {
    const { pathname } = useLocation();
    const openMoreHandler = () => {
        return null;
    };
    return (
        <div className={styles["container"]}>
            <div className={styles["wrap"]}>
                <div className={styles["current-nav"]}>
                    <span className={styles["current-nav__name"]}>
                        {get(PageNames, pathname, "Details")}
                    </span>
                    <div className={styles["current-nav__corner"]}>
                        <RightCorner
                            className={styles["current-nav__corner-icon"]}
                        />
                    </div>
                </div>
                <MobileNavigationModal
                    trigger={
                        <div
                            className={styles["open-more"]}
                            onClick={openMoreHandler}
                        >
                            <DotsIcon className={styles["open-more__dots"]} />

                            <div className={styles["open-more__corner"]}>
                                <RightCorner
                                    className={styles["open-more__corner-icon"]}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};
