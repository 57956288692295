import { ExitIcon } from "../../../../components/icons";
import { useUserAuthStore } from "../../../../stores/user/userAuthStore";
import { useUserRootStore } from "../../../../stores/user/userRootStore";

import TopUserAvatar1x from "../../../../assets/images/illustration/top-user-avatar-1x.png";
import TopUserAvatar2x from "../../../../assets/images/illustration/top-user-avatar-2x.png";
import TopUserAvatar3x from "../../../../assets/images/illustration/top-user-avatar-3x.png";

import styles from "./user-dropdown.module.scss";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

export const UserDropdown = () => {
    const refMainHoverEl = useRef<HTMLDivElement | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const { email } = useUserRootStore();
    const { logout } = useUserAuthStore();

    useEffect(() => {
        const listener = ({ target }: MouseEvent) => {
            if (!refMainHoverEl.current) {
                return;
            }
            if (refMainHoverEl.current?.contains(target as Node)) {
                setShow(true);
            } else {
                setShow(false);
            }
        };
        document.addEventListener("mouseover", listener);
        return () => {
            document.removeEventListener("mouseout", listener);
        };
    }, []);

    return (
        <div ref={refMainHoverEl} className={styles["container"]}>
            <div className={styles["user-wrap"]}>
                <div className={styles["user-avatar"]}>
                    <img
                        src={TopUserAvatar1x}
                        srcSet={`${TopUserAvatar2x} 2x, ${TopUserAvatar3x} 3x`}
                        alt=""
                    />
                </div>
                <div className={styles["user-email"]}>{email}</div>
                <div className={styles["user-arrow-down"]}>
                    <span className={styles["user-arrow-down--icon"]} />
                </div>
            </div>

            <div
                className={clsx({
                    [styles["dropdown"]]: true,
                    [styles["dropdown--open"]]: show,
                })}
            >
                <ul className={styles["dropdown__list"]}>
                    <li
                        onClick={() => logout()}
                        className={`${styles["dropdown__list-item"]} ${styles["dropdown__list-item--error"]}`}
                    >
                        <ExitIcon className={styles["list__item-icon"]} />
                        Log out
                    </li>
                </ul>
            </div>
        </div>
    );
};
