import { useNavigate } from "react-router-dom";
import { LeaveQuizModalProps } from "./types";

import routes from "../../../data/routes.json";
import { Modal } from "../Modal";

import DoctorSingle1x from "../../../assets/images/illustration/doctor-single-1x.png";
import DoctorSingle2x from "../../../assets/images/illustration/doctor-single-2x.png";
import DoctorSingle3x from "../../../assets/images/illustration/doctor-single-3x.png";

import styles from "./leave-quiz-modal.module.scss";
import { isMobileWidth } from "../../../services/helpers";
import { useQuizRootStore } from "../../../stores/quiz/quizRootStore";

const ModalBody = ({ close }: { close: () => void }) => {
    const { resetStore } = useQuizRootStore();
    const navigate = useNavigate();
    const onLeaveHandler = () => {
        close();
        resetStore();
        setTimeout(() => {
            navigate(routes.dashServicesPage);
        }, 1);
    };
    return (
        <div className={styles["body__wrap"]}>
            <h3 className={styles["body__title"]}>
                Are you sure you want to stop filling out the questionnaire?
            </h3>
            <div className={styles["body__image-wrap"]}>
                <img
                    src={DoctorSingle1x}
                    srcSet={`${DoctorSingle2x} 2x, ${DoctorSingle3x} 3x`}
                    alt=""
                    className={styles["body__image"]}
                />
            </div>
            <div className={styles["body__actions"]}>
                <button
                    onClick={() => onLeaveHandler()}
                    className={`button light-blue ${styles["body__actions-btn"]}`}
                >
                    Yes, I want to leave
                </button>
                <button
                    className={`button purple ${styles["body__actions-btn"]}`}
                    onClick={close}
                >
                    No, I want to continue
                </button>
            </div>
        </div>
    );
};

export const LeaveQuizModal = ({ trigger }: LeaveQuizModalProps) => {
    return (
        <Modal trigger={trigger} isMobileDialog={isMobileWidth()}>
            {(props) => <ModalBody {...props} />}
        </Modal>
    );
};
