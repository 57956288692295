import { clsx } from "clsx";
import { range, toNumber } from "lodash";

import DatePicker from "react-datepicker";

import { Controller } from "react-hook-form";
import { getYear, getMonth } from "date-fns";

import { BirthdayFieldProps } from "../types";

import inputStyles from "../input/input.module.scss";
import styles from "./birthday-field.module.scss";

export const BirthdayField = ({ id, name, control }: BirthdayFieldProps) => {
    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    if (!name) return null;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <DatePicker
                    id={id}
                    className={clsx({
                        [inputStyles["input"]]: true,
                    })}
                    selected={field.value && new Date(field.value)}
                    dateFormat="MM/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => field.onChange(date)}
                    calendarClassName={styles["calendar"]}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className={styles["header"]}>
                            <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                className={styles["header__btn"]}
                            >
                                {"<"}
                            </button>
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                    changeYear(toNumber(value))
                                }
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                className={styles["header__btn"]}
                            >
                                {">"}
                            </button>
                        </div>
                    )}
                />
            )}
        />
    );
};
