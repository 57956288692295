import clsx from "clsx";
import { FunctionComponent, ReactElement, useState } from "react";
import { ForgotPasswordForm } from "../../../components/forms/forgot-password";

import styles from "../auth-pages.module.scss";

export const ForgotPasswordPage: FunctionComponent = (): ReactElement => {
    const [msg, setMsg] = useState<string | null>(null);

    return (
        <div className={styles["wrapper"]}>
            <div className={styles["title-wrap"]}>
                <h2 className={styles["title"]}>Password Recovery</h2>
                <p className="section-text">
                    Enter your e-mail and we sent verification code
                </p>
            </div>
            <div
                className={clsx({
                    [styles["form-wrap"]]: true,
                    [styles["notify-wrap"]]: msg !== null,
                })}
            >
                <ForgotPasswordForm
                    formClassName={styles["form"]}
                    actionBtnWrapClassName={styles["form-action"]}
                    onSuccess={setMsg}
                />
                <span className={`notify__msg ${styles["notify__msg"]}`}>
                    {msg}
                </span>
            </div>
        </div>
    );
};
