import { useUserRootStore } from "../../../../stores/user/userRootStore";
import styles from "./quizzes-history-table.module.scss";
import { UserQuizTableItem } from "./table-item";

export const QuizzesHistoryTable = () => {
    const { userQuizzes } = useUserRootStore();

    return (
        <div className={styles["table-wrap"]}>
            <div className={styles["table"]}>
                <div className={styles["table__head"]}>
                    <div className={styles["table__head--treatment"]}>
                        <span className={styles["table__head-name"]}>
                            Treatment
                        </span>
                    </div>
                    <div className={styles["table__head--status"]}>
                        <span className={styles["table__head-name"]}>
                            Status
                        </span>
                    </div>
                    <div className={styles["table__head--doctor"]}>
                        <span className={styles["table__head-name"]}>
                            Doctor
                        </span>
                    </div>
                    <div className={styles["table__head--date"]}>
                        <span className={styles["table__head-name"]}>Date</span>
                    </div>
                    <div className={styles["table__head--actions"]} />
                </div>
                <div className={styles["table__body"]}>
                    {userQuizzes?.map((item, i) => (
                        <UserQuizTableItem key={i} item={item} />
                    ))}
                </div>
            </div>
        </div>
    );
};
