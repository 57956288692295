import clsx from "clsx";
import { Controller } from "react-hook-form";

import { GenderFieldProps } from "../types";

import styles from "./gender-field.module.scss";

export const GenderField = ({ name, control }: GenderFieldProps) => {
    if (!name) return null;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div className={styles["gender"]}>
                    <span
                        onClick={() => field.onChange("female")}
                        className={clsx({
                            [styles["gender__item"]]: true,
                            [styles["gender__item--active"]]:
                                field.value === "female",
                        })}
                    >
                        Female
                    </span>
                    <span
                        onClick={() => field.onChange("male")}
                        className={clsx({
                            [styles["gender__item"]]: true,
                            [styles["gender__item--active"]]:
                                field.value === "male",
                        })}
                    >
                        Male
                    </span>
                </div>
            )}
        />
    );
};
