import { Link } from "react-router-dom";

import CrxHealthLogo1x from "./images/crx-health-logo-2d-1x.png";
import CrxHealthLogo2x from "./images/crx-health-logo-2d-2x.png";
import CrxHealthLogo3x from "./images/crx-health-logo-2d-3x.png";

import styles from "./logo.module.scss";

export const Logo = () => {
    return (
        <Link to="/" className={styles.component}>
            <img
                src={CrxHealthLogo1x}
                srcSet={`${CrxHealthLogo2x} 2x, ${CrxHealthLogo3x} 3x`}
                alt="CRX Health"
                className={styles.image}
            />
        </Link>
    );
};
