import routes from "./routes.json";

export default [
    {
        title: "Terms & Conditions",
        slug: routes.dashProfilePage,
    },
    {
        title: "Privacy Policy",
        slug: routes.dashProfilePage,
    },
];
