import { Link } from "react-router-dom";
import { useQuizRootStore } from "../../../../stores/quiz/quizRootStore";
import { QuizErrorTypes } from "../../../../stores/quiz/types";

import QuestionDoctorImage1x from "../../../../assets/images/illustration/crx-doctor-question-1x.png";
import QuestionDoctorImage2x from "../../../../assets/images/illustration/crx-doctor-question-2x.png";
import QuestionDoctorImage3x from "../../../../assets/images/illustration/crx-doctor-question-3x.png";

import routes from "../../../../data/routes.json";

import styles from "./quiz_no-rx-error.module.scss";

interface Props {
    error: {
        type: QuizErrorTypes;
        message: string | null;
    };
}

export const QuizError = ({ error }: Props) => {
    const { prevPage } = useQuizRootStore();

    return (
        <div className={styles["wrap"]}>
            <div className={styles["illustration"]}>
                <img
                    src={QuestionDoctorImage1x}
                    srcSet={`${QuestionDoctorImage2x} 2x, ${QuestionDoctorImage3x} 3x`}
                    alt=""
                    draggable={false}
                />
            </div>
            <div className={styles["content"]}>
                <div className={styles["content__head"]}>
                    <h3 className={styles["content__head-title"]}>
                        Sorry, we can&apos;t currently help you
                    </h3>
                    <p className={styles["content__head-text"]}>
                        {error.message
                            ? error.message
                            : "We suggest making a Appointment for a physical exam with a local medical professional."}
                    </p>
                </div>
                <div className={styles["content__actions"]}>
                    {error.type !== QuizErrorTypes.restriction && (
                        <button
                            onClick={prevPage}
                            className={`${styles["content__actions-btn"]} button purple`}
                        >
                            Go Back to Questionnaire
                        </button>
                    )}
                    <Link to={routes.dashServicesPage}>
                        <button
                            className={`${styles["content__actions-btn"]} button light-blue`}
                        >
                            Go to main page
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
