import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { UserAuthStore } from "./types";
import { produce } from "immer";
import { logoutUser } from "../../services/auth.service";

const LOCAL_TOKEN_KEY = "crx_token";

const getAuthTokenFromStorage = (): string | null => {
    return sessionStorage.getItem(LOCAL_TOKEN_KEY);
};

const useUserAuthStore = create(
    subscribeWithSelector<UserAuthStore>((set, get) => ({
        token: getAuthTokenFromStorage(),
        computed: {
            get isAuth(): boolean {
                return get().token !== null;
            },
        },
        login: (token) =>
            set(
                produce((state) => {
                    sessionStorage.setItem(LOCAL_TOKEN_KEY, token);
                    state.token = token;
                })
            ),
        logout: async () => {
            const { error } = await logoutUser();
            if (error !== undefined) {
                // eslint-disable-next-line no-console
                console.error(error);
            }

            sessionStorage.removeItem(LOCAL_TOKEN_KEY);
            set({ token: null });
        },
    }))
);

// useUserAuthStore.subscribe(
//     (state) => state.token,
//     (saved) => {
//         console.log("useUserAuthStore.subscribe: ", saved);
//     }
// );

export { getAuthTokenFromStorage, useUserAuthStore };
