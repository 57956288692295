import { StethoscopeIcon } from "../../../../components/icons";
import { UserQuizItem } from "../../../../services/types";
import { DetailsBlock } from "../ui";
import styles from "./details-treatment.module.scss";

interface Props {
    quiz: UserQuizItem;
}

export const DetailsTreatment = ({ quiz }: Props) => {
    return (
        <DetailsBlock
            title="Treatment"
            icon={<StethoscopeIcon color="#2b307a" />}
            asTab={true}
        >
            <ul className={styles["quiz-list"]}>
                {quiz.answers?.map((item, i) => (
                    <li key={i} className={styles["quiz-list__item"]}>
                        <span className={styles["list-title"]}>
                            {item.label}
                        </span>
                        <span className={styles["list-answer"]}>
                            {item.answer}
                        </span>
                    </li>
                ))}
            </ul>
        </DetailsBlock>
    );
};
