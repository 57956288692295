import { ApiResponse, ValidationErrorFields } from "../types";

interface iApiValidationError {
    type: string;
    message: string;
    fields: ValidationErrorFields[] | null;
}
export class ApiValidationError extends Error implements iApiValidationError {
    type = "";
    message = "";
    fields: ValidationErrorFields[] | null = null;

    constructor(errResponse: ApiResponse) {
        super();
        this.type = "ValidationError";
        if (errResponse.error && errResponse.error.fields) {
            this.fields = errResponse.error.fields;
        }

        Object.setPrototypeOf(this, ApiValidationError.prototype);
    }
}
