import { Link } from "react-router-dom";
import { FunctionComponent, ReactElement } from "react";

import { QuizWrapper } from "../wrapper";

import CongratsImage1x from "../../../assets/images/illustration/crx-quiz-congrats-1x.png";
import CongratsImage2x from "../../../assets/images/illustration/crx-quiz-congrats-2x.png";
import CongratsImage3x from "../../../assets/images/illustration/crx-quiz-congrats-3x.png";

import routes from "../../../data/routes.json";
import styles from "./quiz-congrats-page.module.scss";

export const CongratsQuizPage: FunctionComponent = (): ReactElement => {
    return (
        <QuizWrapper>
            <div className={styles["wrap"]}>
                <div className={styles["illustration"]}>
                    <img
                        src={CongratsImage1x}
                        srcSet={`${CongratsImage2x} 2x, ${CongratsImage3x} 3x`}
                        alt=""
                        draggable={false}
                    />
                </div>
                <div className={styles["content"]}>
                    <div className={styles["content__head"]}>
                        <h3 className={styles["content__head-title"]}>
                            Congratulations!
                        </h3>
                        <p className={styles["content__head-text"]}>
                            The doctor will review your profile and respond as
                            soon as possible. We will notify you by e-mail.
                        </p>
                    </div>
                    <div className={styles["content__actions"]}>
                        <Link to={routes.dashHistoryPage}>
                            <button
                                className={`${styles["content__actions-btn"]} button purple`}
                            >
                                Check status
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </QuizWrapper>
    );
};
