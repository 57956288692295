import { RightCorner } from "../../../../components/icons";
import { MainNavigation } from "../../../../components/navigation";
import styles from "./navigation-tabs.module.scss";

export const NavigationTabs = () => {
    return (
        <div className={styles["container"]}>
            <div className={styles["wrap"]}>
                <MainNavigation
                    withCorners
                    navClassName={styles["nav-list"]}
                    navLinkClassName={styles["nav-item"]}
                    navLinkActiveClassName={styles["nav-item--active"]}
                    disabledClassName={styles["nav-item--disabled"]}
                    linkTitleClassName={styles["nav-item__title"]}
                    navLinkCornerClassName={styles["corner_nav-item"]}
                    lockIconClassName={styles["nav-item__lock-icon"]}
                />
                <div className={styles["corner_nav-main"]}>
                    <RightCorner className={styles["corner_nav-main-icon"]} />
                </div>
            </div>
        </div>
    );
};
