import { has } from "lodash";
import axios, { AxiosError } from "axios";

import { useUserAuthStore } from "../../stores/user/userAuthStore";
import { ApiNetworkError, ApiValidationError } from "../errors";
import { ApiBackendError, ApiResponse } from "../types";
import { useErrorsRootStore } from "../../stores/errors/errorsRootStore";

const {
    REACT_APP_BACKEND_URL,
    REACT_APP_BACKEND_AUTH_LOGIN,
    REACT_APP_BACKEND_AUTH_PWD,
} = process.env;

const apiAxios = axios.create({
    ...(REACT_APP_BACKEND_URL && { baseURL: REACT_APP_BACKEND_URL }),
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    ...(REACT_APP_BACKEND_AUTH_LOGIN &&
        REACT_APP_BACKEND_AUTH_PWD && {
            auth: {
                username: REACT_APP_BACKEND_AUTH_LOGIN,
                password: REACT_APP_BACKEND_AUTH_PWD,
            },
        }),
});

apiAxios.interceptors.request.use(function (config) {
    const token = useUserAuthStore.getState().token;
    if (token !== null) {
        config.headers["X-Authorization"] = `Bearer ${token}`;
    }
    return config;
});

apiAxios.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error: AxiosError) {
        if (error.response) {
            if (error.response.data) {
                const apiResponse = error.response.data as ApiResponse;

                // 422 (Validation Error)
                if (error.response.status === 422 && error.response.data) {
                    return Promise.reject(new ApiValidationError(apiResponse));
                }

                // 403 (Restrictions)
                if (error.response.status === 403 && error.response.data) {
                    return Promise.reject(
                        new Error(apiResponse.error?.message)
                    );
                }

                // 401 (Unauthorized)
                if (error.response.status === 401) {
                    useUserAuthStore.getState().logout();
                    return;
                }

                if (has(error.response.data, "error")) {
                    const backendError = error.response.data as ApiBackendError;
                    const apiErrorObj = new ApiNetworkError(
                        backendError.error ||
                            "Something went wrong with API side."
                    );
                    useErrorsRootStore.getState().setError(apiErrorObj);
                    return Promise.reject(apiErrorObj);
                }
                // return Promise.reject(new ApiNetworkError(apiResponse));
            } else {
                // console.log("api. response: ", error.response);
                useErrorsRootStore.getState().setError(error);
            }
        }

        useErrorsRootStore
            .getState()
            .setError({ message: "Something went wrong, try later." });
        return Promise.reject(error); // ?
    }
);

export default {
    post: apiAxios.post,
    get: apiAxios.get,
};
