import { FunctionComponent, ReactElement } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

type OptionItem = {
    label: string;
    value: string;
    logic?: {};
};

interface Props {
    id: string;
    content: [];
    currentValue: string | null | undefined;
    saveHandler: (value: string) => void;
}
export const QuestionFieldSelect: FunctionComponent<Props> = ({
    id,
    currentValue,
    content,
    saveHandler,
}): ReactElement => {
    // console.log("i udpateselect question");

    const clickHandler = (itemIndex: number, value: string) => {
        saveHandler(value);
    };

    if (content.length === 0) {
        return <></>;
    }

    return (
        <ul className={styles["select__list"]}>
            {content.map((option: OptionItem, key) => (
                <li
                    className={styles["select__list-item"]}
                    key={key}
                    onClick={() => clickHandler(key, option.value)}
                >
                    <span
                        className={clsx({
                            [styles["select__item-point"]]: true,
                            [styles["select__item-point--checked"]]:
                                currentValue === option.value,
                        })}
                    />
                    <span className={styles["item-label"]}>{option.label}</span>
                </li>
            ))}
        </ul>
    );
};
