import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
    StethoscopeIcon,
    PencilEditIcon,
    LocationIcon,
} from "../../../../components/icons";
import { UserAvatar } from "../../../../components/user-avatar";
import { useUserRootStore } from "../../../../stores/user/userRootStore";
import { QuizTypes, UserInfoConfirmData } from "../../../../services/types";
import {
    checkDiseaseRestrictions,
    confirmationUserDataForNewQuiz,
    getOrCreateUserQuiz,
} from "../../../../services/disease.service";
import { useQuizRootStore } from "../../../../stores/quiz/quizRootStore";
import { QuizErrorTypes } from "../../../../stores/quiz/types";

import routes from "../../../../data/routes.json";
import styles from "./welcome-content-confirm-step.module.scss";

const UserInfoDataMapper = [
    {
        label: "Full Name",
        key: "fullName",
        value: null,
    },
    {
        label: "Email",
        key: "email",
        value: null,
    },
    {
        label: "Birthday",
        key: "birthday",
        value: null,
    },
    {
        label: "Gender",
        key: "gender",
        value: null,
    },
    {
        label: "Phone",
        key: "mobilePhone",
        value: null,
    },
] as UserInfoConfirmData[];

interface Props {
    currentTypeOfQuiz: QuizTypes;
}

export const WelcomeContentConfirmStep = ({ currentTypeOfQuiz }: Props) => {
    const { diseaseSlug } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [userMainAddress, getConfirmedUserDataByFields] = useUserRootStore(
        (s) => [s.computed.userMainAddress, s.getConfirmedUserDataByFields]
    );

    const [setGlobalError, setLoading, initCurrentQuiz] = useQuizRootStore(
        (s) => [s.setGlobalError, s.setLoading, s.initCurrentQuiz]
    );
    const [userDataAddress, setUserDataAddr] = useState<{
        id: number;
        value: string;
    } | null>(null);

    useEffect(() => {
        if (userMainAddress !== null) {
            setUserDataAddr({
                id: userMainAddress.key,
                value: `${userMainAddress?.location}, ${userMainAddress?.address}, ${userMainAddress?.additional}`,
            });
        }
    }, []);

    // confirmed data which we will pass to quiz.
    const confirmHandler = async () => {
        // if address was choice
        if (diseaseSlug && userDataAddress) {
            setLoading(true);
            const { error: restrictionError } = await checkDiseaseRestrictions(
                diseaseSlug
            );

            if (restrictionError === undefined) {
                const { data, error } = await getOrCreateUserQuiz(
                    diseaseSlug,
                    currentTypeOfQuiz,
                    undefined
                );

                if (data && error === undefined) {
                    const { error: confirmationError } =
                        await confirmationUserDataForNewQuiz(
                            data.saved.id,
                            userDataAddress.id
                        );

                    if (confirmationError === undefined) {
                        initCurrentQuiz(data.title, data.quiz, data.saved);

                        if (data.saved !== null && data.saved.id) {
                            navigate(`${pathname}/${data.saved.id}`);
                        }
                    } else {
                        const _err = confirmationError as Error; // ?
                        setGlobalError(QuizErrorTypes.global, _err.message);
                    }
                } else {
                    const _err = error as Error; // ?
                    setGlobalError(QuizErrorTypes.norx, _err.message);
                }
            } else {
                const _err = restrictionError as Error; // ?
                setGlobalError(QuizErrorTypes.restriction, _err.message);
            }
            setLoading(false);
        }
        return null;
    };

    return (
        <div className={styles["container"]}>
            <div className={styles["info-card"]}>
                <div className={styles["info-card__head"]}>
                    <div
                        className={`${styles["card-head__item"]} ${styles["card-head__item--stroke"]}`}
                    >
                        <StethoscopeIcon
                            className={styles["head-item__icon"]}
                        />
                        <span className={styles["head-item__text"]}>
                            Medical Card
                        </span>
                    </div>
                    <div className={styles["card-head__item"]}>
                        <PencilEditIcon
                            className={`${styles["head-item__icon"]} ${styles["head-item__icon--edit"]}`}
                        />
                        <Link to={routes.dashProfilePage}>
                            <span className={styles["head-item__text"]}>
                                Edit
                            </span>
                        </Link>
                    </div>
                </div>
                <div className={styles["info-card__body"]}>
                    <div className={styles["card-body__avatar"]}>
                        <UserAvatar
                            containerClassName={styles["user-avatar"]}
                            imageClassName={styles["user-avatar__img"]}
                        />
                    </div>
                    <div className={styles["card-body__user-info"]}>
                        <ul className={styles["user-info"]}>
                            {getConfirmedUserDataByFields(
                                UserInfoDataMapper
                            )?.map((item, i) => (
                                <li
                                    key={i}
                                    className={styles["user-info__item"]}
                                >
                                    {item.label}:
                                    <span
                                        className={
                                            styles["user-info__item-val"]
                                        }
                                    >
                                        {item?.value}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles["info-card"]}>
                <div className={styles["info-card__head"]}>
                    <div className={styles["card-head__item"]}>
                        <LocationIcon className={styles["head-item__icon"]} />
                        <span className={styles["head-item__text"]}>
                            Address
                        </span>
                    </div>
                </div>
                <div className={styles["info-card__body"]}>
                    <div className={styles["address-wrap"]}>
                        <span className={styles["address-label"]}>
                            You current main address:
                        </span>
                        <div className={styles["address-block"]}>
                            <span className={styles["address-value"]}>
                                {userDataAddress?.value}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {currentTypeOfQuiz && (
                // <Link
                //     to={generatePath(routes.dashQuizMainPage, {
                //         diseaseSlug,
                //         quizType: currentTypeOfQuiz,
                //     })}
                // >
                <button
                    className={`button purple ${styles["bottom__btn"]}`}
                    onClick={confirmHandler}
                >
                    Confirm & Start
                </button>
                // </Link>
            )}
        </div>
    );
};
