import { SaveQuizRequest } from "../types";
import client from "./client";

export const getDiseasesRequest = () => {
    return client.get("/api/disease");
};

export const getDiseaseRestrictionsRequest = (slug: string) => {
    return client.get(`/api/disease/${slug}/restrictions`);
};

export const getOrCreateUserQuizRequest = (
    slug: string,
    type: string,
    quizId: string | undefined
) => {
    return client.get(
        `/api/disease/${slug}/quiz/${type}${
            quizId !== undefined ? "/" + quizId : ""
        }`
    );
};

export const getDiseaseQuizzesRequest = (diseaseName: string) => {
    return client.get(`/api/disease/${diseaseName}/quizzes`);
};

export const confirmUserDataQuizRequest = (
    userQuizId: number,
    data: { locationId: number }
) => {
    return client.post(`/api/user/quiz/${userQuizId}/confirmation`, data);
};

export const saveProgressOfQuizForUserRequest = (
    userQuizId: number,
    data: SaveQuizRequest
) => {
    return client.post(`/api/user/quiz/${userQuizId}/progress`, data);
};

export const getSaveQuizFromUserRequest = (quizId: number) => {
    return client.get(`/api/quiz/${quizId}/saved`);
};
