import * as yup from "yup";
import React, { FunctionComponent } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { FieldGroup, InputField } from "../../../../components/fields";
import { FormProps } from "./types";

import styles from "./welcome-forms.module.scss";
import { updateMainUserProfile } from "../../../../services/user.service";
import { ApiValidationError } from "../../../../services/errors";
import { parseValidationErrors } from "../../../../services/helpers";

export const PhoneUserStep: FunctionComponent<FormProps> = ({
    loadingHandler,
    finalStep,
    user,
}) => {
    const VALIDATOR = yup
        .object({
            mobilePhone: yup.string().required(),
        })
        .required();
    const {
        register,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm<FieldValues>({
        values: {
            mobilePhone: user?.mobilePhone,
        },
        resolver: yupResolver(VALIDATOR),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        loadingHandler(true);
        const { data: userData, error } = await updateMainUserProfile(data);
        if (error !== undefined) {
            loadingHandler(false);
            if (error instanceof ApiValidationError) {
                parseValidationErrors(error, setError);
            }
        } else {
            if (userData !== null) {
                finalStep(userData);
            } else {
                console.error("ERROR user data is empty");
                loadingHandler(false);
            }
        }
    };

    return (
        <div className={styles["users-form"]}>
            <h3 className={styles["users-form__title"]}>Your phone number?</h3>
            <form
                className={styles["users-form__wrap"]}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FieldGroup
                    name="mobilePhone"
                    label="Phone"
                    containerClassName={styles["users-form__group"]}
                    error={errors.mobilePhone}
                >
                    <InputField
                        register={register}
                        placeholder="+1-222-333-4455"
                    />
                </FieldGroup>
                <button
                    type="submit"
                    className={`button blue ${styles["users-form__btn"]}`}
                >
                    Save
                </button>
            </form>
        </div>
    );
};
