import { ReactElement, ReactNode, cloneElement, useState } from "react";

import styles from "./history-details__block.module.scss";
import clsx from "clsx";

interface Props {
    title: string;
    icon: ReactElement;
    children: ReactNode;
    asTab?: boolean;
}

export const DetailsBlock = ({
    title,
    icon,
    children,
    asTab = false,
}: Props) => {
    const [tabIsOpen, setOpen] = useState(false);

    const headClickHandler = () => {
        if (asTab) {
            setOpen((prev) => !prev);
        }
    };

    return (
        <div className={styles["container"]}>
            <div className={styles["head"]}>
                <div
                    className={styles["head__title-block"]}
                    onClick={headClickHandler}
                >
                    {cloneElement(icon, { className: styles["head-icon"] })}
                    <span className={styles["head-title"]}>{title}</span>
                    {asTab && (
                        <span
                            className={clsx({
                                [styles["head-tab-arrow"]]: true,
                                [styles["head-tab-arrow--open"]]: tabIsOpen,
                            })}
                        />
                    )}
                </div>
            </div>
            <div className={styles["body"]}>
                {asTab ? (
                    <>
                        <div
                            className={clsx({
                                [styles["body__tab-content"]]: true,
                                [styles["body__tab-content--open"]]: tabIsOpen,
                            })}
                        >
                            {children}
                        </div>
                        <div className={styles["body__tab-button"]}>
                            <button
                                className="button ghost-white"
                                onClick={headClickHandler}
                            >
                                Show {tabIsOpen ? "less" : "full"}
                            </button>
                        </div>
                    </>
                ) : (
                    children
                )}
            </div>
        </div>
    );
};
