import * as yup from "yup";
import { useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldGroup, InputField } from "../../fields";
import { LoadingButton } from "../../buttons";

import { parseValidationErrors } from "../../../services/helpers";
import { ApiValidationError } from "../../../services/errors";
import { verifyEmail } from "../../../services/auth.service";

import { FormEmailVerifyProps } from "../types";

const SCHEMA = yup
    .object({
        code: yup.string().length(6).required(),
    })
    .required();

export const EmailVerifyCodeForm = ({
    email,
    formClassName,
    actionBtnWrapClassName,
    onSuccess,
}: FormEmailVerifyProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(SCHEMA),
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        if (Object.keys(errors).length === 0 && email !== null && data.code) {
            setLoading(true);
            const { error } = await verifyEmail(email, data.code);
            setLoading(false);

            if (error) {
                if (error instanceof ApiValidationError) {
                    parseValidationErrors(error, setError);
                }
            } else {
                onSuccess("");
            }
        }
    };

    return (
        <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup
                label="Verification code"
                name="code"
                error={errors.code}
            >
                <InputField register={register} />
            </FieldGroup>

            <div className={actionBtnWrapClassName}>
                <LoadingButton
                    isSubmitBtn={true}
                    color="purple"
                    title="Verify Code"
                    isLoading={loading}
                />
            </div>
        </form>
    );
};
